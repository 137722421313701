import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded'
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded'
import RemoveIcon from '@material-ui/icons/Remove'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import DashboardValue from '../DashboardValue'

export default function GrowthIndicator({ label, trend }) {
    const { t } = useTranslation()
    const theme = useTheme()

    const Icon =
        trend === 0
            ? DragHandleIcon
            : trend > 0
            ? ArrowUpwardRounded
            : trend < 0
            ? ArrowDownwardRounded
            : RemoveIcon

    const color =
        trend > 0
            ? theme.palette.status.danger
            : trend < 0
            ? theme.palette.status.ok
            : 'rgb(165,165,165)'

    const trendSummary =
        trend === 0
            ? t('invariato')
            : trend > 0
            ? t('in_aumento')
            : trend < 0
            ? t('in_diminuzione')
            : t('N/D')

    return (
        <DashboardValue
            title={t(label)}
            subtitle={t(trendSummary)}
            trendIndicatorComponent={() => (
                <Icon style={{ color, fontSize: 35 }} />
            )}
        />
    )
}
