import React, { Fragment } from 'react'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Typography,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RisksBarChart from '../Customized/RiskComparisonGraph'
import { Doughnut } from 'react-chartjs-2'
import { formatAreasForDoughnut } from '../../utils'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import LocalHospitalIcon from '@material-ui/icons/LocalHospitalRounded'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterRounded'
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded'
import Paper from '@material-ui/core/Paper'
import CustomizedProgressBar from '../Customized/CustomizedProgressBar'
import { useTranslation } from 'react-i18next'

const criticalitiesIcons = {
    health: LocalHospitalIcon,
    service: SettingsApplicationsRoundedIcon,
    business: BusinessCenterIcon,
}

export default function CardHG({
    image,
    name,
    category,
    address,
    risk,
    handleMoreClick,
    handleShiftRedirect,
    ownerProductionUnit,
    employeesCount,
    criticalities,
    workers,
    ...rest
}) {
    const { t } = useTranslation()
    const theme = useTheme(rest)
    const { data: genericGraphData } = formatAreasForDoughnut(
        risk?.proximityCategories
    )
    const hasCriticalities = !!criticalities && criticalities !== 'none'
    const CriticalityIcon =
        hasCriticalities && criticalitiesIcons[criticalities]

    return (
        <Box>
            <Card {...rest} style={{ borderRadius: 8 }}>
                <CardHeader
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleMoreClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={<Typography variant={'h5'}>{name}</Typography>}
                    subheader={address}
                />
                <CardContent style={{ paddingTop: 0 }}>
                    {/* <Typography color="textSecondary">{category}</Typography>
                    <Typography variant="body2" component="p">
                        {address}
                    </Typography> */}

                    <Grid
                        container
                        spacing={1}
                        style={{
                            marginBottom: theme.spacing(4),
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ height: 110, maxHeight: 110 }}
                        >
                            <Typography variant={'caption'}>
                                {t('perc_ATTIVITA')}
                            </Typography>
                            <div style={{ height: 80 }}>
                                <Doughnut
                                    legend={null}
                                    options={{
                                        maintainAspectRatio: false,
                                        animation: { duration: 0 },
                                    }}
                                    data={genericGraphData}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} container>
                            <Grid
                                item
                                xs={12}
                                style={{ height: 70, maxHeight: 70 }}
                            >
                                <Typography variant={'caption'}>
                                    {t('NUMEROSITA')}
                                </Typography>

                                <Paper
                                    style={{
                                        backgroundColor: '#f2f2f2',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                        padding: theme.spacing(0.5),
                                    }}
                                    elevation={0}
                                >
                                    <Typography variant={'h3'}>
                                        {employeesCount}
                                    </Typography>
                                </Paper>
                                {/*<Grid item alignContent={'center'}>*/}
                                {/*</Grid>*/}
                                {/*<Grid item>*/}

                                {/*</Grid>*/}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{ height: 70, maxHeight: 70 }}
                            >
                                <Box
                                    width="100%"
                                    mb="25px"
                                    display="flex"
                                    height="60px"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box>
                                        <Typography variant={'caption'}>
                                            {t('workers_progress_stat')}
                                        </Typography>
                                    </Box>
                                    <Box width="100%">
                                        <Typography>
                                            {workers.length} / {employeesCount}
                                        </Typography>
                                        <CustomizedProgressBar
                                            currentWorkers={workers.length}
                                            maxWorkers={employeesCount}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mb="15px" width="100%" height="63px">
                            <Fragment>
                                <Typography variant={'caption'}>
                                    {t('CRITICITA')}
                                </Typography>

                                <Paper
                                    style={{
                                        backgroundColor: hasCriticalities
                                            ? '#f2dac4'
                                            : '#f2f2f2',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        padding: theme.spacing(1),
                                    }}
                                    elevation={0}
                                >
                                    {hasCriticalities ? (
                                        // <Tooltip title={`Gruppo critico per catena del valore: ${criticalities}`}>
                                        <Fragment>
                                            <Typography
                                                color={'warning'}
                                                variant={'caption'}
                                            >
                                                {criticalities}
                                            </Typography>
                                            <CriticalityIcon
                                                style={{
                                                    color: theme.palette.warning
                                                        .dark,
                                                }}
                                            />
                                        </Fragment>
                                    ) : (
                                        <Box height="24px">
                                            <Typography
                                                color={'warning'}
                                                variant={'caption'}
                                            >
                                                {t('nessuna')}
                                            </Typography>
                                        </Box>
                                    )}
                                </Paper>
                            </Fragment>
                        </Box>

                        <Grid item xs={12}>
                            <RisksBarChart
                                baseRisk={risk?.totalRisk}
                                reducedRisk={risk?.mitigatedRisk}
                            />
                        </Grid>
                    </Grid>
                    {/*<Typography variant={'caption'}>Rischio totale</Typography>*/}
                    {/*<RiskRating value={risk?.totalRisk}/>*/}
                    {/*<Typography variant={'caption'}>Rischio ridotto</Typography>*/}
                    {/*<RiskRating value={risk?.mitigatedRisk}/>*/}
                </CardContent>
            </Card>
        </Box>
    )
}
