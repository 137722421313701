import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import DetailHG from '../Components/HomogeneousGroup/DetailHG'
import { useQuery } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { HOMOGENEOUS_GROUP_DETAILS_QUERY } from '../Components/HomogeneousGroup/GraphQL_queries'
import PageTitle from '../Components/Customized/PageTitle'
import PageContainer from '../Components/Customized/PageContainer'

export default function HomogeneousGroupDetailScreen() {
    const { groupID } = useParams()
    const { loading, data, refetch } = useQuery(
        HOMOGENEOUS_GROUP_DETAILS_QUERY,
        {
            variables: { idList: [groupID] },
        }
    )

    const refetchData = async () => {
        let data = await refetch()
        return data.data.homogeneousGroups?.[0]?.risk
    }

    return (
        <PageContainer>
            {loading ? (
                <CircularProgress />
            ) : (
                <Fragment>
                    <PageTitle>{data?.homogeneousGroups?.[0]?.name}</PageTitle>
                    <DetailHG
                        data={data?.homogeneousGroups?.[0]}
                        refetch={refetchData}
                    />
                </Fragment>
            )}
        </PageContainer>
    )
}
