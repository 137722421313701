import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import ListHG from '../Components/HomogeneousGroup/ListHG'
import { useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { CustomDialog } from '../Components/Customized'
import HomogeneousGroupEditForm from '../Components/HomogeneousGroup/HomogeneousGroupEditForm'
import { gql, useMutation, useQuery } from '@apollo/client'
import {
    defaultMutationUpdateCallback,
    notifyFailure,
    notifySuccess,
} from '../utils'
import {
    DELETE_HOMOGENEOUS_GROUP,
    HOMOGENEOUS_GROUPS_QUERY,
    INSERT_HOMOGENEOUS_GROUP,
    UPDATE_HOMOGENEOUS_GROUP,
} from '../Components/HomogeneousGroup/GraphQL_queries'
import { useConfirmDialog } from '../globalState'
import HGRadarGraphSummary from '../Components/HomogeneousGroup/HGRadarGraphSummary'
import { useTranslation } from 'react-i18next'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core'
import CloseButton from '../Components/CloseButton'

const useStyle = makeStyles(() => ({
    verticalGraph: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
}))

// eslint-disable-next-line no-unused-vars
const updateCacheAfterInsertion = (cache, { data }) =>
    defaultMutationUpdateCallback({
        cache,
        mutatedData: data.insertHomogeneousGroup,
        modelName: 'homogeneousGroup',
        modelCollectionName: 'homogeneousGroups',
        gqlFragment: gql`
            fragment NewGroup on HomogeneousGroupType {
                id
                name
                ownerProductionUnit {
                    id
                }
            }
        `,
    })

const updateCacheAfterDeletion = (cache, { data }) =>
    defaultMutationUpdateCallback({
        cache,
        mutatedData: data.deleteHomogeneousGroup,
        modelName: 'homogeneousGroup',
        modelCollectionName: 'homogeneousGroups',
        isDelete: true,
    })

export default function HomogeneousGroupsSection({
    productionUnitId,
    groupsIDs,
    employeesCount,
    insertedEmployees,
    checkEmployees,
    secondBreakpoint,
    ...rest
}) {
    const { t } = useTranslation()
    const { data, refetch } = useQuery(HOMOGENEOUS_GROUPS_QUERY, {
        variables: { ownerProductionUnit: productionUnitId },
    })
    const [_deleteHomogeneousGroup] = useMutation(DELETE_HOMOGENEOUS_GROUP, {
        update: updateCacheAfterDeletion,
        onCompleted: async (data) => {
            await refetch()
        },
    })
    const [insertHomogeneousGroup] = useMutation(INSERT_HOMOGENEOUS_GROUP)
    const [updateHomogeneousGroup] = useMutation(UPDATE_HOMOGENEOUS_GROUP)
    const appHistory = useHistory()
    const [isEditFormOpen, setIsEditFormOpen] = React.useState(false)
    const [selectedItem, setSelectedItem] = React.useState(null)
    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])

    const style = useStyle()

    async function handleFormSubmit(homogeneousGroupData) {
        if (
            insertedEmployees + parseInt(homogeneousGroupData.employeesCount) <=
            employeesCount
        ) {
            rest.setInsertedEmployees(
                insertedEmployees +
                    parseInt(homogeneousGroupData.employeesCount)
            )
        }
        const mutation = homogeneousGroupData.id
            ? updateHomogeneousGroup
            : insertHomogeneousGroup
        try {
            await mutation({
                variables: {
                    ...homogeneousGroupData,
                    productionUnitId: productionUnitId,
                },
            })
            notifySuccess()
            refetch().then((data) => {
                rest.setInsertedEmployees(
                    checkEmployees(data?.data?.homogeneousGroups)
                )
            })

            setIsEditFormOpen(false)
        } catch (e) {
            notifyFailure(e)
        }
        return true
    }

    function deleteHomogeneousGroup({ id }, updatedCount) {
        openConfirm({
            title: t('cancella_og'),
            description: t('cancella_og_descrizione'),
            onConfirm: () => {
                _deleteHomogeneousGroup({ variables: { id } }).catch(
                    notifyFailure
                )
                if (updatedCount > 0) rest.setInsertedEmployees(updatedCount)
                else if (updatedCount === 0) rest.setInsertedEmployees(0)
            },
        })
    }

    function openEditForm(unit) {
        setSelectedItem(unit)
        setIsEditFormOpen(true)
    }

    function handleCreateIntent() {
        setSelectedItem(null)
        setIsEditFormOpen(true)
    }

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <Box
                width="70%"
                mb="20px"
                borderTop="1px solid rgba(0,0,0,0.15)"
            ></Box>
            <Box
                width="100%"
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                pb="5px"
            >
                <Box>
                    <Typography variant="h4">{t('gruppi_omogenei')}</Typography>
                </Box>
                <Box>
                    <IconButton
                        size={'small'}
                        color={'primary'}
                        onClick={handleCreateIntent}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>

            <Box
                width="100%"
                maxWidth={secondBreakpoint ? 'none' : '1146px'}
                display="flex"
                flexDirection={secondBreakpoint ? 'column' : 'row'}
            >
                <Box
                    m="12px 12px 12px 0"
                    className={secondBreakpoint ? style.verticalGraph : ''}
                >
                    <Paper
                        elevation={1}
                        style={{
                            height: '394px',
                            width: '300px',
                            borderRadius: 8,
                        }}
                    >
                        <HGRadarGraphSummary data={data?.homogeneousGroups} />
                    </Paper>
                </Box>
                <Box>
                    <ListHG
                        list={data?.homogeneousGroups}
                        onCreate={handleCreateIntent}
                        onDelete={deleteHomogeneousGroup}
                        onEdit={openEditForm}
                        onItemClick={(unit) =>
                            appHistory.push(`/homogeneousGroups/${unit.id}`)
                        }
                        setInsertedEmployees={rest.setInsertedEmployees}
                        insertedEmployees={insertedEmployees}
                    />
                </Box>
            </Box>
            <CustomDialog
                open={isEditFormOpen}
                aria-labelledby="simple-dialog-title"
                // fullWidth={true}
                maxWidth={'300'}
            >
                <CloseButton
                    title={t('gruppi_omogenei')}
                    onClose={() => setIsEditFormOpen(false)}
                />

                <HomogeneousGroupEditForm
                    key={selectedItem?.id}
                    onSubmit={handleFormSubmit}
                    unitData={selectedItem}
                    employeesCount={employeesCount}
                    insertedEmployees={insertedEmployees}
                    homogeneousGroups={data?.homogeneousGroups}
                    afterSubmit={() => setIsEditFormOpen(!isEditFormOpen)}
                />
            </CustomDialog>
        </Box>
    )
}
