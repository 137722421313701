import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Avatar,
    Box,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BuildIcon from '@material-ui/icons/Build'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import { RiQuestionAnswerFill } from 'react-icons/ri'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { BsTable } from 'react-icons/bs'
import { GET_SCENARIOS_ID } from '../ProductionUnit/GraphQL_queries'
import { useQuery } from '@apollo/client'
import { FaChevronRight } from 'react-icons/fa'
import { RiErrorWarningLine } from 'react-icons/ri'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    drawer: {
        width: 300,
        padding: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        width: '100%',
        height: '100%',
    },
    arrowAnimation: {
        transform: 'translateX(4px)',
    },
}))

export default function Sidebar({ open, setOpen }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const urlLocation = useLocation().pathname
    const history = useHistory()

    const [scenariosIdList, setScenarioIdList] = React.useState([])

    React.useEffect(() => {
        setOpen(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlLocation])

    useQuery(GET_SCENARIOS_ID, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => setScenarioIdList(res.allScenarios),
    })

    const ScenariosAccordion = () => {
        const ScenarioItem = ({ scenario }) => {
            const [isGroupHover, setIsGroupHover] = React.useState(false)

            return (
                <Box
                    onMouseEnter={() => setIsGroupHover(true)}
                    onMouseLeave={() => setIsGroupHover(false)}
                >
                    <ListItem button>
                        <Link
                            className={classes.link}
                            to={'/scenario/' + scenario.id}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                fontSize="16px"
                            >
                                {scenario.productionUnit}
                                <Box
                                    color="#3F51B5"
                                    fontSize="xs"
                                    style={{
                                        transition: 'all 0.2s',
                                    }}
                                    component={FaChevronRight}
                                    className={
                                        isGroupHover
                                            ? classes.arrowAnimation
                                            : ''
                                    }
                                />
                            </Box>
                        </Link>
                    </ListItem>
                </Box>
            )
        }

        return (
            <div className={classes.root}>
                <Accordion elevation="0">
                    <ListItem button>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                                width: '100%',
                                padding: '0',
                                height: '32px',
                                minHeight: '32px',
                            }}
                        >
                            <Box
                                height="100%"
                                display="flex"
                                alignItems="center"
                            >
                                <BsTable
                                    fontSize="22px"
                                    color="#3F51B5"
                                    style={{ marginRight: '34px' }}
                                />
                                <ListItemText>{t('scenari')}</ListItemText>
                            </Box>
                        </AccordionSummary>
                    </ListItem>
                    <AccordionDetails>
                        <Box width="100%">
                            {scenariosIdList.length > 0 ? (
                                scenariosIdList.map((scenario, idx) => (
                                    <Box key={idx}>
                                        <ScenarioItem scenario={scenario} />
                                    </Box>
                                ))
                            ) : (
                                <Box
                                    fontSize="15px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Avatar
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            marginRight: '10px',
                                            backgroundColor: '#F2CD34',
                                        }}
                                    >
                                        <RiErrorWarningLine />
                                    </Avatar>
                                    {t('nessuno_scenario')}
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ className: classes.drawer }}
        >
            <List component="nav">
                <ListItem button>
                    <Link className={classes.link} to="/productionUnits">
                        <Grid container alignItems="center">
                            <ListItemIcon>
                                <AccountTreeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText>{t('unita_produttive')}</ListItemText>
                        </Grid>
                    </Link>
                </ListItem>

                <ListItem button>
                    <Grid
                        container
                        alignItems="center"
                        onClick={(e) => {
                            e.stopPropagation()
                            history.push('/workers')
                        }}
                    >
                        <ListItemIcon>
                            <PeopleIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText>{t('tutti_lavoratori')}</ListItemText>
                    </Grid>
                </ListItem>

                <ScenariosAccordion />

                <ListItem button>
                    <Link className={classes.link} to={'/equipments'}>
                        <Grid container alignItems={'center'}>
                            <ListItemIcon>
                                <BuildIcon color={'primary'} />
                            </ListItemIcon>
                            <ListItemText>
                                {t('mezzi_e_attrezzature')}
                            </ListItemText>
                        </Grid>
                    </Link>
                </ListItem>

                <ListItem button>
                    <Link className={classes.link} to="/allQuestionnaire">
                        <Grid container alignItems="center">
                            <ListItemIcon>
                                <RiQuestionAnswerFill
                                    fontSize="1.5rem"
                                    color="#3F51B5"
                                />
                            </ListItemIcon>
                            <ListItemText>{t('questionari')}</ListItemText>
                        </Grid>
                    </Link>
                </ListItem>

                <ListItem button>
                    <Link className={classes.link} to="/archive_online">
                        <Grid container alignItems="center">
                            <ListItemIcon>
                                <CloudUploadIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText>{t('archivio')}</ListItemText>
                        </Grid>
                    </Link>
                </ListItem>

                <ListItem button>
                    <Link className={classes.link} to="/jobHistory">
                        <Grid container alignItems="center">
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText>{t('job_history')}</ListItemText>
                        </Grid>
                    </Link>
                </ListItem>

                <ListItem button>
                    <Link className={classes.link} to={'/auditLog'}>
                        <Grid container alignItems={'center'}>
                            <ListItemIcon>
                                <ChromeReaderModeIcon color={'primary'} />
                            </ListItemIcon>
                            <ListItemText>{t('audit_logs')}</ListItemText>
                        </Grid>
                    </Link>
                </ListItem>

                {/* <ListItem button>
                    <Link className={classes.link} to="/scenario">
                        <Grid container alignItems="center">
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                {t('configurazione_scenari')}
                            </ListItemText>
                        </Grid>
                    </Link>
                </ListItem> */}
            </List>
        </Drawer>
    )
}
