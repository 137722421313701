import React, { Fragment } from 'react'
import { Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { formatDecimal } from '../../utils'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    gray: {
        color: 'dimgray',
    },
    orange: {
        color: '#ec7120',
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    column: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))
export default function ActivityRiskNumber({ inducedRisk, aggregation }) {
    const { t } = useTranslation()

    const totalRisk = inducedRisk * aggregation
    const classes = useStyle()
    return (
        <div className={classes.container}>
            {inducedRisk && aggregation > 1 && (
                <Fragment>
                    <div className={classes.column}>
                        <Typography
                            variant={'caption'}
                            className={classes.gray}
                        >
                            {inducedRisk}&nbsp;
                        </Typography>
                        <Tooltip title={t('aggregaz_con_terzi')}>
                            <Typography
                                variant={'caption'}
                                className={classes.orange}
                            >
                                x{aggregation}
                            </Typography>
                        </Tooltip>
                    </div>
                    <Typography variant={'caption'} className={classes.gray}>
                        &nbsp;=&nbsp;
                    </Typography>
                </Fragment>
            )}
            <Typography variant={'h6'}> {formatDecimal(totalRisk)}</Typography>
        </div>
    )
}
