import _ from 'lodash'
import i18n from './i18n'
import { toast } from 'react-toastify'
import { theme } from './App'
import provinceJson from './province-italia.json'

toast.configure()

export function notifyFailure(message = i18n.t('toast_generic_error')) {
    toast.error(message)
    console.error(message)
}

export function notifySuccess() {
    toast.success(i18n.t('toast_success'), { autoClose: 2000 })
}

export const percentageToGreenRedRange = (
    hue,
    saturation = 85,
    brightness = 80,
    alpha = 1,
) => {
    if (hue > 100) {
        hue = 100
    }
    const h = 120 - hue * 1.2
    const s = saturation / 100
    const v = brightness / 100
    const f = (n, k = (n + h / 60) % 6) =>
        v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)
    const res = [f(5) * 255, f(3) * 255, f(1) * 255]
    return `rgba(${res[0]},${res[1]},${res[2]}, ${alpha})`
}

/* eslint-disable */
export const blendColors = (c0, c1, p) => {
    //https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
    let f = parseInt(c0.slice(1), 16),
        t = parseInt(c1.slice(1), 16),
        R1 = f >> 16,
        G1 = (f >> 8) & 0x00ff,
        B1 = f & 0x0000ff,
        R2 = t >> 16,
        G2 = (t >> 8) & 0x00ff,
        B2 = t & 0x0000ff
    return (
        '#' +
        (
            0x1000000 +
            (Math.round((R2 - R1) * p) + R1) * 0x10000 +
            (Math.round((G2 - G1) * p) + G1) * 0x100 +
            (Math.round((B2 - B1) * p) + B1)
        )
            .toString(16)
            .slice(1)
    )
}

export const formatDecimal = (someNumber) =>
    Number.isNaN(someNumber) ? '' : _.round(someNumber, 2)

export const doughnutColors = ['green', 'yellow', 'orange', 'red']

export const questionnaireQuestions = [
    'q_esposizione_informative',
    'q_rilevazione_temperatura',
    'q_comunicazione_istruzioni',
    'q_rispetto_distanze',
    'q_utilizzo_dpi',
    'q_organizzazione_squadre',
    'q_utilizzo_percorsi',
    'q_organizzazione_spogliatoi',
    'q_rispetto_scaglioni_ingresso',
    'q_rispetto_turni_mensa',
    'q_rispetto_divieto_accesso',
    'q_rispetto_prescrizioni_sale_controllo',
    'q_rispetto_squadre_stagne',
    'q_deroga_separazione_lavoratori',
    'q_sanificazione_postazioni',
    'q_presenza_dispenser',
    'q_comunicazione_procedure_appaltatori',
    'q_smaltimento_dpi',
    'q_rientri_post_isolamento',
    'q_sanificazione_servizi',
    'q_limitazione_presenza_sito',
    'q_sanificazione_post_contagio',
]

export const userQuestionnaireQuestions = [
    'q_1',
    'q_2',
    'q_3',
    'q_4',
    'q_5',
    'q_6',
    'q_7',
    'q_8',
    'q_9',
    'q_10',
    'q_11',
    'q_12',
    'q_13',
    'q_14',
]


/* eslint-disable */
export function formatAreasForDoughnut(allAreas) {
    const data = {
        datasets: [{ data: [], backgroundColor: doughnutColors, labels: [] }],
        labels: [],
        text: '0%',
    }
    if (!allAreas) return data
    let total = 0
    const defaultAreasNames = [
        i18n.t('rischio_minimo'),
        i18n.t('rischio_basso'),
        i18n.t('rischio_medio'),
        i18n.t('rischio_elevato'),
    ]
    for (let i = 0; i < allAreas.length; i++) {
        const area = allAreas[i]
        data.datasets[0].data.push(
            area.activities.reduce((acc, curr) => {
                total += curr.timePercentage
                return acc + curr.timePercentage
            }, 0),
        ) //somma timePercentage
        data.labels.push(
            area.name || defaultAreasNames[i] || i18n.t('area_senza_nome'),
        )
    }
    data.text = formatDecimal(total) + '%'
    return { data, total }
}

export function getRegionsByProvinces(provincesList) {
    // ["TO", "AO", "RM", "VT"] --> ["piemonte", "valle_daosta", "lazio", "lazio"]
    const regionsKeys = []
    provincesList.forEach((PUprovince) => {
        provinceJson.forEach((italianProvince) => {
            if (italianProvince.sigla === PUprovince) {
                regionsKeys.push(italianProvince.key)
            }
        })
    })
    return _.uniq(regionsKeys)
}

export function employeesStatsToPieChartData(employeesData = []) {
    const statusPalette = theme.palette.status
    const currentData = employeesData.slice(-1)[0]

    return {
        labels: [i18n.t('positivi'), i18n.t('negativi'), i18n.t('quarantena')],
        text: '',
        datasets: [
            {
                data: [
                    currentData?.infetti?.toFixed(2),
                    currentData?.sani?.toFixed(2),
                    // TODO: vaccinati rimossi da RT GIORNALLIERO PIE perche altrimenti la percentuale totale veniva errata, rimuovere anche da composiizione personale ?
                    // currentData?.vaccinati?.toFixed(2),
                    currentData?.quarantena?.toFixed(2),
                ],
                backgroundColor: [
                    statusPalette.danger,
                    statusPalette.ok,
                    // statusPalette.neutral,
                    statusPalette.warning,
                ],
            },
        ],
    }
}

export function calcVaccinatedPercentage(employeesData = []) {
    const currentData = employeesData.slice(-1)[0]
    const totEmployees =
        currentData.sick + currentData.healthy + currentData.dubious
    return Math.round((currentData.vaccinated / totEmployees) * 100)
}

export function calcInfectionsTrend(employeesData = []) {
    const currentData = employeesData.slice(-1)[0]
    let comparisonData
    // compare last 15 days if available data
    if (employeesData?.length >= 15) {
        comparisonData = employeesData.slice(-15)[0]
    } else {
        comparisonData = employeesData[0]
    }
    const trend = currentData
        ? (currentData.infetti - comparisonData.infetti) /
        comparisonData.infetti
        : null
    return trend
}

export function employeesStatsToAreaChartData(employeesData = []) {
    const statusPalette = theme.palette.status
    const sickData = []
    const healthyData = []
    const quarantine = []
    // const vaccinatedData = []
    const labels = []
    employeesData.forEach((dataObj, index) => {
        sickData.push(dataObj.infetti?.toFixed(2))
        healthyData.push(dataObj.sani?.toFixed(2))
        quarantine.push(dataObj.quarantena?.toFixed(2))
        // vaccinatedData.push(dataObj.vaccinati?.toFixed(2))
        labels.push(dataObj?.timestamp)
        // labels.push(index + 1)
    })
    return {
        labels,
        datasets: [
            {
                label: i18n.t('negativi'),
                data: healthyData,
                borderColor: statusPalette.ok,
                backgroundColor: statusPalette.ok,
                fill: true,
            },
            {
                label: i18n.t('quarantena'),
                data: quarantine,
                borderColor: statusPalette.warning,
                backgroundColor: statusPalette.warning,
                fill: true,
            },
            {
                label: i18n.t('positivi'),
                data: sickData,
                borderColor: statusPalette.danger,
                backgroundColor: statusPalette.danger,
                fill: true,
            },
            // {
            //     label: i18n.t('vaccinati'),
            //     data: vaccinatedData,
            //     borderColor: statusPalette.neutral,
            //     backgroundColor: statusPalette.neutral,
            //     // type: 'bubble',
            //     fill: true
            // },
        ],
    }
}

export function countermeasuresToRadarDatatype(mitigationActivities) {
    return {
        labels: mitigationActivities.map((c) => c.name),
        datasets: [
            {
                data: mitigationActivities.map((c) => c.practicalExecution),
                label: i18n.t('estensione'),
                borderColor: 'rgb(7,199,0)',
                backgroundColor: 'rgba(7,199,0,0.43)',
            },
            {
                data: mitigationActivities.map(
                    (c) => (c.practicalEfficacy * c.practicalExecution) / 100,
                ),
                borderColor: 'rgb(46,52,255)',
                label: i18n.t('efficacia_relativa'),
                backgroundColor: 'rgba(46,52,255, 0.64)',
            },
        ],
    }
}

export function countermeasuresToRadarDatatype2(mitigationActivities) {
    const colors = [
        'rgba(218,0,0, 0.6)',
        'rgba(216,107,0, 0.6)',
        'rgba(31,29,227, 0.6)',
        'rgba(116,190,0, 0.6)',
        'rgba(0,140,219, 0.6)',
        'rgba(214,0,213, 0.6)',
        'rgba(0,203,37, 0.6)',
        'rgba(204,199,0, 0.6)',
    ]
    const datasets = []
    const groups = _.groupBy(mitigationActivities, (ma) => ma.group)
    let counter = 0
    const labels = []
    Object.values(groups).map((actGroup, index, groupValues) => {
        const groupColor = colors[index % (colors.length - 1)]
        const groupExtData = new Array(mitigationActivities.length).fill(0)
        const groupEffData = new Array(mitigationActivities.length).fill(0)
        if (index > 0) {
            const previousGroup = groupValues[index - 1]
            const lastElem = previousGroup[previousGroup.length - 1]
            groupEffData[counter - 1] = lastElem.practicalExecution || 0
            groupExtData[counter - 1] =
                (lastElem.practicalExecution * lastElem.practicalEfficacy) /
                100 || 0
        }
        if (index === groupValues.length - 1) {
            const firstGroup = groupValues[0]
            const firstElem = firstGroup[0]

            groupEffData[0] = firstElem.practicalExecution || 0
            groupExtData[0] =
                (firstElem.practicalExecution * firstElem.practicalEfficacy) /
                100 || 0
        }
        actGroup.forEach((activity) => {
            labels.push(activity.name)
            groupExtData[counter] = activity.practicalExecution || 0
            groupEffData[counter] =
                (activity.practicalExecution * activity.practicalEfficacy) /
                100 || 0
            counter++ // track activity # regardless of groups
        })
        datasets.push({
            data: groupExtData,
            label: i18n.t('attuazione'),
            borderColor: 'rgb(138,135,140)',
            backgroundColor: 'rgba(126,122,126,0.43)',
        })
        datasets.push({
            data: groupEffData,
            label: i18n.t('efficacia_relativa'),
            borderColor: groupColor,
            backgroundColor: groupColor,
        })
    })
    return {
        labels: labels,
        datasets: datasets,
        // [
        //   {
        //       data: mitigationActivities.map(c => c.practicalExecution),
        //       label: i18n.t('estensione'),
        //       borderColor: 'rgb(7,199,0)',
        //       backgroundColor: 'rgba(7,199,0,0.43)'
        //   },
        //   {
        //       data: mitigationActivities.map(c => c.practicalEfficacy * c.practicalExecution / 100),
        //       borderColor: 'rgb(46,52,255)',
        //       label: i18n.t('efficacia_relativa'),
        //       backgroundColor: 'rgba(46,52,255, 0.64)'
        //   }]
    }
}

export function defaultMutationUpdateCallback({
                                                  cache,
                                                  mutatedData,
                                                  modelName,
                                                  modelCollectionName,
                                                  gqlFragment,
                                                  isDelete,
                                              }) {
    let model
    if (isDelete) {
        model = { id: mutatedData['deletedId'] } // make sure database is returning this after deletions
    } else {
        model = mutatedData[modelName]
    }
    cache.modify({
        fields: {
            [modelCollectionName]: function(modelCollection = []) {
                if (isDelete) {
                    return modelCollection.filter((m) => m.__ref !== model.id) // graphql is fucking incoherent
                } else {
                    // const { homogeneousGroup } = upsertHomogeneousGroup
                    const foundIndex = _.findIndex(
                        modelCollection,
                        (p) => p.__ref === model.id,
                    )
                    if (foundIndex >= 0) {
                        // so found it
                        return [
                            ...modelCollection.slice(0, foundIndex),
                            { ...modelCollection[foundIndex], ...model },
                            ...modelCollection.slice(
                                foundIndex + 1,
                                modelCollection.length,
                            ),
                        ]
                    } else {
                        const newModelRef = cache.writeFragment({
                            id: model.id,
                            data: model,
                            fragment: gqlFragment,
                        })
                        return [...modelCollection, newModelRef]
                    }
                }
            },
        },
    })
}
