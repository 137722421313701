import React from 'react'
import { Menu, MenuItem, Box } from '@material-ui/core'
import CrudGrid from '../CrudGrid'
import CardPU from './CardPU'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function ProductionUnitsScreen({
    unitsList,
    onUnitClick,
    onDelete,
    onEdit,
    onWorker,
}) {
    const { t } = useTranslation()
    const history = useHistory()

    const [anchorEl, setAnchorEl] = React.useState({
        domElement: null,
        data: null,
    })

    const handleMoreClick = (event, data) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl({ domElement: event.currentTarget, data })
    }

    const handleMenuClose = () => {
        setAnchorEl({ domElement: null, data: null })
    }

    const handleDeletePU = () => {
        handleMenuClose()
        anchorEl?.data?.id && onDelete(anchorEl.data)
    }

    const handleEdit = () => {
        handleMenuClose()
        anchorEl?.data?.id && onEdit(anchorEl.data)
    }

    const handleRedirect = () => {
        handleMenuClose()
        history.push('/scenario/' + anchorEl?.data?.id)
    }

    const insertWorker = () => {
        handleMenuClose()
        history.push('/workers/' + anchorEl?.data?.id)
        // anchorEl?.data?.id && onWorker(anchorEl.data)
    }

    const CardPUWithFunctions = (item) => {
        return (
            <CardPU
                handleMoreClick={(e) => handleMoreClick(e, item)}
                {...item}
            />
        )
    }

    return (
        <Box>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl?.domElement}
                keepMounted
                open={Boolean(anchorEl?.domElement)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>{t('modifica')}</MenuItem>
                <MenuItem onClick={handleRedirect}>
                    {t('config_scenery')}
                </MenuItem>
                <MenuItem onClick={insertWorker}>
                    {t('inserisci_lavoratore')}
                </MenuItem>
                <MenuItem style={{ color: 'red' }} onClick={handleDeletePU}>
                    {t('elimina')}
                </MenuItem>
            </Menu>

            <CrudGrid
                items={unitsList}
                onItemClick={onUnitClick}
                renderItem={CardPUWithFunctions}
            />
        </Box>
    )
}
