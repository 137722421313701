/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    MenuItem,
    Checkbox,
    ListSubheader,
    TextField,
    FormControl,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Controller, useForm } from 'react-hook-form'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
    ADD_WORKER_QUERY,
    GET_HOMOGENEOUS_GROUP_QUERY,
    GET_SELECT_SHIFTS,
    GET_WORKERS,
    REFRESH_STATS,
    UPDATE_WORKER,
} from './GraphQL_queries'
import { useTranslation } from 'react-i18next'
import { notifyFailure, notifySuccess } from '../../utils'
import {
    OutlinedSelect,
    OutlinedTextField,
} from '../Customized/CustomComponents'
import { useParams } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'

const useStyle = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2),
        alignItems: 'center',
        display: 'flex',
        margin: 'auto',
    },
    formField: {
        margin: theme.spacing(1),
    },
    formSelectField: {
        margin: theme.spacing(1),
        minWidth: 195,
    },
    menuItem: {
        '&:hover': {
            backgroundColor: '#dedede',
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonsBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
}))

export default function ProductionUnitWorkerForm({
    onSubmit,
    workerData,
    unitID,
    hgID,
    full,
    setFull,
    ...rest
}) {
    const [workers, setWorkers] = React.useState(null)
    const [shifts, setShifts] = React.useState(
        workerData ? [workerData?.turno] : null
    )
    const [homogeneousGroups, setHomogeneousGroups] = React.useState(
        workerData ? [workerData?.gruppoOmogeneo] : []
    )
    const [isCheckedVac, setIsCheckedVac] = React.useState(
        workerData ? workerData.vaccinato : false
    )
    const [refreshStats] = useLazyQuery(REFRESH_STATS, {
        fetchPolicy: 'network-only',
    })

    const [isValid, setIsValid] = React.useState(true)
    const [isMissingContent, setIsMissingContent] = React.useState(false)
    const invalidCount = React.useRef(0)
    const missingCount = React.useRef(0)
    const [missingHistory, setMissingHistory] = React.useState({
        name: false,
        cognome: false,
    })

    const checkEmptiness = (value) => {
        if (value === '') {
            invalidCount.current += 1
        } else {
            if (invalidCount.current > 0) invalidCount.current -= 1
        }

        if (invalidCount.current !== 0) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }

    const checkMissingContent = (value, name) => {
        if (
            value.replace(/\s/g, '').length === 0 &&
            value.length === 1 &&
            !missingHistory[name]
        ) {
            setMissingHistory((prevValue) => {
                return {
                    ...prevValue,
                    [name]: true,
                }
            })
            missingCount.current += 1
        } else if (value.length === 0) {
            if (missingCount.current > 0) {
                setMissingHistory((prevValue) => {
                    return {
                        ...prevValue,
                        [name]: false,
                    }
                })
                missingCount.current -= 1
            }
        }

        if (missingCount.current !== 0) {
            setIsMissingContent(true)
        } else {
            setIsMissingContent(false)
        }
    }

    const validate = (value, name) => {
        checkEmptiness(value)
        checkMissingContent(value, name)
    }

    const { handleSubmit, formState, control, setValue } = useForm({
        defaultValues: workerData && {
            statoSalute: workerData?.statoSalute,
            turno: workerData?.turno?.id,
            responsabile: workerData?.responsabile?.id,
            nome: workerData?.nome,
            cognome: workerData?.cognome,
            vaccinato: workerData?.vaccinato,
            nMatricola: workerData?.nMatricola,
            id: workerData?.id,
            note: workerData?.note,
        },
    })
    const { isSubmitting, isDirty } = formState

    const { t } = useTranslation()
    const classes = useStyle(rest)

    // eslint-disable-next-line no-unused-vars
    const unitId = useParams()
    const [updateWorker] = useMutation(UPDATE_WORKER)
    const [getWorkers, workersQuery] = useLazyQuery(GET_WORKERS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        onCompleted: (data) => {
            setWorkers(data?.workersByHg)
        },
    })
    const [getShifts, shiftsQuery] = useLazyQuery(GET_SELECT_SHIFTS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        onCompleted: (data) => {
            setShifts(data)
        },
    })
    const [insertWorker] = useMutation(ADD_WORKER_QUERY)
    // eslint-disable-next-line no-unused-vars
    const [hg, setHg] = useState([])

    const { data } = useQuery(GET_HOMOGENEOUS_GROUP_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            unitId: unitID && unitID,
        },
        onCompleted: (result) => {
            if (workerData) {
                let hg = result.productionUnits.filter(
                    (unit) =>
                        unit.id ===
                        workerData?.gruppoOmogeneo?.ownerProductionUnit?.id
                )[0].homogeneousGroups
                setHomogeneousGroups(hg)
            } else if (unitID) {
                let hg = result.productionUnits.filter(
                    (unit) => unit.id === unitID
                )[0].homogeneousGroups
                setHomogeneousGroups(hg)
                if (hgID) {
                    let currentHg = hg.filter((hg) => hg.id === hgID)[0]
                    let count = 0
                    count = currentHg?.workers?.length
                    if (count + 1 > currentHg.employeesCount) {
                        setFull(true)
                        notifyFailure(
                            'Hai inserito tutti i lavoratori per questo gruppo, selezionane uno diverso!'
                        )
                    }
                }
            } else
                setHomogeneousGroups(result?.productionUnits?.homogeneousGroups)
        },
    })

    useEffect(() => {
        if (workersQuery.data !== undefined && workerData === undefined)
            setWorkers(workersQuery.data?.workersByHg)
        else if (workersQuery.data !== undefined && workerData) {
            let updatedWorkers = workersQuery?.data?.workersByHg?.filter(
                (worker) => worker.id !== workerData.id
            )
            setWorkers(updatedWorkers)
        }
    }, [workersQuery.data])

    const onHomogeneousGroupChange = (id) => {
        if (workerData && workerData.gruppoOmogeneo.id === id) {
            setFull(false)
            getWorkers({
                variables: { homogeneousGroupId: id },
            })
            getShifts({
                variables: { homogeneousGroupId: id },
            })
        } else {
            let currentGroup = homogeneousGroups?.filter(
                (group) => group.id === id
            )[0]
            let count = 0
            count = currentGroup?.workers?.length
            if (
                count + 1 === currentGroup?.employeesCount ||
                count + 1 < currentGroup?.employeesCount
            ) {
                setFull(false)
                getWorkers({
                    variables: { homogeneousGroupId: id },
                })
                getShifts({
                    variables: { homogeneousGroupId: id },
                })
            } else {
                setFull(true)
                notifyFailure(
                    'Hai inserito tutti i lavoratori per questo gruppo, selezionane uno diverso!'
                )
            }
        }
    }

    useEffect(() => {
        if (workerData) {
            setValue('gruppoOmogeneo', workerData?.gruppoOmogeneo?.id)
            getWorkers({
                variables: {
                    homogeneousGroupId: workerData?.gruppoOmogeneo?.id,
                },
            })
            getShifts({
                variables: {
                    homogeneousGroupId: workerData?.gruppoOmogeneo?.id,
                },
            })
        } else if (hgID) {
            setValue('gruppoOmogeneo', hgID)
            getWorkers({
                variables: { homogeneousGroupId: hgID },
            })
            getShifts({
                variables: { homogeneousGroupId: hgID },
            })
        }
    }, [])

    const handleWorkerFormSubmit = (data) => {
        insertWorker({
            variables: {
                ...{ ...data },
                gruppoOmogeneoId: data?.gruppoOmogeneo,
            },
        })
            .then(() => {
                refreshStats()
                rest.afterSubmit()
                notifySuccess()
                return Promise.resolve()
            })
            .catch(notifyFailure)
    }

    const handleEdit = (data) => {
        if (workerData?.turno?.id) {
            let filteredShifts = shiftsQuery.data.selectShifts.filter(
                (item) => item.id === workerData.turno.id
            )
            if (filteredShifts.length === 0) data.turno = null
        }
        let id = data.gruppoOmogeneo
        delete data['gruppoOmogeneo']
        updateWorker({
            variables: {
                workerData: data,
                gruppoOmogeneoId: id,
            },
        })
            .then(() => {
                refreshStats()
                notifySuccess(t('lavoratore_edit_success'))
                rest.afterSubmit()
            })
            .catch(() => notifyFailure())
    }

    const listStatoSaluteItems = (menuItems) => {
        return menuItems.map((item, i) => {
            return (
                <MenuItem
                    key={i}
                    value={item.toUpperCase()}
                    className={classes.menuItem}
                >
                    {t(item)}
                </MenuItem>
            )
        })
    }

    return (
        <form
            id="shift-form"
            onSubmit={handleSubmit(
                workerData ? handleEdit : handleWorkerFormSubmit
            )}
            className={classes.form}
        >
            <Box>
                <Box>
                    <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                className={classes.formField}
                                label={t('Nome')}
                                name="name"
                                onChange={(e) => {
                                    validate(e.target.value, e.target.name)
                                    onChange(e.target.value)
                                }}
                                value={value}
                            />
                        )}
                        name={'nome'}
                    />
                    <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                className={classes.formField}
                                label={t('Cognome')}
                                name="cognome"
                                onChange={(e) => {
                                    validate(e.target.value, e.target.name)
                                    onChange(e.target.value)
                                }}
                                value={value}
                            />
                        )}
                        name={'cognome'}
                    />
                </Box>
                <Box>
                    <Controller
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                className={classes.formField}
                                name={'nMatricola'}
                                label={t('Matricola')}
                                type={'number'}
                                onChange={(e) => {
                                    checkEmptiness(e.target.value)
                                    if (e.target.value < 0) {
                                        onChange(0)
                                    } else {
                                        onChange(e.target.value)
                                    }
                                }}
                                value={value}
                            />
                        )}
                        name={'nMatricola'}
                    />
                    <Controller
                        control={control}
                        name={'statoSalute'}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedSelect
                                key={'statoSalute'}
                                passClassName={classes.formSelectField}
                                onChange={(e) => {
                                    checkEmptiness(e.target.value)
                                    onChange(e.target.value)
                                }}
                                value={value}
                                // className={classes.formField}
                                label={t('stato_salute')}
                            >
                                {listStatoSaluteItems([
                                    'sano',
                                    'infetto',
                                    'quarantena',
                                ])}
                            </OutlinedSelect>
                        )}
                    />
                </Box>
                <Box>
                    <OutlinedSelect
                        key={'productionUnit'}
                        passClassName={classes.formSelectField}
                        label={t('unita')}
                        onChange={(e) => {
                            checkEmptiness(e.target.value)
                            setHomogeneousGroups(
                                data.productionUnits.filter(
                                    (unit) => unit.id === e.target.value
                                )[0].homogeneousGroups
                            )
                        }}
                        defaultValue={
                            unitID
                                ? unitID
                                : workerData &&
                                  workerData.gruppoOmogeneo.ownerProductionUnit
                                      .id
                        }
                    >
                        {data?.productionUnits.map((unit, key) => (
                            <MenuItem
                                key={key}
                                value={unit?.id}
                                className={classes.menuItem}
                            >
                                {unit.name}
                            </MenuItem>
                        ))}
                    </OutlinedSelect>
                    <Controller
                        control={control}
                        name={'gruppoOmogeneo'}
                        rules={{ required: true }}
                        render={({
                            field: {
                                onChange,
                                value = workerData?.gruppoOmogeneo
                                    ? workerData?.gruppoOmogeneo
                                    : hgID && hgID,
                            },
                        }) => (
                            <OutlinedSelect
                                key={'gruppoOmogeneo'}
                                passClassName={classes.formSelectField}
                                label={t('gruppo')}
                                onChange={(e) => {
                                    checkEmptiness(e.target.value)
                                    onChange(e.target.value)
                                    onHomogeneousGroupChange(e.target.value)
                                }}
                                value={value}
                            >
                                {homogeneousGroups?.length > 0 ? (
                                    homogeneousGroups.map((group, i) => (
                                        <MenuItem
                                            key={i}
                                            value={group.id}
                                            className={classes.menuItem}
                                        >
                                            {group.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <ListSubheader>
                                        {t('no_OG_warning')}
                                    </ListSubheader>
                                )}
                            </OutlinedSelect>
                        )}
                    />
                    {/*<Controller*/}
                    {/*    control={control}*/}
                    {/*    name={'gruppoOmogeneo'}*/}
                    {/*    render={({ field: { onChange, value } }) => (*/}
                    {/*        <Autocomplete*/}
                    {/*            id={"autcomplete-gruppo-omogeneo"}*/}
                    {/*            options={workers ? workers : []}*/}
                    {/*            onChange={(e, value) => {*/}
                    {/*                onChange(value?.id)*/}
                    {/*            }}*/}
                    {/*            value={typeof(value) === "object" ? value : workerData && workerData.responsabile}*/}
                    {/*            getOptionLabel={(option) => `${option.nome} - (mat. ${option.nMatricola})`}*/}
                    {/*            getOptionSelected={(option, value) => `${option.nome} - (mat. ${option.nMatricola})`}*/}
                    {/*            className={classes.formSelectField}*/}
                    {/*            renderInput={(params) => <TextField {...params} label="Responsabile" variant="outlined" />}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*/>*/}
                </Box>
                <Box>
                    <Controller
                        control={control}
                        name={'turno'}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedSelect
                                key={'turno'}
                                passClassName={classes.formSelectField}
                                label={t('turno')}
                                onChange={(e) => {
                                    onChange(e.target.value)
                                }}
                                value={value}
                            >
                                {shifts?.selectShifts?.length > 0 ? (
                                    shifts?.selectShifts?.map((item) => (
                                        <MenuItem value={item.id}>
                                            {item.nome}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <ListSubheader>
                                        {t('no_shift_warning')}
                                    </ListSubheader>
                                )}
                            </OutlinedSelect>
                        )}
                    />

                    <Controller
                        control={control}
                        name={'responsabile'}
                        render={({ field: { onChange, value } }) => (
                            <FormControl
                                variant={'outlined'}
                                className={classes.formSelectField}
                            >
                                <Autocomplete
                                    id={'autcomplete-responsabile'}
                                    options={workers ? workers : []}
                                    onChange={(e, value) => {
                                        onChange(value?.id)
                                    }}
                                    value={
                                        typeof value === 'object'
                                            ? value
                                            : workerData &&
                                              workerData.responsabile
                                    }
                                    getOptionLabel={(option) =>
                                        `${option.nome} - (mat. ${option.nMatricola})`
                                    }
                                    getOptionSelected={(option, value) =>
                                        `${option.nome} - (mat. ${option.nMatricola})`
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Responsabile"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </FormControl>
                        )}
                    />
                </Box>
                <Box display="flex">
                    <Box flex="1">
                        <Controller
                            control={control}
                            name={'note'}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    className={classes.formField}
                                    multiline
                                    variant="outlined"
                                    label={t('note')}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                />
                            )}
                        />
                    </Box>
                    <Box
                        flex="1"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Controller
                            control={control}
                            name={'vaccinato'}
                            render={({ field: { onChange, value } }) => (
                                <Checkbox
                                    onChange={(e) => {
                                        onChange(e)
                                        setIsCheckedVac(!isCheckedVac)
                                    }}
                                    checked={isCheckedVac}
                                    value={isCheckedVac}
                                    color="primary"
                                />
                            )}
                        />
                        {t('vaccinato')}
                    </Box>

                    {/*<Checkbox*/}
                    {/*    {...register('vaccinato')}*/}
                    {/*    onChange={() => setIsCheckedVac(!isCheckedVac)}*/}
                    {/*    checked={isCheckedVac}*/}
                    {/*    value={isCheckedVac}*/}
                    {/*    color='primary'*/}
                    {/*/> Vaccinato*/}
                </Box>
                <Box className={classes.buttonsBox}>
                    <Button
                        variant="contained"
                        size={'small'}
                        style={{
                            backgroundColor: 'rgba(42,42,42,0.81)',
                            color: 'white',
                        }}
                        className={classes.button}
                        onClick={() => {
                            rest.onClose()
                        }}
                    >
                        {t('chiudi')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size={'small'}
                        type={'submit'}
                        disabled={
                            isMissingContent ||
                            !((isSubmitting || isDirty) && isValid) ||
                            full
                        }
                        className={classes.button}
                    >
                        {t('salva')}
                    </Button>
                </Box>
            </Box>
        </form>
    )
}
