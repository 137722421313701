import React, { useState } from 'react'
import {
    Box,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Controller, useForm } from 'react-hook-form'
import provinces from '../../province-italia.json'
import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import { useConfirmDialog } from '../../globalState'
import { useLazyQuery } from '@apollo/client'
import { IMPORT_SCENARIO } from './GraphQL_queries'
import Typography from '@material-ui/core/Typography'

function ATECOInputField({
    defaultValue,
    value,
    callback,
    onChange,
    validate,
    ...props
}) {
    const [localValue, setLocalValue] = React.useState(defaultValue || '')

    function restrictInput(e) {
        const value = e.target.value.toUpperCase()
        const regex = new RegExp(/^[A-Z]{1,2}((\.(\d+)){1,5})?(\.)?$/)
        if (value === '' || value.match(regex)) {
            setLocalValue(value)
            callback && callback(e.target.value)
        }
    }

    return (
        <OutlinedTextField
            {...props}
            value={localValue}
            name="ateco"
            onChange={(e) => {
                restrictInput(e)
                validate(e.target.value)
                onChange(e.target.value)
            }}
        />
    )
}

const OutlinedTextField = (props) => (
    <TextField variant={'outlined'} {...props} />
)

const OutlinedSelect = ({ label, passClassName, ...props }) => (
    <FormControl
        fullWidth={true}
        variant={'outlined'}
        className={passClassName}
    >
        <InputLabel id={`${props.name}-label`}>{label}</InputLabel>
        <Select label={label} labelId={`${props.name}-label`} {...props} />
    </FormControl>
)

const useStyle = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2),
        alignItems: 'center',
        display: 'flex',
        margin: 'auto',
    },
    formField: {
        margin: theme.spacing(1),
    },
    formSelectField: {
        minWidth: 140,
    },
    menuItem: {
        '&:hover': {
            backgroundColor: '#dedede',
        },
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonsBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
}))

export default function ProductionUnitEditForm({
    unitData,
    onSubmit,
    units,
    ...rest
}) {
    const classes = useStyle(rest)
    const { t } = useTranslation()
    const [isRestrained, setIsRestrained] = useState(
        unitData ? unitData?.restrainingBlock : false
    )
    const { register, handleSubmit, formState, control } = useForm()
    const { isSubmitting, isDirty } = formState
    const unit = unitData || {}

    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])
    const [importScenario] = useLazyQuery(IMPORT_SCENARIO)

    const [isValid, setIsValid] = React.useState(true)
    const [isMissingContent, setIsMissingContent] = React.useState(false)
    const invalidCount = React.useRef(0)
    const missingCount = React.useRef(0)
    const [isNumerousnessValid, setIsNumerousnessValid] = React.useState(false)
    const [missingHistory, setMissingHistory] = React.useState({
        name: false,
        indirizzo: false,
    })

    const handleNumerousnessChange = (value) => {
        let total = 0;
        console.log(unitData)
        unitData.homogeneousGroups.map(hg => total += hg.employeesCount)
        if (value >= total) {
            setIsNumerousnessValid(false)
        } else setIsNumerousnessValid(true)
    }

    const checkEmptiness = (value) => {
        if (value === '') {
            invalidCount.current += 1
        } else {
            if (invalidCount.current > 0) invalidCount.current -= 1
        }

        if (invalidCount.current !== 0) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }

    const checkMissingContent = (value, name) => {
        if (
            value.replace(/\s/g, '').length === 0 &&
            value.length === 1 &&
            !missingHistory[name]
        ) {
            setMissingHistory((prevValue) => {
                return {
                    ...prevValue,
                    [name]: true,
                }
            })
            missingCount.current += 1
        } else if (value.length === 0) {
            if (missingCount.current > 0) {
                setMissingHistory((prevValue) => {
                    return {
                        ...prevValue,
                        [name]: false,
                    }
                })
                missingCount.current -= 1
            }
        }

        if (missingCount.current !== 0) {
            setIsMissingContent(true)
        } else {
            setIsMissingContent(false)
        }
    }

    const validate = (value, name) => {
        checkEmptiness(value)
        checkMissingContent(value, name)
    }

    function submitWithId(values) {
        onSubmit({ id: unitData?.id, ...values })
    }

    return (
        <form onSubmit={handleSubmit(submitWithId)} className={classes.form}>
            <Box>
                <Box>
                    <Controller
                        control={control}
                        name={'name'}
                        rules={unit ? { required: false } : { required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                defaultValue={unit.name}
                                className={classes.formField}
                                label={t('Nome')}
                                name="name"
                                value={value}
                                onChange={(e) => {
                                    validate(e.target.value, e.target.name)
                                    onChange(e.target.value)
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={'industryCode'}
                        rules={unit ? { required: false } : { required: true }}
                        render={({ field: { onChange, value } }) => (
                            <ATECOInputField
                                defaultValue={unit.industryCode}
                                className={classes.formField}
                                label={t('codice_ateco')}
                                {...register(
                                    'industryCode',
                                    unit
                                        ? { required: false }
                                        : { required: true }
                                )}
                                value={value}
                                onChange={onChange}
                                validate={checkEmptiness}
                            />
                        )}
                    />
                </Box>
                <Box>
                    <Controller
                        control={control}
                        name={'employeesCount'}
                        rules={unit ? { required: false } : { required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                defaultValue={unit.employeesCount}
                                className={classes.formField}
                                // {/*{...register('employeesCount', unit ? { required: false } : { required: true })}*/}
                                type={'number'}
                                label={t('numerosita')}
                                value={value}
                                error={isNumerousnessValid}
                                onChange={(e) => {
                                    checkEmptiness(e.target.value)
                                    handleNumerousnessChange(e.target.value)
                                    if (e.target.value < 0) {
                                        onChange(0)
                                    } else {
                                        onChange(e.target.value)
                                    }
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={'address'}
                        rules={unit ? { required: false } : { required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                defaultValue={unit.address}
                                className={classes.formField}
                                // {...register('address', unit ? { required: false } : { required: true })}
                                label={t('indirizzo')}
                                name="indirizzo"
                                value={value}
                                onChange={(e) => {
                                    validate(e.target.value, e.target.name)
                                    onChange(e.target.value)
                                }}
                            />
                        )}
                    />
                </Box>
                <Box>
                    <OutlinedSelect
                        key={'province'}
                        passClassName={classes.formSelectField}
                        className={classes.formField}
                        defaultValue={unit.province}
                        {...register(
                            'province',
                            unit ? { required: false } : { required: true }
                        )}
                        label={t('provincia')}
                    >
                        {provinces.map((o, i) => (
                            <MenuItem
                                key={i}
                                value={o.sigla}
                                className={classes.menuItem}
                            >
                                {o.nome}
                            </MenuItem>
                        ))}
                    </OutlinedSelect>
                    <Controller
                        control={control}
                        name={'restrainingBlock'}
                        render={({ field: { onChange, value } }) => (
                            <Checkbox
                                onChange={(e) => {
                                    onChange(e)
                                    setIsRestrained(!isRestrained)
                                }}
                                checked={isRestrained}
                                value={isRestrained}
                                color="primary"
                            />
                        )}
                    />
                    {t('blocco_restrittivo')}
                </Box>
                <Box width={"100%"} m={"10px"}>
                    { isNumerousnessValid && <Typography variant={"body2"} style={{color: "red"}}>
                        Per togliere diminuisci la numerosità dei gruppi omogenei !
                    </Typography> }
                </Box>
                <Box className={classes.buttonsBox}>
                    <Button
                        style={{
                            alignSelf: 'flex-end',
                            backgroundColor: 'rgba(42,42,42,0.81)',
                            color: 'white',
                        }}
                        variant={'contained'}
                        size={'small'}
                        onClick={() => rest.afterSubmit()}
                        className={classes.button}
                    >
                        {t('chiudi')}
                    </Button>
                    <Button
                        style={{ alignSelf: 'flex-end' }}
                        variant={'contained'}
                        color={'primary'}
                        type="submit"
                        size={'small'}
                        disabled={
                            isMissingContent ||
                            !((isSubmitting || isDirty) && isValid) || isNumerousnessValid
                        }
                        className={classes.button}
                    >
                        {t('salva')}
                    </Button>
                </Box>
                {unitData && (
                    <Box>
                        <Divider />
                        <Box mt={'8px'}>
                            <OutlinedSelect
                                passClassName={classes.formSelectField}
                                className={classes.formField}
                                label={t('importa_scenario_da')}
                                onChange={(e) => {
                                    openConfirm({
                                        title: t('importa_scenario'),
                                        description: t(
                                            'importa_scenario_descrizione'
                                        ),
                                        onConfirm: () => {
                                            importScenario({
                                                variables: {
                                                    fromId: e.target.value,
                                                    toId: unitData.id,
                                                },
                                            })
                                        },
                                    })
                                }}
                                // value={hgToCopy}
                                disabled={unitData === null}
                            >
                                {units
                                    .filter((u) => u.id !== unitData.id)
                                    .map((unit, index) => (
                                        <MenuItem
                                            key={index}
                                            value={unit.id}
                                            className={classes.menuItem}
                                        >
                                            {unit.name}
                                        </MenuItem>
                                    ))}

                                {/*<MenuItem value={'none'} className={classes.menuItem}>*/}
                                {/*    {t('nessuno')}*/}
                                {/*</MenuItem>*/}
                            </OutlinedSelect>
                        </Box>
                    </Box>
                )}
            </Box>
        </form>
    )
}
