import React from 'react'
import { Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    stepStyle: {
        marginBottom: '20px',
    },
    stepTextStyle: {
        fontWeight: 'bold',
        marginBottom: '4px',
    },
}))

export default function StepItem({ step, ...props }) {
    const classes = useStyles()

    return (
        <Box className={classes.stepStyle}>
            <Typography className={classes.stepTextStyle} align="left">
                Step {step}:
            </Typography>

            {props.children}

            <Box mt="20px">
                <Divider variant="middle" />
            </Box>
        </Box>
    )
}
