import { Box, DialogTitle } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

const CloseButton = ({ title, onClose }) => {
    return (
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <Box>
                <DialogTitle
                    id='simple-dialog-title'
                    style={{ padding: "16px"}}
                >
                    {title}
                </DialogTitle>
            </Box>
            <Box>
                <IconButton
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default CloseButton