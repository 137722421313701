import React, { useState, useEffect } from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Fab,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@material-ui/core'
import {
    DELETE_WORKER,
    GET_ALL_WORKERS,
    REFRESH_STATS,
} from '../Components/ProductionUnit/GraphQL_queries'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { CustomDialog } from '../Components/Customized'
import ProductionUnitWorkerForm from '../Components/ProductionUnit/ProductionUnitWorkerForm'
import AddIcon from '@material-ui/icons/Add'
import { notifyFailure, notifySuccess } from '../utils'
import { useConfirmDialog } from '../globalState'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CloseButton from '../Components/CloseButton'
import FiltersBar from '../Components/FiltersBar'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        // width: '100%',
        margin: theme.spacing(2),

        // padding: theme.spacing(2)
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    fab: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        margin: '20px',
        '&.MuiDivider-root': {
            background: '#6495ED',
        },
    },
    outlinedSelect: {
        // margin: theme.spacing(1),
        margin: theme.spacing(2),
        width: '400px',
    },
    button: {
        margin: theme.spacing(2),
    },
}))

function EnhancedTableHead(props) {
    const { t } = useTranslation()
    const { classes, order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    const headCells = [
        { id: 'nome', numeric: false, disablePadding: true, label: t('Nome') },
        {
            id: 'cognome',
            numeric: false,
            disablePadding: false,
            label: t('Cognome'),
        },
        {
            id: 'n_matricola',
            numeric: false,
            disablePadding: false,
            label: t('n_matricola'),
        },
        {
            id: 'responsabile',
            numeric: false,
            disablePadding: false,
            label: t('responsabile'),
        },
        {
            id: 'stato_salute',
            numeric: false,
            disablePadding: false,
            label: t('stato_salute'),
        },
        {
            id: 'vaccinato',
            numeric: false,
            disablePadding: false,
            label: t('vaccinato'),
        },
        {
            id: 'turno',
            numeric: false,
            disablePadding: false,
            label: t('turno'),
        },
        {
            id: 'gruppo_omogeneo',
            numeric: false,
            disablePadding: false,
            label: t('gruppo_omogeneo'),
        },
        {
            id: 'note',
            numeric: false,
            disablePadding: false,
            label: t('note'),
        },
    ]

    return (
        <TableHead>
            {/*<TableRow>*/}
            {/*    <TableCell colSpan={9}>*/}
            {/*        <FiltersBar*/}
            {/*            unitID={props.unitID && props.unitID}*/}
            {/*            hgID={props.hgID && props.hgID}*/}
            {/*            applyFilters={props.applyFilters}*/}
            {/*            resetFilters={props.resetFilters}*/}
            {/*        />*/}
            {/*    </TableCell>*/}
            {/*</TableRow>*/}
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? t('discendente')
                                        : t('ascendente')}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

// eslint-disable-next-line no-unused-vars
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight: {
        color: theme.palette.text.primary,
        backgroundColor: lighten('#3f51b5', 0.9),
    },
    title: {
        flex: '1 1 100%',
    },
}))

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const WorkerScreenTest = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const params = useParams()
    const unitID = params?.unitID && params?.unitID
    const hgId = params?.hgID && params.hgID
    const [full, setFull] = useState(false)
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState(null)
    const [page, setPage] = React.useState(0)
    // eslint-disable-next-line no-unused-vars
    const [dense, setDense] = React.useState(false)
    const [rows, setRows] = React.useState([])
    // eslint-disable-next-line no-unused-vars
    const [originalRows, setOriginalRows] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [originalLength, setOriginalLength] = useState(0)
    const [rowsCount, setRowsCount] = React.useState(0)
    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const [isWorkerFormOpen, setIsWorkerFormOpen] = React.useState(false)
    const [isInsertFormOpen, setIsInsertFormOpen] = useState(
        unitID ? true : false
    )

    const [removeWorker] = useMutation(DELETE_WORKER)

    // eslint-disable-next-line no-unused-vars
    const [filterBy, setFilterBy] = useState({ hg: null, pu: null })
    const [refreshStats] = useLazyQuery(REFRESH_STATS, {
        fetchPolicy: 'network-only',
    })
    const { fetchMore } = useQuery(GET_ALL_WORKERS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: {
            perPage: 25,
            page: page,
            filters: {
                pu: unitID ? unitID : null,
            },
        },
        onCompleted: (result) => {
            setRows(result?.workers?.workers)
            setOriginalRows(result?.workers?.workers)
            setRowsCount(result?.workers?.totalCount)
        },
    })

    useEffect(() => {
        setRows(
            // eslint-disable-next-line array-callback-return
            rows.filter((value) => {
                if (filterBy.hg) {
                    return value === filterBy.hg
                } else if (filterBy.pu) {
                    return value === filterBy.pu
                }
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterBy])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    async function deleteWorker(id) {
        if (id !== undefined) {
            openConfirm({
                title: t('elimina_lavoratore_titolo'),
                description: t('elimina_lavoratore_descrizione'),
                onConfirm: () =>
                    removeWorker({
                        variables: {
                            workerId: id,
                        },
                    }).then(async () => {
                        notifySuccess(t('elimina_lavoratore_successo'))
                        setFull(false)
                        refreshStats()
                        setRowsCount((prevState) => prevState - 1)
                        if (!selectedPuId) {
                            resetFilters()
                        } else {
                            applyFilters(selectedPuId, selectedHgId)
                        }

                        setPage(parseInt(rowsCount / rowsPerPage))
                    }),
            })
        }
    }

    // eslint-disable-next-line no-unused-vars
    const handleClick = (event, name) => {
        if (name === selected) setSelected(null)
        else setSelected(name)
    }

    const handleChangePage = (event, newPage) => {
        // setLoading(true)
        setPage(newPage)
        fetchMore({
            variables: {
                perPage: rowsPerPage,
                page: newPage,
                filters: {
                    pu: unitID && unitID,
                },
            },
        }).then((result) => {
            setRows(result?.data?.workers?.workers)
            // setOriginalRows(result?.data?.workers?.workers)
        })
    }

    const handleChangeRowsPerPage = (event) => {
        // setLoading(true)
        setRowsPerPage(parseInt(event.target.value, 10))
        fetchMore({
            variables: {
                perPage: parseInt(event.target.value, 10),
                page: page,
            },
        }).then((result) => {
            setRows(result?.data?.workers?.workers)
            // setOriginalRows(result?.data?.workers?.workers)
        })
        setPage(0)
    }

    const getWorkerFromId = (workerId) => {
        return rows?.filter((worker) => worker.id === workerId)[0]
    }

    const [selectedPuId, setSelectedPuId] = useState(false)
    const [selectedHgId, setSelectedHgId] = useState(false)

    const applyFilters = async (productionUnitId, homogeneousGroupId) => {
        let filters = {}
        if (productionUnitId && homogeneousGroupId) {
            homogeneousGroupId === 'Tutti'
                ? (filters = {
                      pu: productionUnitId && productionUnitId,
                  })
                : (filters = {
                      pu: productionUnitId && productionUnitId,
                      hg: homogeneousGroupId && homogeneousGroupId,
                  })
            await fetchMore({
                variables: { page: 0, perPage: rowsPerPage, filters: filters },
            }).then((result) => {
                setRows(result?.data?.workers?.workers)
                setOriginalRows(result?.data?.workers?.workers)
                setRowsCount(result?.data?.workers?.totalCount)
            })
        } else {
            if (!productionUnitId) notifyFailure(t('seleziona_PU'))
            else notifyFailure(t('seleziona_HG'))
        }
    }

    const resetFilters = async () => {
        setSelectedPuId(false)
        setSelectedHgId(false)
        await fetchMore({ variables: { page: 0, perPage: rowsPerPage } }).then(
            (result) => {
                setRows(result?.data?.workers?.workers)
                setOriginalRows(result?.data?.workers?.workers)
                setRowsCount(result?.data?.workers?.totalCount)
            }
        )
    }

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    React.useEffect(() => {
        setWindowHeight(window.innerHeight)
    }, [])

    // ------- Window Height change listener -------
    window.addEventListener('resize', (event) => {
        const newHeight = window.innerHeight
        setWindowHeight(newHeight)
    })

    return (
        <Box className={classes.root}>
            <Paper className={classes.paper}>
                {/*<EnhancedTableToolbar userSelected={selected} handleWorkerFormOpen={handleWorkerFormOpen} />*/}
                <FiltersBar
                    hgID={selectedHgId}
                    unitID={selectedPuId}
                    setSelectedPuId={setSelectedPuId}
                    setSelectedHgId={setSelectedHgId}
                    applyFilters={() => {
                        applyFilters(selectedPuId, selectedHgId)
                    }}
                    resetFilters={resetFilters}
                />
                <TableContainer style={{ maxHeight: windowHeight - 280 }}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={1}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.length > 0 ? (
                                stableSort(
                                    rows,
                                    getComparator(order, orderBy)
                                ).map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                            onClick={() => setSelected(row.id)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Grid
                                                    container
                                                    spacing={4}
                                                    style={{
                                                        marginRight: '15px',
                                                    }}
                                                >
                                                    <Grid item sm={4}>
                                                        <IconButton
                                                            onClick={() =>
                                                                deleteWorker(
                                                                    row.id
                                                                )
                                                            }
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <IconButton
                                                            color={'primary'}
                                                            onClick={(e) => {
                                                                setIsWorkerFormOpen(
                                                                    true
                                                                )
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.nome}
                                            </TableCell>
                                            <TableCell>{row.cognome}</TableCell>
                                            <TableCell>
                                                {row.nMatricola}
                                            </TableCell>
                                            <TableCell>
                                                {row.responsabile?.nome}
                                            </TableCell>
                                            <TableCell>
                                                {row.statoSalute}
                                            </TableCell>
                                            <TableCell>
                                                {row.vaccinato ? (
                                                    <CheckIcon />
                                                ) : (
                                                    <CloseIcon />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.turno?.nome}
                                            </TableCell>
                                            <TableCell>
                                                {row.gruppoOmogeneo?.name}
                                            </TableCell>
                                            <TableCell>{row?.note}</TableCell>
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={9}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Typography
                                            variant={'body1'}
                                            color={'textSecondary'}
                                        >
                                            {t('no_worker_found')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    labelRowsPerPage={t('per_pagina')}
                    count={rowsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Fab
                className={classes.fab}
                color="primary"
                aria-label="add"
                onClick={() => setIsInsertFormOpen(true)}
            >
                <AddIcon />
            </Fab>
            <CustomDialog
                open={isWorkerFormOpen}
                aria-labelledby="simple-dialog-title"
            >
                <CloseButton
                    title={t('informazioni_lavoratore')}
                    onClose={() => setIsWorkerFormOpen(false)}
                />
                <ProductionUnitWorkerForm
                    workerData={getWorkerFromId(selected)}
                    afterSubmit={() => {
                        setIsWorkerFormOpen(false)
                        if (!selectedPuId) {
                            resetFilters()
                        } else {
                            applyFilters(selectedPuId, selectedHgId)
                        }
                        // refetch().then((data) =>
                        //     setRows(data?.data?.workers?.workers)
                        // )
                    }}
                    full={full}
                    setFull={setFull}
                    onClose={() => setIsWorkerFormOpen(false)}
                    unitID={unitID && unitID}
                    hgID={hgId && hgId}
                />
            </CustomDialog>
            <CustomDialog
                open={isInsertFormOpen}
                aria-labelledby="simple-dialog-title"
            >
                <CloseButton
                    title={t('crea_lavoratore')}
                    onClose={() => setIsInsertFormOpen(false)}
                />
                <ProductionUnitWorkerForm
                    afterSubmit={() => {
                        setIsInsertFormOpen(false)
                        setRowsCount((prevState) => prevState + 1)
                        setPage(parseInt(rowsCount / rowsPerPage))
                        if (!selectedPuId) {
                            resetFilters()
                        } else {
                            applyFilters(selectedPuId, selectedHgId)
                        }
                    }}
                    full={full}
                    setFull={setFull}
                    onClose={() => setIsInsertFormOpen(false)}
                    unitID={unitID && unitID}
                    hgID={hgId && hgId}
                />
            </CustomDialog>
        </Box>
    )
}

export default WorkerScreenTest
