import React from 'react'
import { Container } from '@material-ui/core'
import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export default function PageContainer({ children }) {
    const theme = useTheme()

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Container
                style={{
                    width: '100%',
                    padding: '0 10px',
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(4),
                    marginLeft: 0,
                    marginRight: 0,
                    position: 'relative',
                }}
            >
                {children}
            </Container>
        </Box>
    )
}
