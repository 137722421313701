import React from 'react'
import { Box, Card, IconButton, TableCell, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { FiEdit } from 'react-icons/fi'
import _ from 'lodash'

import { inactiveColors } from '../../colors'
import CustomScenarioDialog from './CustomScenarioCellDialog'

const useStyles = makeStyles(() => ({
    tableStyle: {
        border: '1px solid black',
        position: 'relative',
        padding: 0,
        background: ({ inactiveColor }) =>
            `repeating-linear-gradient(56deg,#FAFAfA,#FAFAFA 20px,${inactiveColor} 20px,${inactiveColor} 40px)`,
    },
    activeTableStyle: {
        backgroundColor: ({ activeColor }) => activeColor,
        border: '2px solid black',
        position: 'relative',
        padding: 0,
    },
    link: {
        color: 'black',
        '&:hover': {
            color: 'blue',
        },
    },
}))

export default function CustomizedTableCell({
    yIndex,
    xIndex,
    tables,
    scenario,
    color,
    tableContent,
    setTableContent,
    submitPackage,
}) {
    const props = {
        inactiveColor: inactiveColors[xIndex][yIndex],
        activeColor: color,
    }

    const styles = useStyles(props)

    const [isComputedScenario, setIsComputedScenario] = React.useState(false)
    const [cellContentList, setCellContentList] = React.useState([])
    const [itemIndex, setItemIndex] = React.useState(null)

    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const handleOpenDialog = () => setIsDialogOpen(true)
    const handleCloseDialog = () => setIsDialogOpen(false)

    // Check whether current cell is the current scenario
    React.useEffect(() => {
        if (scenario.row === yIndex && scenario.col === xIndex)
            setIsComputedScenario(true)
    }, [scenario, xIndex, yIndex])

    // Get Values for current cell if existing
    React.useEffect(() => {
        tables.forEach((cell, indx) => {
            if (cell.row === yIndex && cell.col === xIndex) {
                setCellContentList(cell.values)
                setItemIndex(indx)
            }
        })
    }, [tables, xIndex, yIndex])

    const addCellToPackage = (newLocalCellContentList) => {
        const newItem = {
            row: yIndex,
            col: xIndex,
            values: newLocalCellContentList,
        }

        let newTableData = _.cloneDeep(tables)

        if (cellContentList.length === 0) {
            newTableData.push(newItem)
        } else {
            if (itemIndex !== null) {
                newTableData.splice(itemIndex, 1)
                if (newItem.values.length !== 0) {
                    newTableData.push(newItem)
                }
            } else {
                if (newItem.values.length !== 0) {
                    newTableData.push(newItem)
                }
            }
        }

        newTableData.sort((a, b) => {
            var rowA = a.row
            var rowB = b.row
            var colA = a.col
            var colB = b.col

            if (rowA < rowB) return -1
            if (rowA > rowB) return 1

            if (rowA === rowB) {
                if (colA < colB) return -1
                if (colA > colB) return 1
            }

            return 0
        })

        let newTable = _.cloneDeep(tableContent)
        newTable.tables = newTableData

        setTableContent(newTable)
        submitPackage(newTable)
    }

    return (
        <TableCell
            style={{ transition: '0.1s' }}
            classes={{
                body: isComputedScenario
                    ? styles.activeTableStyle
                    : styles.tableStyle,
            }}
        >
            <Box
                width="100%"
                position="absolute"
                top={0}
                right={0}
                display="flex"
                flexDirection="row-reverse"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Box color="black">
                    <IconButton
                        style={{ padding: '5px', fontSize: '20px' }}
                        onClick={handleOpenDialog}
                    >
                        <FiEdit />
                    </IconButton>
                </Box>
            </Box>

            <Box height="180px" width="240px" p="27px 15px 15px">
                <Box
                    pt="5px"
                    width="100%"
                    height="100%"
                    overflow="scroll"
                    className="scenario-scrollbar masked-overflow"
                    position="relative"
                >
                    {cellContentList.map((item, idx) => {
                        return item.type === 'text' ? (
                            <Box
                                key={idx}
                                component="p"
                                style={idx === 0 ? { marginTop: '0' } : {}}
                            >
                                <Card
                                    elevation="2"
                                    style={{
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: 6,
                                        padding: '8px 4px',
                                    }}
                                >
                                    <Typography align="center">
                                        {item.value}
                                    </Typography>
                                </Card>
                            </Box>
                        ) : (
                            item.type === 'link' && (
                                <Box
                                    key={idx}
                                    component="p"
                                    style={idx === 0 ? { marginTop: '0' } : {}}
                                >
                                    <Card
                                        style={{
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 6,
                                            padding: '8px 4px',
                                        }}
                                    >
                                        <Box
                                            className={styles.link}
                                            component="a"
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Typography align="center">
                                                <Box>{item.title}</Box>
                                            </Typography>
                                        </Box>
                                    </Card>
                                </Box>
                            )
                        )
                    })}
                </Box>
            </Box>
            <CustomScenarioDialog
                isOpen={isDialogOpen}
                handleClose={handleCloseDialog}
                cellContentList={cellContentList}
                setCellContentList={setCellContentList}
                addCellToPackage={addCellToPackage}
                submitPackage={submitPackage}
            />
        </TableCell>
    )
}
