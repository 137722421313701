export const colorsList = [
    ['#77C23B', '#80AC55', '#80AC55', '#F9E8A3', '#F1C246'],
    ['#80AC55', '#80AC55', '#F9E8A3', '#F9E8A3', '#F1C246'],
    ['#80AC55', '#F9E8A3', '#F9E8A3', '#F1C246', '#D33C30'],
    ['#F9E8A3', '#F9E8A3', '#F1C246', '#D33C30', '#D33C30'],
    ['#F1C246', '#F1C246', '#D33C30', '#D33C30', '#D33C30'],
]

export const headerColorPalette = [
    'rgba(91,197,4,0.75)',
    'rgba(115,174,71,0.75)',
    'rgba(253,231,153,0.75)',
    'rgba(250,192,21,0.75)',
    'rgba(230,38,34,0.75)',
]

export const inactiveColors = [
    [
        'rgba(91,197,4,0.1)',
        'rgba(115,174,71,0.1)',
        'rgba(115,174,71,0.1)',
        'rgba(253,231,153,0.24)',
        'rgba(250,192,21,0.16)',
    ],
    [
        'rgba(115,174,71,0.1)',
        'rgba(115,174,71,0.1)',
        'rgba(253,231,153,0.24)',
        'rgba(253,231,153,0.24)',
        'rgba(250,192,21,0.16)',
    ],
    [
        'rgba(115,174,71,0.1)',
        'rgba(253,231,153,0.24)',
        'rgba(253,231,153,0.24)',
        'rgba(250,192,21,0.16)',
        'rgba(230,38,34,0.1)',
    ],
    [
        'rgba(253,231,153,0.24)',
        'rgba(253,231,153,0.24)',
        'rgba(250,192,21,0.16)',
        'rgba(230,38,34,0.1)',
        'rgba(230,38,34,0.1)',
    ],
    [
        'rgba(250,192,21,0.16)',
        'rgba(250,192,21,0.16)',
        'rgba(230,38,34,0.1)',
        'rgba(230,38,34,0.1)',
        'rgba(230,38,34,0.1)',
    ],
]
