import React from 'react'
import {
    Box,
    Button,
    MenuItem,
    TextField,
    InputLabel,
    FormControl,
    Select,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useLazyQuery } from '@apollo/client'

import {
    COPY_HOMOGENEOUS_GROUP_ACTIVITIES,
    COPY_SHIFTS,
    HOMOGENEOUS_GROUP_DETAILS_QUERY,
} from './GraphQL_queries'
import Divider from '@material-ui/core/Divider'
import { notifyFailure, notifySuccess } from '../../utils'
import { useConfirmDialog } from '../../globalState'
import { useTranslation } from 'react-i18next'

const OutlinedTextField = (props) => (
    <TextField
        variant={'outlined'}
        {...props}
        inputProps={{
            min: '0',
            max: `${props.max}`,
        }}
    />
)

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    formField: {
        margin: theme.spacing(1),
    },
    formSelectField: {
        minWidth: 140,
    },
    menuItem: {
        '&:hover': {
            backgroundColor: '#dedede',
        },
    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
    buttonsBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    errorMessage: {
        color: 'red',
        margin: theme.spacing(1),
    },
}))

const OutlinedSelect = ({ label, passClassName, ...props }) => (
    // margin='dense'
    <FormControl
        fullWidth={true}
        variant={'outlined'}
        className={passClassName}
    >
        <InputLabel id={`${props.name}-label`}>{label}</InputLabel>
        <Select label={label} labelId={`${props.name}-label`} {...props} />
    </FormControl>
)

export default function HomogeneousGroupEditForm({
    unitData,
    onSubmit,
    homogeneousGroups,
    ...rest
}) {
    const { register, control, handleSubmit, errors, formState } = useForm()
    const classes = useStyle(rest)
    // eslint-disable-next-line no-unused-vars
    const [hgToCopy, setHgToCopy] = React.useState('none')
    const { t } = useTranslation()
    const { isDirty, isSubmitting } = formState
    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])
    const [copyShifts] = useMutation(COPY_SHIFTS)
    const [copyHGActivities] = useMutation(COPY_HOMOGENEOUS_GROUP_ACTIVITIES)
    const [getHGDetails] = useLazyQuery(HOMOGENEOUS_GROUP_DETAILS_QUERY, {
        onCompleted: (data) => {
            copyHGActivities({
                variables: {
                    groupId: unitData.id,
                    riskObject: data.homogeneousGroups[0].risk,
                },
            })
                .then(() => notifySuccess())
                .catch(() => notifyFailure())
        },
    })

    function submit(values, event) {
        if (hgToCopy !== 'none') {
            getHGDetails({
                variables: {
                    idList: [hgToCopy],
                },
            })
        }
        onSubmit({ ...unitData, ...values })
    }

    // Check that inserted value is not greater then the max capcity
    const checkEmployeesEdit = () => {
        return (
            unitData === null &&
            rest.employeesCount - rest.insertedEmployees === 0
        )
    }

    const [isValid, setIsValid] = React.useState(true)
    const [isMissingContent, setIsMissingContent] = React.useState(false)
    const invalidCount = React.useRef(0)
    const missingCount = React.useRef(0)
    const [missingHistory, setMissingHistory] = React.useState({
        name: false,
    })

    const checkEmptiness = (value) => {
        if (value === '') {
            invalidCount.current += 1
        } else {
            if (invalidCount.current > 0) invalidCount.current -= 1
        }

        if (invalidCount.current !== 0) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }

    const checkMissingContent = (value, name) => {
        if (
            value.replace(/\s/g, '').length === 0 &&
            value.length === 1 &&
            !missingHistory[name]
        ) {
            missingCount.current += 1
            setMissingHistory({
                [name]: true,
            })
        } else if (value.length === 0) {
            if (missingCount.current > 0) {
                setMissingHistory({
                    [name]: false,
                })
                missingCount.current -= 1
            }
        }
        if (!value.startsWith(' ') && missingCount.current > 0) {
            setMissingHistory({
                [name]: false,
            })
            missingCount.current -= 1
        }

        if (missingCount.current !== 0) {
            setIsMissingContent(true)
        } else {
            setIsMissingContent(false)
        }
    }

    const validate = (value, name) => {
        checkEmptiness(value)
        checkMissingContent(value, name)
    }

    return (
        <Box>
            <form className={classes.form} onSubmit={handleSubmit(submit)}>
                <Box>
                    <Controller
                        control={control}
                        name={'name'}
                        rules={
                            unitData ? { required: false } : { required: true }
                        }
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                name={'name'}
                                label={t('Nome')}
                                // {...register('name', unitData ? { required: false } : { required: true })}
                                defaultValue={unitData?.name}
                                className={classes.formField}
                                value={value}
                                onChange={(e) => {
                                    validate(e.target.value, e.target.name)
                                    onChange(e.target.value)
                                }}
                                style={{ width: '150px' }}
                            />
                        )}
                    />
                    {errors?.name && <span>{t('campo_richiesto')}</span>}

                    <Controller
                        control={control}
                        name={'employeesCount'}
                        rules={
                            unitData ? { required: false } : { required: true }
                        }
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                error={checkEmployeesEdit()}
                                helperText={
                                    checkEmployeesEdit()
                                        ? 'Capienza massima raggiunta'
                                        : null
                                }
                                label={t('numerosita')}
                                // {...register('employeesCount', unitData ? { required: false } : { required: true })}
                                defaultValue={unitData?.employeesCount}
                                className={classes.formField}
                                style={{ width: '150px' }}
                                value={value}
                                onChange={(e) => {
                                    if (e.target.value < 0) {
                                        onChange(1)
                                    } else {
                                        onChange(e.target.value)
                                    }
                                }}
                                max={
                                    unitData === null
                                        ? rest.employeesCount -
                                          rest.insertedEmployees
                                        : unitData?.employeesCount +
                                          (rest.employeesCount -
                                              rest.insertedEmployees)
                                }
                                type={'number'}
                            />
                        )}
                    />
                    {errors?.employeesCount && (
                        <span>{t('campo_richiesto')}</span>
                    )}
                    <Box>
                        <OutlinedSelect
                            key={'criticalities'}
                            passClassName={classes.formSelectField}
                            className={classes.formField}
                            defaultValue={unitData?.criticalities}
                            {...register(
                                'criticalities',
                                unitData
                                    ? { required: false }
                                    : { required: true }
                            )}
                            label={t('criticita')}
                        >
                            <MenuItem
                                value={'none'}
                                className={classes.menuItem}
                            >
                                {t('crit_1')}
                            </MenuItem>
                            <MenuItem
                                value={'business'}
                                className={classes.menuItem}
                            >
                                {t('crit_2')}
                            </MenuItem>
                            <MenuItem
                                value={'service'}
                                className={classes.menuItem}
                            >
                                {t('crit_3')}
                            </MenuItem>
                            <MenuItem
                                value={'health'}
                                className={classes.menuItem}
                            >
                                {t('crit_4')}
                            </MenuItem>
                        </OutlinedSelect>
                    </Box>
                    <Box className={classes.buttonsBox}>
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                backgroundColor: 'rgba(42,42,42,0.81)',
                                color: 'white',
                            }}
                            variant={'contained'}
                            color={'secondary'}
                            size={'small'}
                            onClick={() => rest.afterSubmit()}
                            className={classes.button}
                        >
                            {t('chiudi')}
                        </Button>
                        <Button
                            style={{ alignSelf: 'flex-end' }}
                            variant={'contained'}
                            color={'primary'}
                            type="submit"
                            size={'small'}
                            disabled={
                                !isValid ||
                                isMissingContent ||
                                isSubmitting ||
                                !isDirty ||
                                (unitData === null &&
                                    rest.employeesCount -
                                        rest.insertedEmployees ===
                                        0)
                            }
                            className={classes.button}
                        >
                            {t('salva')}
                        </Button>
                    </Box>
                </Box>
            </form>
            <Divider />
            <Box className={classes.container}>
                <OutlinedSelect
                    passClassName={classes.formSelectField}
                    className={classes.formField}
                    label={t('importa_attivita_da')}
                    onChange={(e) => {
                        openConfirm({
                            title: t('importa_attivita'),
                            description: t('importa_attivita_descrizione'),
                            onConfirm: () => {
                                getHGDetails({
                                    variables: {
                                        idList: [e.target.value],
                                    },
                                })
                            },
                        })
                    }}
                    value={hgToCopy}
                    disabled={unitData === null}
                >
                    {homogeneousGroups &&
                        homogeneousGroups.length > 0 &&
                        homogeneousGroups
                            .filter((value) => value?.id !== unitData?.id)
                            .map((e, i) => (
                                <MenuItem
                                    key={i}
                                    value={e.id}
                                    className={classes.menuItem}
                                >
                                    {e?.name}
                                </MenuItem>
                            ))}
                    <MenuItem value={'none'} className={classes.menuItem}>
                        {t('nessuno')}
                    </MenuItem>
                </OutlinedSelect>
                <OutlinedSelect
                    passClassName={classes.formSelectField}
                    className={classes.formField}
                    label={t('importa_turni_da')}
                    onChange={(e) => {
                        openConfirm({
                            title: t('importa_turni'),
                            description: t('importa_turni_descrizione'),
                            onConfirm: () => {
                                copyShifts({
                                    variables: {
                                        fromGroupId: e.target.value,
                                        toGroupId: unitData?.id,
                                    },
                                })
                                    .then(() => {
                                        notifySuccess()
                                    })
                                    .catch(notifyFailure)
                            },
                        })
                    }}
                    value={hgToCopy}
                    disabled={unitData === null}
                >
                    {homogeneousGroups &&
                        homogeneousGroups.length > 0 &&
                        homogeneousGroups
                            .filter((value) => value?.id !== unitData?.id)
                            .map((e, i) => (
                                <MenuItem
                                    key={i}
                                    value={e.id}
                                    className={classes.menuItem}
                                >
                                    {e?.name}
                                </MenuItem>
                            ))}
                    <MenuItem value={'none'} className={classes.menuItem}>
                        {t('nessuno')}
                    </MenuItem>
                </OutlinedSelect>
                <div className={classes.marginLeft}>
                    {unitData === null && (
                        <Typography color={'textSecondary'} variant={'caption'}>
                            {t('null_unit_data_warning')}
                        </Typography>
                    )}
                </div>
            </Box>
        </Box>
    )
}
