import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { OutlinedTextField } from '../Customized/CustomComponents'
import { Button, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { INSERT_SHIFT, UPDATE_SHIFT } from './GraphQL_queries'
import { useMutation } from '@apollo/client'
import FieldsArrayInsert from './FieldsArrays'
import { notifyFailure, notifySuccess } from '../../utils'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    formField: {
        margin: theme.spacing(1),
    },
    formSelectField: {
        minWidth: 140,
    },
    menuItem: {
        '&:hover': {
            backgroundColor: '#dedede',
        },
    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        width: 500,
        margin: theme.spacing(1),
    },
    fieldBox: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 500,
    },
    formLongField: {
        margin: theme.spacing(1),
        flexGrow: 1,
        // minWidth: '20vw'
    },
    buttonsBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
}))

export default function ShiftCreateForm({
    onSubmit,
    groupId,
    shiftData,
    ...rest
}) {
    const classes = useStyle(rest)
    const { t } = useTranslation()
    const { register, getValues, handleSubmit, control, formState } = useForm({
        defaultValues: shiftData,
    })
    const { isSubmitting, isDirty } = formState
    // const [isSubmitting, setSubmitting] = React.useState(false)
    const [insertShift] = useMutation(INSERT_SHIFT)
    const [updateShift] = useMutation(UPDATE_SHIFT)
    const [isError, setIsError] = useState(false)

    const addShift = (shiftData) => {
        shiftData.homogeneousGroupId = groupId
        insertShift({
            variables: shiftData,
        })
            .then(() => {
                rest.afterSubmit(rest.onClose())
                notifySuccess()
            })
            .catch(notifyFailure)
    }

    const editShift = (data) => {
        data.homogeneousGroupId = groupId
        updateShift({
            variables: {
                shiftData: data,
            },
        })
            .then(() => {
                rest.afterSubmit(rest.onClose())
                notifySuccess()
            })
            .catch(notifyFailure)
    }

    return (
        <form
            className={classes.form}
            onSubmit={handleSubmit(shiftData ? editShift : addShift)}
        >
            <Box width={'100%'}>
                <Box>
                    {shiftData && (
                        <input
                            hidden={true}
                            {...register('id')}
                            value={shiftData && shiftData.id}
                        />
                    )}
                    <Controller
                        name={'nome'}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedTextField
                                label={t('nome_turno')}
                                key={'name'}
                                onChange={(e) => {
                                    onChange(e.target.value)
                                }}
                                value={value}
                                defaultValue={shiftData && shiftData.nome}
                                className={classes.formField}
                            />
                        )}
                    />
                </Box>
                <Grid container spacing={2}>
                    {[
                        'lunedi',
                        'martedi',
                        'mercoledi',
                        'giovedi',
                        'venerdi',
                        'sabato',
                        'domenica',
                    ].map((item, index) => (
                        <Grid item sm={4} md={4}>
                            <Paper
                                className={classes.button}
                                elevation={0}
                                variant={'outlined'}
                            >
                                <Typography
                                    className={classes.button}
                                    variant={'caption'}
                                >
                                    {t(item)}
                                </Typography>
                                <FieldsArrayInsert
                                    control={control}
                                    register={register}
                                    day={item}
                                    getValues={getValues}
                                    isError={isError}
                                    setIsError={setIsError}
                                    isEdit={shiftData ? shiftData : null}
                                />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                <Box className={classes.buttonsBox}>
                    <Button
                        variant="contained"
                        size={'small'}
                        style={{
                            backgroundColor: 'rgba(42,42,42,0.81)',
                            color: 'white',
                        }}
                        className={classes.button}
                        onClick={() => {
                            rest.onClose()
                        }}
                    >
                        {t('chiudi')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size={'small'}
                        type={'submit'}
                        disabled={!(isSubmitting || isDirty) || isError}
                        className={classes.button}
                    >
                        {t('salva')}
                    </Button>
                </Box>
            </Box>
        </form>
    )
}
