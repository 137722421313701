/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
    Fab,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
} from '@material-ui/core'
import {
    GET_ALL_HOMOGENEOUS_GROUPS,
    REFRESH_STATS,
    ALL_PRODUCTION_UNITS,
} from '../Components/ProductionUnit/GraphQL_queries'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { CustomDialog } from '../Components/Customized'
import AddIcon from '@material-ui/icons/Add'
import { notifyFailure, notifySuccess } from '../utils'
import { useConfirmDialog } from '../globalState'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import {
    DELETE_EQUIPMENT,
    GET_ALL_EQUIPMENT,
} from '../Components/HomogeneousGroup/GraphQL_queries'
import EquipmentForm from '../Components/Equipment/EquipmentForm'
import ImportContactsTwoToneIcon from '@material-ui/icons/ImportContactsTwoTone'
import _ from 'lodash'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import CloseButton from '../Components/CloseButton'
import FiltersBar from '../Components/FiltersBar'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        // width: '100%',
        margin: theme.spacing(2),
        // padding: theme.spacing(2)
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    fab: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        margin: '20px',
        '&.MuiDivider-root': {
            background: '#6495ED',
        },
    },
    outlinedSelect: {
        // margin: theme.spacing(1),
        margin: theme.spacing(2),
        width: '400px',
    },
    button: {
        margin: theme.spacing(2),
    },
}))

function EnhancedTableHead(props) {
    const { t } = useTranslation()
    const { classes, order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    const headCells = [
        { id: 'Nome', numeric: false, disablePadding: true, label: t('Nome') },
        {
            id: 'categoria',
            numeric: false,
            disablePadding: false,
            label: t('categoria'),
        },
        {
            id: 'Assegnata a',
            numeric: false,
            disablePadding: false,
            label: t('assegnato_a'),
        },
        {
            id: 'Ultima assegnazione',
            numeric: false,
            disablePadding: false,
            label: t('ultima_assegnazione'),
        },
        {
            id: 'note',
            numeric: false,
            disablePadding: false,
            label: t('note'),
        },
        {
            id: 'Storico utilizzi',
            numeric: false,
            disablePadding: false,
            label: t('storico_utilizzi'),
        },
    ]

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? t('discendente')
                                        : t('ascendente')}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight: {
        color: theme.palette.text.primary,
        backgroundColor: lighten('#3f51b5', 0.9),
    },
    title: {
        flex: '1 1 100%',
    },
}))

// eslint-disable-next-line no-unused-vars
const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles()
    const { userSelected } = props
    // NUMERO MATRICOLA USATO

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: userSelected !== null,
            })}
        >
            {/*{userSelected !== null ? (*/}
            {/*<Tooltip title='Edit worker'>*/}
            {/*    <IconButton aria-label='filter list'>*/}
            {/*        <FilterListIcon />*/}
            {/*    </IconButton>*/}
            {/*</Tooltip>*/}
            {/*) : (*/}
            {/*    <Tooltip title='Filter list'>*/}
            {/*        <IconButton aria-label='filter list'>*/}
            {/*            <FilterListIcon />*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*)}*/}
        </Toolbar>
    )
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const ShowDetailsButton = ({ handleShowDialog }) => {
    const { t } = useTranslation()

    return (
        <IconButton
            onClick={() => handleShowDialog()}
            style={{ borderRadius: '0%' }}
        >
            <ImportContactsTwoToneIcon />
            <Typography
                style={{ margin: '5px' }}
                color={'textSecondary'}
                variant={'caption'}
            >
                {t('mostra_dettagli')}
            </Typography>
        </IconButton>
    )
}

const getFormattedDate = (dateStr) => {
    const newDate = new Date(dateStr)
    return `${newDate.toLocaleString('it-IT', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })}`
}

const ListHistory = ({ history, currentOwner }) => {
    const [historyList, setHistoryList] = useState(history)
    const [orderInc, setOrderInc] = useState(true)

    useEffect(() => {
        let cloned = _.cloneDeep(historyList)
        cloned.sort((a, b) => {
            return new Date(a.startTime) - new Date(b.startTime)
        })
        setHistoryList(cloned)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeOrder = () => {
        let cloned = _.cloneDeep(historyList)
        setHistoryList(_.reverse(cloned))
        setOrderInc(!orderInc)
    }

    const { t } = useTranslation()

    return (
        <Box>
            {historyList.length > 0 && (
                <Box
                    style={{
                        marginLeft: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant={'body1'}
                        color={'textPrimary'}
                        style={{ marginLeft: '8px' }}
                    >
                        {t('attualmente_utilizzato_da')}
                        {currentOwner ?
                        `${currentOwner?.nome} ${currentOwner?.cognome} - (mat. ${currentOwner?.nMatricola})` : "Nessuno"}
                    </Typography>
                    <Typography
                        variant={'body1'}
                        style={{ marginLeft: '8px' }}
                        color={'textSecondary'}
                    >
                        {t('ordina_per')}
                        <IconButton
                            onClick={() => changeOrder()}
                            size={'small'}
                        >
                            {orderInc ? (
                                <ArrowDownwardIcon />
                            ) : (
                                <ArrowUpwardIcon />
                            )}
                        </IconButton>
                    </Typography>
                </Box>
            )}
            <List style={{ margin: '8px', paddingTop: '0px' }}>
                {historyList?.length > 0 ? (
                    historyList.map((item, index) => (
                        <ListItem>
                            {/*'Jan 9, 2014'*/}
                            <ListItemText
                                primary={`${item?.firstName} ${item?.lastName} - (${item?.nMatricola})`}
                                secondary={
                                    getFormattedDate(item?.startTime) +
                                    ' / ' +
                                    getFormattedDate(item?.endTime)
                                }
                            />
                        </ListItem>
                    ))
                ) : (
                    <ListItem>
                        <Typography variant={'body1'} color={'textSecondary'}>
                            {t('no_worker_history')}
                        </Typography>
                    </ListItem>
                )}
            </List>
        </Box>
    )
}

const EquipmentScreen = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { unitID } = useParams()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState(null)
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(false)
    const [rows, setRows] = React.useState([])
    const [originalRows, setOriginalRows] = useState([])
    const [rowsCount, setRowsCount] = React.useState(0)
    const [showDetailsDialog, setShowDetailsDialog] = useState(false)
    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const [selectedItem, setSelectedItem] = useState('{}')
    const [isEquipmentFormOpen, setIsEquipmentFormOpen] = React.useState(false)
    const [isEquipmentEditFormOpen, setIsEquipmentEditFormOpen] =
        useState(false)
    const [showFilterDialog, setShowFilterDialog] = useState(false)
    const [removeEquipment] = useMutation(DELETE_EQUIPMENT)
    const [alls, setAlls] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [hgSwitch, setHgSwitch] = useState(false)
    const [puSwitch, setPuSwitch] = useState(false)

    const [selectedPuId, setSelectedPuId] = useState(false)
    const [selectedHgId, setSelectedHgId] = useState(false)

    const [getAllHG] = useLazyQuery(GET_ALL_HOMOGENEOUS_GROUPS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAlls(res.homogeneousGroups)
        },
    })
    const [getAllPU] = useLazyQuery(ALL_PRODUCTION_UNITS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setAlls(res.productionUnits)
        },
    })

    // eslint-disable-next-line no-unused-vars
    const [filterBy, setFilterBy] = useState({ HG: null, PU: null })
    // eslint-disable-next-line no-unused-vars
    const [refreshStats] = useLazyQuery(REFRESH_STATS, {
        fetchPolicy: 'network-only',
    })
    const { fetchMore, refetch } = useQuery(GET_ALL_EQUIPMENT, {
        variables: { perPage: 25, page: page },
        fetchPolicy: 'network-only',
        onCompleted: (result) => {
            setRows(result?.equipments?.equipments)
            setOriginalRows(result?.equipments?.equipments)
            setRowsCount(result?.equipments?.totalCount)
        },
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    async function deleteWorker(id) {
        if (id) {
            openConfirm({
                title: t('elimina_attrezzatura'),
                description: t('elimina_attrezzatura_descrizione'),
                onConfirm: () =>
                    removeEquipment({
                        variables: { equipmentId: id },
                    }).then(() => {
                        setRowsCount((prevState) => prevState - 1)
                        refetch()
                            .then((data) => {
                                setRows(data?.data?.equipments?.equipments)
                                notifySuccess()
                            })
                            .catch(notifyFailure)
                    }),
            })
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        fetchMore({
            variables: {
                perPage: rowsPerPage,
                page: newPage,
            },
        }).then((result) => {
            setRows(result?.data?.equipments?.equipments)
            setOriginalRows(result?.data?.equipments?.equipments)
        })
    }

    const handleChangeRowsPerPage = (event) => {
        // setLoading(true)
        setRowsPerPage(parseInt(event.target.value, 10))
        fetchMore({
            variables: {
                perPage: parseInt(event.target.value, 10),
                page: 0,
            },
        }).then((result) => {
            setRows(result?.data?.equipments?.equipments)
            setOriginalRows(result?.data?.equipments?.equipments)
        })
        setPage(0)
    }

    const getEquipmentFromId = (equipmentId) => {
        return rows?.filter((eq) => eq.id === equipmentId)[0]
    }

    const resetFilters = async () => {
        setSelectedPuId(false)
        setSelectedHgId(false)
        await fetchMore({ variables: { page: 0, perPage: rowsPerPage } }).then(
            (result) => {
                setRows(result?.data?.equipments?.equipments)
                setRowsCount(result?.data?.equipments?.totalCount)
            }
        )
    }

    const applyFilters = async (productionUnitId, homogeneousGroupId) => {
        let filters = {}
        if (productionUnitId && homogeneousGroupId) {
            homogeneousGroupId === 'Tutti'
                ? (filters = {
                      pu: productionUnitId && productionUnitId,
                  })
                : (filters = {
                      pu: productionUnitId && productionUnitId,
                      hg: homogeneousGroupId && homogeneousGroupId,
                  })
            await fetchMore({
                variables: { page: 0, perPage: rowsPerPage, filters: filters },
            }).then((result) => {
                setRows(result?.data?.equipments?.equipments)
                setRowsCount(result?.data?.equipments?.totalCount)
            })
        } else {
            notifyFailure(t('invalid_filter_warning'))
        }
    }

    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    React.useEffect(() => {
        setWindowHeight(window.innerHeight)
    }, [])

    // ------- Window Height change listener -------
    window.addEventListener('resize', (event) => {
        const newHeight = window.innerHeight
        setWindowHeight(newHeight)
    })

    return (
        <Box className={classes.root}>
            <Paper className={classes.paper}>
                {/*<EnhancedTableToolbar userSelected={selected} handleWorkerFormOpen={handleWorkerFormOpen} />*/}
                <FiltersBar
                    hgID={selectedHgId}
                    unitID={unitID ? unitID : selectedPuId}
                    setSelectedPuId={setSelectedPuId}
                    setSelectedHgId={setSelectedHgId}
                    applyFilters={() =>
                        applyFilters(selectedPuId, selectedHgId)
                    }
                    resetFilters={resetFilters}
                />
                <TableContainer style={{ maxHeight: windowHeight - 280 }}>
                    <Table
                        stickyHeader
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={1}
                            order={order}
                            orderBy={orderBy}
                            // applyFilters={applyFilters}
                            // resetFilters={resetFilters}
                            onRequestSort={handleRequestSort}
                            // unitID={unitID}
                            rowCount={rows.length}
                            setHandleAnchorElement={setAnchorEl}
                            setShowFilterDialog={setShowFilterDialog}
                        />
                        <TableBody>
                            {rows.length > 0 ? (
                                stableSort(
                                    rows,
                                    getComparator(order, orderBy)
                                ).map((row, index) => {
                                    // const isItemSelected = isSelected(row.id)
                                    const labelId = `enhanced-table-checkbox-${index}`

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                            onClick={() => setSelected(row.id)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Grid
                                                    container
                                                    spacing={4}
                                                    style={{
                                                        marginRight: '15px',
                                                    }}
                                                >
                                                    <Grid item sm={4}>
                                                        <IconButton
                                                            onClick={() =>
                                                                deleteWorker(
                                                                    row.id
                                                                )
                                                            }
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <IconButton
                                                            color={'primary'}
                                                            onClick={(e) => {
                                                                setIsEquipmentEditFormOpen(
                                                                    true
                                                                )
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.asset.asset}
                                            </TableCell>
                                            <TableCell>
                                                {row.asset.category}
                                            </TableCell>
                                            <TableCell>
                                                {row?.currentOwner?.nome}
                                            </TableCell>
                                            <TableCell>
                                                {row?.lastAssigned}
                                            </TableCell>
                                            <TableCell>{row?.note}</TableCell>
                                            <TableCell>
                                                <ShowDetailsButton
                                                    handleShowDialog={() => {
                                                        setSelectedItem({
                                                            history:
                                                                row.history,
                                                            currentOwner:
                                                                row.currentOwner,
                                                        })
                                                        setShowDetailsDialog(
                                                            true
                                                        )
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={9}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Typography
                                            variant={'body1'}
                                            color={'textSecondary'}
                                        >
                                            Nessuna attrezzatura trovata
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={rowsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Fab
                className={classes.fab}
                color="primary"
                aria-label="add"
                onClick={() => setIsEquipmentFormOpen(true)}
            >
                <AddIcon />
            </Fab>
            <CustomDialog
                onClose={() => setIsEquipmentEditFormOpen(false)}
                open={isEquipmentEditFormOpen}
                aria-labelledby="simple-dialog-title"
            >
                <CloseButton
                    title={t('modifica_equipaggiamento')}
                    onClose={() => setIsEquipmentEditFormOpen(false)}
                />
                <EquipmentForm
                    equipmentData={getEquipmentFromId(selected)}
                    afterSubmit={() => {
                        setIsEquipmentEditFormOpen(false)
                        if (!selectedPuId) {
                            resetFilters()
                        } else {
                            applyFilters(selectedPuId, selectedHgId)
                        }
                    }}
                    onClose={() => setIsEquipmentEditFormOpen(false)}
                />
            </CustomDialog>
            <CustomDialog
                open={isEquipmentFormOpen}
                aria-labelledby="simple-dialog-title"
            >
                <CloseButton
                    title={t('crea_equipaggiamento')}
                    onClose={() => setIsEquipmentFormOpen(false)}
                />
                <EquipmentForm
                    onClose={() => setIsEquipmentFormOpen(false)}
                    afterSubmit={() => {
                        setIsEquipmentFormOpen(false)
                        if (!selectedPuId) {
                            resetFilters()
                        } else {
                            applyFilters(selectedPuId, selectedHgId)
                        }
                    }}
                />
            </CustomDialog>
            <CustomDialog open={showDetailsDialog} fullWidth={true}>
                <CloseButton
                    title={t('storico_utilizzo_strumento')}
                    onClose={() => setShowDetailsDialog(false)}
                />
                <ListHistory
                    history={selectedItem.history}
                    currentOwner={selectedItem.currentOwner}
                />
            </CustomDialog>
        </Box>
    )
}

export default EquipmentScreen
