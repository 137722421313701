import { gql } from '@apollo/client'

export const PRODUCTION_UNITS_QUERY = gql`
    query getProductionUnits($unitId: ID) {
        productionUnits(id: $unitId) {
            industryCode
            name
            id
            address
            province
            category
            k1
            k3
            risk {
                initial
                raw
                final
                initialClass
                finalRiskPercentageComposition
                finalClass
                finalPercentage
                initialPercentage
            }
            employeesCount
            homogeneousGroups {
                id
                name
                risk {
                    totalRisk
                    mitigatedRisk
                }
                criticalities
                employeesCount
                workers {
                    id
                    nome
                }
            }
            mitigation {
                mitigationActivities {
                    name
                    group
                    practicalEfficacy
                    practicalExecution
                    theoreticalMax
                    theoreticalMin
                    k
                }
                totalMitigation
            }
            workerStats {
                sani
                infetti
                quarantena
                vaccinati
                timestamp
            }
            restrainingBlock
        }
    }
`

export const GET_LAST_WOKRER_STATS = gql`
    query getWorkerStats($productionUnitId: ID!) {
        workerStats(productionUnitId: $productionUnitId) {
            sani
            infetti
            quarantena
            vaccinati
            timestamp
        }
    }
`

export const INSERT_PRODUCTION_UNIT = gql`
    mutation insertProductionUnit(
        $name: String
        $industryCode: String
        $employeesCount: Int
        $address: String
        $province: String
        $restrainingBlock: Boolean
    ) {
        insertProductionUnit(
            productionUnitData: {
                name: $name
                industryCode: $industryCode
                employeesCount: $employeesCount
                address: $address
                province: $province
                restrainingBlock: $restrainingBlock
            }
        ) {
            productionUnit {
                id
                name
                address
                province
                category
                industryCode
                employeesCount
                homogeneousGroups {
                    id
                    employeesCount
                }
                restrainingBlock
            }
        }
    }
`

export const ALL_PRODUCTION_UNITS = gql`
    {
        productionUnits {
            id
            name
        }
    }
`

export const UPDATE_PRODUCTION_UNIT = gql`
    mutation updateProductionUnit(
        $id: ID
        $name: String
        $industryCode: String
        $employeesCount: Int
        $address: String
        $province: String
        $restrainingBlock: Boolean
    ) {
        updateProductionUnit(
            productionUnitData: {
                id: $id
                name: $name
                industryCode: $industryCode
                employeesCount: $employeesCount
                address: $address
                province: $province
                restrainingBlock: $restrainingBlock
            }
        ) {
            productionUnit {
                id
                name
                address
                province
                category
                industryCode
                employeesCount
                homogeneousGroups {
                    id
                    employeesCount
                }
                risk {
                    initial
                    raw
                    final
                    initialClass
                    finalClass
                    finalPercentage
                    initialPercentage
                }
                restrainingBlock
            }
        }
    }
`

export const UPDATE_PRODUCTION_UNIT_MITIGATION_ACTIVITIES = gql`
    mutation editProductionUnitMitigationActivities(
        $productionUnitId: ID!
        $mitigationActivitiesData: [MitigationActivityFieldInput]!
    ) {
        editProductionUnitMitigationActivities(
            productionUnitId: $productionUnitId
            mitigationActivitiesData: $mitigationActivitiesData
        ) {
            productionUnit {
                id
                mitigation {
                    mitigationActivities {
                        group
                        name
                        practicalEfficacy
                        practicalExecution
                        theoreticalMax
                        theoreticalMin
                        k
                    }
                    totalMitigation
                }
                homogeneousGroups {
                    id
                    name
                    risk {
                        totalRisk
                        mitigatedRisk
                    }
                    criticalities
                    employeesCount
                }
                risk {
                    initial
                    raw
                    final
                    initialClass
                    finalClass
                    finalPercentage
                    initialPercentage
                }
            }
        }
    }
`

export const DELETE_PRODUCTION_UNIT = gql`
    mutation deleteProductionUnit($id: ID) {
        deleteProductionUnit(productionUnitData: { id: $id }) {
            deletedId
        }
    }
`

export const RT_QUERY = gql`
    {
        getLastRt {
            data
        }
    }
`

export const ADD_WORKER_QUERY = gql`
    mutation insertWorker(
        $nome: String
        $cognome: String
        $nMatricola: Int
        $vaccinato: Boolean
        $responsabile: ID
        $turno: ID
        $statoSalute: StatoSaluteLavoratoreEnum
        $gruppoOmogeneo: ID
        $note: String
    ) {
        insertWorker(
            workerData: {
                nome: $nome
                cognome: $cognome
                nMatricola: $nMatricola
                vaccinato: $vaccinato
                responsabile: $responsabile
                turno: $turno
                statoSalute: $statoSalute
                note: $note
            }
            gruppoOmogeneoId: $gruppoOmogeneo
        ) {
            worker {
                nome
                nMatricola
                statoSalute
            }
        }
    }
`
export const UPDATE_WORKER = gql`
    mutation updateWorker($workerData: WorkerInput, $gruppoOmogeneoId: ID) {
        updateWorker(
            workerData: $workerData
            gruppoOmogeneoId: $gruppoOmogeneoId
        ) {
            worker {
                id
            }
        }
    }
`

// export const UPDATED_GET_WORKER_STATS = gql`
//     {
//         query getWorkerStats($productionUnitId: ID) {
//             prodUnitStats(productionUnitId: "611629020a30584e1c7e1d3d") {
//                 vaccinati
//                 infetti
//                 sani
//                 quarantena
//                 timestamp
//             }
//             globalVaccinated
//         }
//     }
//
// `

export const GET_WORKERS = gql`
    query getWorkers($homogeneousGroupId: ID) {
        workersByHg(homogeneousGroupId: $homogeneousGroupId) {
            nome
            note
            nMatricola
            id
        }
    }
`

// export const GET_ALL_WORKERS = gql`
//     query($page: Int){
//         workers(page: $page) {
//             id
//             nome
//             cognome
//             vaccinato
//             responsabile {
//               id
//               nome
//             }
//             turno {
//               id
//               nome
//             }
//             statoSalute
//             nMatricola
//             gruppoOmogeneo {
//               id
//               name
//               ownerProductionUnit {
//                 id
//               }
//             }
//         }
//     }
// `

export const GET_ALL_WORKERS = gql`
    query getAllWorkers(
        $perPage: Int
        $page: Int
        $productionUnitId: ID
        $filters: FilterType
    ) {
        workers(
            perPage: $perPage
            page: $page
            productionUnitId: $productionUnitId
            filters: $filters
        ) {
            workers {
                id
                nome
                note
                cognome
                nMatricola
                responsabile {
                    id
                    nMatricola
                    nome
                }
                statoSalute
                vaccinato
                turno {
                    id
                    nome
                }
                gruppoOmogeneo {
                    id
                    name
                    ownerProductionUnit {
                        id
                    }
                }
            }
            totalCount(pid: $productionUnitId)
        }
    }
`

export const WORKER_LENGTH = gql`
    {
        workersLength
    }
`
export const GET_ALL_HOMOGENEOUS_GROUPS = gql`
    {
        homogeneousGroups {
            name
            id
            ownerProductionUnit {
                id
            }
        }
    }
`

export const GET_HOMOGENEOUS_GROUP_QUERY = gql`
    query getProductionUnits($unitId: ID) {
        productionUnits(id: $unitId) {
            name
            id
            homogeneousGroups {
                id
                employeesCount
                workers {
                    nome
                }
                name
                ownerProductionUnit {
                    id
                }
            }
        }
    }
`

export const UPDATE_SHIFTS = gql`
    mutation updateShifts($shiftData: [ShiftType]!) {
        updateShifts(shiftData: $shiftData) {
            id
            nome
            lunedi {
                startTime
                endTime
            }
            martedi {
                startTime
                endTime
            }
            mercoledi {
                startTime
                endTime
            }
            giovedi {
                startTime
                endTime
            }
            venerdi {
                startTime
                endTime
            }
            sabato {
                startTime
                endTime
            }
            domenica {
                startTime
                endTime
            }
        }
    }
`

export const FETCH_AUDIT_LOG = gql`
    query getAuditLog($perPage: Int, $page: Int) {
        auditLog {
            logs(perPage: $perPage, page: $page) {
                id
                httpMethod
                statusCode
                headers
                payload
                parameters
                requestedUrl
                timestamp
                userRequested {
                    id
                }
            }
            totalCount
        }
    }
`
export const GET_SELECT_SHIFTS = gql`
    query getSelectShifts($homogeneousGroupId: ID) {
        selectShifts(homogeneousGroupId: $homogeneousGroupId) {
            id
            nome
        }
    }
`
export const DELETE_WORKER = gql`
    mutation deleteWorker($workerId: ID) {
        deleteWorker(workerId: $workerId) {
            deletedId
        }
    }
`

export const REFRESH_STATS = gql`
    query updateWorkerStats($productionUnitId: ID) {
        updateWorkerStats(productionUnitId: $productionUnitId) {
            timestamp
            sani
            infetti
            vaccinati
            quarantena
        }
    }
`

export const GLOBAL_STATS = gql`
    {
        globalStats {
            sani
            quarantena
            infetti
            vaccinati
        }
    }
`

export const PU_SCENARIO = gql`
    query getScenario($productionUnitId: ID) {
        scenarios(productionUnitId: $productionUnitId) {
            currentScenario {
                row
                col
                valuesIndex
                missingFields {
                    value
                    hgs {
                        hgid
                        name
                    }
                }
            }
            tables {
                row
                col
                values {
                    type
                    value
                    title
                    url
                }
            }
            productionUnit {
                id
            }
        }
    }
`

export const UPDATE_SCENARIO = gql`
    mutation editScenario($scenarioData: ScenarioFieldInput) {
        editScenario(scenarioData: $scenarioData) {
            scenario {
                currentScenario {
                    row
                    col
                    valuesIndex
                    missingFields {
                        value
                        hgs {
                            hgid
                            name
                        }
                    }
                }
                tables {
                    row
                    col
                    values {
                        type
                        value
                        title
                        url
                    }
                }
                productionUnit {
                    id
                }
            }
        }
    }
`

export const GET_SCENARIO = gql`
    query getScenario($productionUnitId: ID) {
        scenarios(productionUnitId: $productionUnitId) {
            currentScenario {
                row
                col
            }
        }
    }
`

export const GET_SCENARIO_TABLE_VALUES = gql`
    query tableByIndex($productionUnitId: ID) {
        tableByIndex(productionUnitId: $productionUnitId) {
            row
            col
            values {
                type
                value
                title
                url
            }
        }
    }
`

export const GET_JOB_HISTORY = gql`
    query GetJobHistory($page: Int, $perPage: Int) {
        jobHistory(page: $page, perPage: $perPage) {
            id
            jobName
            jobStatus {
                content
                success
            }
            timestamp
        }
    }
`

export const IMPORT_SCENARIO = gql`
    query importScenarios($fromId: ID, $toId: ID) {
        importScenarios(fromId: $fromId, toId: $toId) {
            tables {
                row
                col
                values {
                    type
                    title
                    value
                    url
                }
            }
        }
    }
`

export const GET_SCENARIOS_ID = gql`
    {
        allScenarios {
            id
            productionUnit
        }
    }
`

export const GET_CONFIG = gql`
    query getConfig($configKey: String) {
        config(key: $configKey)
    }
`
export const UPDATE_CONFIG = gql`
    mutation editConfig($configData: ConfigFieldInput, $configKey: String) {
        editConfig(configData: $configData, configKey: $configKey) {
            config {
                key
                value
            }
        }
    }
`
