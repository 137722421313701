import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import italianTranslation from './languages/it'
import englishTranslation from './languages/en'

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: {
            order: [
                'querystring',
                'cookie',
                'localStorage',
                'sessionStorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain',
            ],
        },
        resources: {
            it: {
                translation: italianTranslation,
            },
            en: {
                translation: englishTranslation,
            },
        },
        lng: i18n.language,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
