import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { ArrowBackRounded } from '@material-ui/icons'
import { useLocation, useHistory } from 'react-router-dom'

export default function BackButton(props) {
    const appLocation = useLocation()
    const appHistory = useHistory()
    const shouldBackButtonShow = ['/', '/login'].includes(appLocation?.pathname)

    return (
        <IconButton
            onClick={() => appHistory.goBack()}
            color="inherit"
            style={{
                visibility: shouldBackButtonShow && 'hidden',
                position: shouldBackButtonShow ? 'absolute' : 'static',
            }}
            {...props}
        >
            <ArrowBackRounded />
        </IconButton>
    )
}
