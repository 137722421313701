import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Box } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import image1 from '../../assets/images/instructions/step2.png'
import image2 from '../../assets/images/instructions/step3.png'
import image3 from '../../assets/images/instructions/step4.png'

import StepItem from './StepItem'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    avatarStyle: {
        backgroundColor: '#F1B500',
        height: '26px',
        width: '26px',
    },
    stepStyle: {
        marginBottom: '20px',
    },
    stepTextStyle: {
        fontWeight: 'bold',
        marginBottom: '4px',
    },
    linkStyle: {
        textDecoration: 'none',
        color: 'blue',
        '&:hover': {
            color: 'purple',
        },
    },
}))

export default function InstructionsAccordion() {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            <Accordion elevation={0} defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        pl="28px"
                    >
                        <Box position="absolute" left="0">
                            <Avatar
                                classes={{
                                    root: classes.avatarStyle,
                                }}
                            >
                                <FaRegQuestionCircle />
                            </Avatar>
                        </Box>
                        <Typography
                            style={{ fontSize: '18px' }}
                            className={classes.heading}
                        >
                            {t('come_ottenere_url')}
                        </Typography>
                    </Box>
                </AccordionSummary>

                <AccordionDetails>
                    <Box width="100%">
                        <StepItem step="1">
                            <Typography align="left">
                                Crea un account su{' '}
                                <a
                                    href="https://www.box.com/it-it/home"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.linkStyle}
                                >
                                    Box
                                </a>
                                , oppure accedi al tuo account.
                            </Typography>
                        </StepItem>

                        <StepItem step="2">
                            <Box mb="5px">
                                <Typography align="left">
                                    Crea o apri la cartella che vuoi utilizzare.
                                </Typography>
                            </Box>
                            <img src={image1} alt="step2img" />
                        </StepItem>

                        <StepItem step="3">
                            <Box mb="5px">
                                <Typography align="left">
                                    Apri la cartella premendoci sopra.
                                </Typography>
                                <Typography align="left">
                                    Sulla destra premi il tasto per creare il
                                    link condivisibile.
                                </Typography>
                            </Box>
                            <img src={image2} alt="step3img" />
                        </StepItem>

                        <StepItem step="4">
                            <Box mb="5px">
                                <Typography>
                                    Incolla il link nella sezione di
                                    configurazione.
                                </Typography>
                            </Box>
                            <Box>
                                <img
                                    style={{ width: '100%' }}
                                    src={image3}
                                    alt="step4img"
                                />
                            </Box>
                        </StepItem>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
