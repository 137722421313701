import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import DetailPU from '../Components/ProductionUnit/DetailPU'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, gql } from '@apollo/client'
import {
    GET_LAST_WOKRER_STATS,
    PRODUCTION_UNITS_QUERY,
    UPDATE_PRODUCTION_UNIT_MITIGATION_ACTIVITIES,
} from '../Components/ProductionUnit/GraphQL_queries'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    defaultMutationUpdateCallback,
    notifyFailure,
    notifySuccess,
} from '../utils'
import PageTitle from '../Components/Customized/PageTitle'
import PageContainer from '../Components/Customized/PageContainer'

const updateCacheAfterMutation = (cache, { data }) =>
    defaultMutationUpdateCallback({
        cache,
        mutatedData: data.editProductionUnitMitigationActivities,
        modelName: 'productionUnit',
        modelCollectionName: 'productionUnits',
        gqlFragment: gql`
            fragment ProductionUnit on ProductionUnitType {
                id
                mitigation {
                    mitigationActivities {
                        name
                        practicalEfficacy
                        practicalExecution
                        theoreticalMax
                        theoreticalMin
                        k
                    }
                    totalMitigation
                }
            }
        `,
    })

export default function ProductionUnitDetailScreen() {
    const { unitID } = useParams()
    const { loading, data } = useQuery(PRODUCTION_UNITS_QUERY, {
        variables: { unitId: unitID },
    })
    const [_updateProductionUnitRemediationActivities] = useMutation(
        UPDATE_PRODUCTION_UNIT_MITIGATION_ACTIVITIES,
        {
            update: updateCacheAfterMutation,
        }
    )

    const [currentStats, setCurrentStats] = useState({})
    const workerStatsQuery = useQuery(GET_LAST_WOKRER_STATS, {
        variables: {
            productionUnitId: unitID,
        },
    })

    React.useEffect(() => {
        if (
            workerStatsQuery !== undefined &&
            workerStatsQuery.data !== undefined
        ) {
            setCurrentStats(workerStatsQuery.data)
        } else {
            setCurrentStats({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workerStatsQuery.data])

    function updateProductionUnitRemediationActivities({
        mitigationActivities,
        productionUnitId,
    }) {
        _updateProductionUnitRemediationActivities({
            variables: {
                mitigationActivitiesData: mitigationActivities,
                productionUnitId,
            },
        })
            .then(notifySuccess)
            .catch(notifyFailure)
    }

    // const fakeEmployees = [
    //     { sick: 0, healthy: 10, dubious: 0, vaccinated: 1 },
    //     { sick: 0, healthy: 9, dubious: 1, vaccinated: 1 },
    //     { sick: 1, healthy: 7, dubious: 2, vaccinated: 2 },
    //     { sick: 1, healthy: 7, dubious: 2, vaccinated: 3 },
    // ]

    return (
        <PageContainer>
            {loading ? (
                <CircularProgress />
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="100%"
                >
                    <PageTitle>{data.productionUnits?.[0]?.name}</PageTitle>
                    <DetailPU
                        data={{
                            ...data.productionUnits?.[0],
                            stats: currentStats,
                        }}
                        updateHomogeneousRemediationActivities={
                            updateProductionUnitRemediationActivities
                        }
                    />
                </Box>
            )}
        </PageContainer>
    )
}
