import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyle = makeStyles((theme) => ({
    ribbon: {
        position: 'absolute',
        top: -30,
        left: -30,
        width: 50,
        height: 50,
        backgroundColor: (props) => props.color,
        transform: 'rotate(-45deg)',
        boxShadow: '1px 0 3px rgba(0,0,0,0.3)',
    },
}))

export default function ColoredRibbon({ color = 'dimgray' }) {
    const classes = useStyle({ color })
    return <div className={classes.ribbon} />
}
