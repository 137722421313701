import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import { IoIosArrowDown } from 'react-icons/io'
import { BsDot } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_SCENARIO_TABLE_VALUES } from './GraphQL_queries'

const useStyles = makeStyles((theme) => ({
    expandIcon: { position: 'relative', right: '35px' },
    link: {
        color: 'black',
        '&:hover': {
            color: 'blue',
        },
    },
    scenarioTableLink: {
        textDecoration: 'none',
        color: 'blue',
        '&:hover': {
            color: 'purple',
        },
    },
}))

export default function ScenarioAccordion({ id }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [tableData, setTableData] = React.useState([])

    const [getScenarioTableValue] = useLazyQuery(GET_SCENARIO_TABLE_VALUES, {
        fetchPolicy: 'network-only',
        variables: {
            productionUnitId: id,
        },
        onCompleted: (res) => {
            setTableData(res.tableByIndex?.values)
        },
    })

    React.useEffect(() => {
        getScenarioTableValue()
    }, [getScenarioTableValue])

    return (
        <Box width="100%" mt="10px">
            <Accordion style={{ margin: 0 }} elevation="0">
                <AccordionSummary
                    style={{ margin: 0 }}
                    classes={{ expandIcon: classes.expandIcon }}
                    expandIcon={<IoIosArrowDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Box
                        pl="35px"
                        width="100%"
                        display="flex"
                        justifyContent="center"
                    >
                        <Typography
                            style={{
                                fontSize: '15px',
                            }}
                            color={'textSecondary'}
                        >
                            {t('misure_sicurezza')}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0 0 20px' }}>
                    <Box width="100%">
                        {tableData?.length > 0 ? (
                            tableData?.map((item, idx) => {
                                return (
                                    <Box key={idx} width="100%">
                                        {item.type === 'text' ? (
                                            <Box
                                                width="100%"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <BsDot
                                                    color="black"
                                                    fontSize="22px"
                                                />
                                                <Typography>
                                                    {item.value}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            item.type === 'link' && (
                                                <Box
                                                    width="100%"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    component="a"
                                                    className={classes.link}
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <BsDot
                                                        color="black"
                                                        fontSize="22px"
                                                    />
                                                    <Typography>
                                                        {item.title}
                                                    </Typography>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                )
                            })
                        ) : (
                            <Box px="20px">
                                <Typography align="center">
                                    Nessuna misura di sicurezza specificata per
                                    lo scenario corrente.
                                </Typography>
                                <Box
                                    component="a"
                                    className={classes.scenarioTableLink}
                                    href={'/scenario/' + id}
                                >
                                    <Typography>{t('add_misure_a')}</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
