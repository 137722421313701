/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import HomogeneousGroupsSection from '../../Screens/HomogeneousGroupsSection'
import MitigationForm from '../HomogeneousGroup/MitigationForm'
import { Radar } from 'react-chartjs-2'
import { countermeasuresToRadarDatatype } from '../../utils'
import hash from 'object-hash'
import DashboardActions from '../../Screens/DashboardActions'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

const useStyle = makeStyles((theme) => ({
    topStatsPaper: {
        width: '100%',
        maxWidth: '900px',
        borderRadius: 8,
    },
    verticalTopStatsPaper: {
        width: '320px',
    },
    gridVerticalRule: {
        borderLeft: '1px solid rgba(0,0,0,0.15)',
    },
    gridHorizontalLayout: {
        width: '100%',
        borderTop: '1px solid rgba(0,0,0,0.15)',
    },
}))

export default function DetailPU({
    data,
    updateHomogeneousRemediationActivities,
    ...props
}) {
    const { t } = useTranslation()
    // eslint-disable-next-line no-unused-vars
    const { unitID } = useParams()
    const styles = useStyle()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [firstBreakpoint, setFirstBreakpoint] = useState()
    const [secondBreakpoint, setSecondBreakpoint] = useState()
    const [mitigationInputBP, setMitigationInputBP] = useState()
    const [mitigationLabelBP, setMitigationLabelBP] = useState()

    const checkSize = () => {
        if (windowWidth < 960 || isMobile) setFirstBreakpoint(true)
        else setFirstBreakpoint(false)
        if (windowWidth < 830 || isMobile) setSecondBreakpoint(true)
        else setSecondBreakpoint(false)

        if (windowWidth < 1080 || isMobile) setMitigationInputBP(true)
        else setMitigationInputBP(false)
        if (windowWidth < 970 || isMobile) setMitigationLabelBP(true)
        else setMitigationLabelBP(false)
    }

    React.useEffect(() => {
        checkSize()
    }, [])

    window.addEventListener('resize', (event) => {
        const latestWidth = window.innerWidth
        setWindowWidth(latestWidth)
        checkSize()
    })

    const gridContent = [
        { label: t('categoria'), value: 'category' },
        { label: t('indirizzo'), value: 'address' },
        { label: t('codice_ateco'), value: 'industryCode' },
        { label: t('numerosita_totale'), value: 'employeesCount' },
    ]

    const radarData = React.useCallback(() => {
        return data.mitigation.mitigationActivities
            ? countermeasuresToRadarDatatype(
                  data.mitigation.mitigationActivities
              )
            : {}
    }, [hash(data.mitigation.mitigationActivities)])

    const checkEmployees = (hgs) => {
        return hgs.reduce((sum, hg) => {
            sum += hg.employeesCount
            return sum
        }, 0)
    }
    const [insertedEmployees, setInsertedEmployees] = useState(
        data?.homogeneousGroups.reduce((sum, hg) => {
            sum += hg.employeesCount
            return sum
        }, 0)
    )

    function handleSubmit(values) {
        return updateHomogeneousRemediationActivities({
            ...values,
            productionUnitId: data.id,
        })
    }

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Paper
                className={firstBreakpoint && styles.verticalTopStatsPaper}
                classes={{ root: styles.topStatsPaper }}
            >
                <Box
                    display="flex"
                    flexDirection={firstBreakpoint ? 'column' : 'row'}
                    justifyContent="space-aroud"
                    alignItems="center"
                    width="100%"
                >
                    {gridContent.map((item, index) => (
                        <Box
                            flex="1"
                            className={
                                index > 0 && firstBreakpoint
                                    ? styles.gridHorizontalLayout
                                    : index > 0 && styles.gridVerticalRule
                            }
                            padding="10px 14px"
                        >
                            <Typography
                                color="textPrimary"
                                style={{ marginBottom: 2 }}
                            >
                                {item.label}
                            </Typography>

                            {item.value === 'employeesCount' ? (
                                <Typography
                                    key={
                                        'insertedEmployees' + insertedEmployees
                                    }
                                    color={
                                        insertedEmployees !==
                                        data.employeesCount
                                            ? 'error'
                                            : 'textPrimary'
                                    }
                                >
                                    {insertedEmployees}/{data.employeesCount}
                                </Typography>
                            ) : (
                                <Typography color="textSecondary">
                                    {data[item.value]}
                                </Typography>
                            )}
                        </Box>
                    ))}
                </Box>
            </Paper>
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <DashboardActions
                    provinces={[data.province]}
                    detailsData={data}
                    secondBreakpoint={secondBreakpoint}
                />

                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Box mt="40px" width="100%" padding="0 10px">
                        <HomogeneousGroupsSection
                            productionUnitId={data.id}
                            groupsIDs={data.homogeneousGroups}
                            employeesCount={data.employeesCount}
                            insertedEmployees={insertedEmployees}
                            setInsertedEmployees={setInsertedEmployees}
                            checkEmployees={checkEmployees}
                            secondBreakpoint={secondBreakpoint}
                        />
                    </Box>

                    <Box
                        mt="28px"
                        width="70%"
                        borderTop="1px solid rgba(0,0,0,0.15)"
                        mb="40px"
                    ></Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width="100%"
                    >
                        <Box mb="15px">
                            <Typography gutterBottom variant={'h4'}>
                                {t('attivita_correttive_preventive')}
                            </Typography>
                        </Box>
                        <Box width="100%">
                            <Paper style={{ borderRadius: 8 }}>
                                <Box
                                    display="flex"
                                    flexDirection={
                                        mitigationLabelBP ? 'column' : 'row'
                                    }
                                    justifyContent={
                                        mitigationLabelBP
                                            ? 'flex-start'
                                            : 'space-between'
                                    }
                                    width="100%"
                                    p="0 2%"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent={
                                            mitigationInputBP
                                                ? 'flex-start'
                                                : 'center'
                                        }
                                        alignItems="center"
                                        mt={mitigationInputBP ? '15px' : '0'}
                                    >
                                        <Box height="420px" width="420px">
                                            <Radar
                                                data={radarData}
                                                legend={null}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    scale: {
                                                        ticks: {
                                                            beginAtZero: true,
                                                            max: 100,
                                                            min: 0,
                                                            stepSize: 20,
                                                        },
                                                        pointLabels: {
                                                            callback: function (
                                                                label,
                                                                index,
                                                                labels
                                                            ) {
                                                                if (
                                                                    /\s/.test(
                                                                        label
                                                                    )
                                                                ) {
                                                                    return label.split(
                                                                        ' '
                                                                    )
                                                                } else {
                                                                    return label
                                                                }
                                                            },
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        width="100%"
                                        ml={mitigationLabelBP ? 0 : '40px'}
                                    >
                                        <MitigationForm
                                            onSubmit={handleSubmit}
                                            mitigationActivities={
                                                data.mitigation
                                                    .mitigationActivities
                                            }
                                            mitigationInputBP={
                                                mitigationInputBP
                                            }
                                            mitigationLabelBP={
                                                mitigationLabelBP
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
