import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Box,
    Button,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Fab,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ActivityRiskNumber from '../ProductionUnit/ActivityRiskNumber'
import { Controller, useFieldArray } from 'react-hook-form'
import AddIcon from '@material-ui/icons/Add'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2),
    },
    formButton: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    formLongField: {
        // margin: theme.spacing(1),
        flexGrow: 1,
        // minWidth: '20vw'
    },
    formField: {
        // margin: theme.spacing(1),
        minWidth: 80,
    },
    formSelectField: {
        width: 240,
    },
    fieldBox: {
        position: 'relative',
        marginBottom: theme.spacing(1),
    },
    fieldError: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        color: 'red',
        fontSize: theme.typography.fontSize * 0.8,
    },
}))

const OutlinedTextField = (props) => (
    <TextField margin="dense" fullWidth variant={'outlined'} {...props} />
)
const OutlinedFloatField = (props) => (
    <TextField
        {...props}
        margin="dense"
        type={'number'}
        // inputProps={{ min: '0', max: props.max, step: '0.1' }}
        variant={'outlined'}
    />
)
const OutlinedSelect = ({ label, passClassName, ...props }) => (
    <FormControl margin="dense" variant={'outlined'} className={passClassName}>
        <InputLabel id={`${props.name}-label`}>{label}</InputLabel>
        <Select label={label} labelId={`${props.name}-label`} {...props} />
    </FormControl>
)

export default function ActivitiesForm({
    activities,
    onSubmit,
    riskGroupIndex,
    control,
    register,
    windowWidth,
    setTotalPercentage,
    setPercentageHistory,
    percentageHistory,
    initSum,
    addSum,
    removeSum,
    ...rest
}) {
    const { t } = useTranslation()
    const preFix = 'activities'
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${riskGroupIndex}.${preFix}`,
    })
    const classes = useStyle(rest)
    const [customActivities, setCustomActivities] = useState(activities)

    const getTotalPercentage = () => {
        let totalPercentage = 0
        customActivities.forEach((item) => {
            totalPercentage += item.timePercentage
        })
        return totalPercentage
    }
    // eslint-disable-next-line no-unused-vars
    const [totalSum, setTotalSum] = useState(0)
    React.useEffect(() => {
        setCustomActivities(activities)
    }, [activities])

    // eslint-disable-next-line no-unused-vars
    const [currentTimePercentage, setTimePercentage] = useState([])

    React.useEffect(() => {
        setTotalSum(getTotalPercentage())
        let found = false
        percentageHistory.forEach((percentage) => {
            if (
                percentage.groupIndex === riskGroupIndex &&
                getTotalPercentage() > 0
            )
                found = true
        })
        if (!found) {
            let sumObject = {
                sum: getTotalPercentage(),
                groupIndex: riskGroupIndex,
            }
            setPercentageHistory((prevState) => [...prevState, sumObject])
        }
        let timePercentageList = []
        activities.forEach((activity, index) => {
            let defaults = {
                value: activity?.timePercentage ? activity?.timePercentage : 0,
                index: index,
            }
            timePercentageList.push(defaults)
        })
        setTimePercentage(timePercentageList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ---- Percentage overshoot check ----

    React.useEffect(() => {
        initSum(fields)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields])

    const checkSum = (value, id) => {
        addSum(value, id)
    }

    return (
        <Box w={windowWidth}>
            {fields.map((item, index) => (
                <Box
                    key={item.id}
                    className={classes.fieldBox}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    width={'100%'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        p={'10px'}
                    >
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name={`${riskGroupIndex}.${preFix}.${index}.description`}
                            render={({ field: { onChange, value } }) => (
                                <OutlinedTextField
                                    style={{ width: '100%' }}
                                    className={classes.formLongField}
                                    label={t('descrizione')}
                                    value={value}
                                    InputProps={{ required: true }}
                                    // error={errors?.activities?.[index]?.description ? true : false}
                                    // helperText={errors?.activities?.[index]?.description ? 'ciao' : false}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    defaultValue={item?.description} // make sure to set up defaultValue
                                />
                            )}
                        />
                        <Box
                            width={'100%'}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                // borderBottom={fields[index + 1] && "1px solid grey"}
                            >
                                <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name={`${riskGroupIndex}.${preFix}.${index}.timePercentage`}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <OutlinedFloatField
                                            name={item.id}
                                            className={classes.formField}
                                            label={t('tempo_percent')}
                                            style={{
                                                width: '50%',
                                                marginRight: '8px',
                                                paddingBottom: '8px',
                                            }}
                                            value={value}
                                            InputProps={{ required: true }}
                                            onChange={(e) => {
                                                let clonedActivities =
                                                    _.cloneDeep(
                                                        customActivities
                                                    )
                                                clonedActivities[
                                                    index
                                                ].timePercentage = parseInt(
                                                    e.target.value
                                                )
                                                setCustomActivities(
                                                    clonedActivities
                                                )
                                                onChange(e.target.value)

                                                checkSum(
                                                    e.target.value,
                                                    item.id
                                                )
                                            }}
                                            // max={rest.max}
                                            defaultValue={item?.timePercentage} // make sure to set up defaultValue
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    rules={{ required: true }}
                                    name={`${riskGroupIndex}.${preFix}.${index}.aggregation`}
                                    defaultValue={item?.aggregation || 1.0}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <OutlinedSelect
                                            key={'terzi'}
                                            InputProps={{ required: true }}
                                            style={{ width: '100%' }}
                                            passClassName={
                                                classes.formSelectField
                                            }
                                            label={t('aggregaz_con_terzi')}
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                            }}
                                            value={value}
                                        >
                                            <MenuItem value={1.0}>
                                                {t('activity_aggr3_1')}
                                            </MenuItem>
                                            <MenuItem value={1.15}>
                                                {t('activity_aggr3_2')}
                                            </MenuItem>
                                            <MenuItem value={1.3}>
                                                {t('activity_aggr3_3')}
                                            </MenuItem>
                                            <MenuItem value={1.5}>
                                                {t('activity_aggr3_4')}
                                            </MenuItem>
                                        </OutlinedSelect>
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'}>
                        {index < fields.length && !!item && (
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                            >
                                <ActivityRiskNumber
                                    aggregation={
                                        customActivities[index]?.aggregation
                                    }
                                    inducedRisk={
                                        customActivities[index]?.inducedRisk
                                    }
                                    //totalRisk={item.totalRisk}
                                />
                            </Box>
                        )}
                        <Box display={'flex'} alignItems={'center'}>
                            {index < fields.length && (
                                <Button
                                    style={{ width: '50%' }}
                                    onClick={() => {
                                        // rest.setMaxValue(rest.max + parseInt(item?.timePercentage))
                                        remove(index)

                                        removeSum(item.id)

                                        let clonedTimePercentage =
                                            _.cloneDeep(percentageHistory)
                                        let total = 0
                                        let remainingActivities =
                                            customActivities.filter(
                                                (o, i) => i !== index
                                            )

                                        remainingActivities.forEach(
                                            (activity, index) => {
                                                total += activity.timePercentage
                                            }
                                        )
                                        clonedTimePercentage.forEach(
                                            (percentage, currentIndex) => {
                                                if (
                                                    riskGroupIndex ===
                                                    percentage.groupIndex
                                                )
                                                    clonedTimePercentage[
                                                        index
                                                    ].sum = total
                                            }
                                        )
                                        setTimePercentage(clonedTimePercentage)
                                        setCustomActivities(
                                            customActivities.filter(
                                                (o, i) => i !== index
                                            )
                                        )
                                    }}
                                >
                                    <DeleteIcon />
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            ))}
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px',
                }}
            >
                <Fab
                    key={'add'}
                    color={'primary'}
                    size={'small'}
                    disabled={false}
                    onClick={() => {
                        const apObject = {
                            aggregation: 1.0,
                            timePercentage: null,
                            description: '',
                            // max: rest.max
                        }
                        append(apObject)
                        setCustomActivities([...customActivities, apObject])
                    }}
                >
                    <AddIcon />
                </Fab>
            </Box>
        </Box>
    )
}
