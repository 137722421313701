import React, { useEffect } from 'react'
import { Box, Card, Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { useLazyQuery } from '@apollo/client'
import { GET_SCENARIO } from './ProductionUnit/GraphQL_queries'
import { Chip } from '@material-ui/core'

import ScenarioAccordion from './ProductionUnit/ScenarioAccordion'
import { isMobile } from 'react-device-detect'

const useStyle = makeStyles(() => ({
    card: {
        width: 380,
        border: (props) =>
            props.restrainingBlock ? '1px solid #f44336' : 'none',
        display: 'inline-block',
        margin: '10px',
    },
    insuffDataStyle: {
        textDecoration: 'none',
        color: 'blue',
        fontSize: '16px',
        '&:hover': {
            color: 'purple',
        },
    },
}))

export default function CrudGrid({ items, onItemClick, renderItem }) {
    const { t } = useTranslation()
    const classes = useStyle({ items })
    const [currentScenario, setCurrentScenario] = React.useState({})

    const [getScenario] = useLazyQuery(GET_SCENARIO, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            setCurrentScenario(res.scenarios)
        },
    })

    useEffect(() => {
        getScenario()
    }, [getScenario, items])

    const alerts = [
        t('pre_alert'),
        t('alert'),
        t('pre_alarm'),
        t('alarm'),
        t('emergency'),
    ]

    const colors = ['#77C23B', '#80AC55', '#F9E8A3', '#F1C246', '#D33C30']

    const PuScTemplate = ({ idx, item }) => {
        return (
            <Box minHeight="160px" width="100%" height="100%">
                {currentScenario[idx]?.currentScenario.row === null ? (
                    <Box width="100%">
                        <Divider
                            style={{ marginBottom: '10px' }}
                            variant="middle"
                        />
                        <Box>
                            <Box>
                                <Typography
                                    style={{
                                        fontSize: '15px',
                                    }}
                                    color={'textSecondary'}
                                >
                                    {t('scenario_corrente')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            height="128.5px"
                            pb="15px"
                            display="flex"
                            alignItems="center"
                        >
                            <Box width="100%" mt="4px">
                                <Typography>{t('insuff_data')}</Typography>
                                <a
                                    className={classes.insuffDataStyle}
                                    href={'/scenario/' + item.id}
                                >
                                    {t('scopri_perche')}
                                </a>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box width="100%">
                        <Divider
                            style={{ marginBottom: '10px' }}
                            variant="middle"
                        />
                        <Typography
                            style={{
                                fontSize: '15px',
                            }}
                            color={'textSecondary'}
                        >
                            {t('scenario_corrente')}
                        </Typography>
                        <Box
                            mt="6px"
                            w="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <Box>
                                <Typography>{t('allerta_')}</Typography>
                                <Box mt="4px">
                                    <Chip
                                        color={
                                            colors[
                                                currentScenario[idx]
                                                    ?.currentScenario.row
                                            ]
                                        }
                                        style={{
                                            fontSize: '15px',
                                            backgroundColor:
                                                colors[
                                                    currentScenario[idx]
                                                        ?.currentScenario.row
                                                ],
                                        }}
                                        label={
                                            alerts[
                                                currentScenario[idx]
                                                    ?.currentScenario.row
                                            ]
                                        }
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography>{t('classe_')}</Typography>
                                <Box mt="4px">
                                    <Chip
                                        style={{
                                            fontSize: '15px',
                                            backgroundColor:
                                                colors[
                                                    currentScenario[idx]
                                                        ?.currentScenario.col
                                                ],
                                        }}
                                        label={
                                            t('classe') +
                                            ' ' +
                                            (currentScenario[idx]
                                                ?.currentScenario.col +
                                                1)
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <ScenarioAccordion id={item.id} />
                    </Box>
                )}
            </Box>
        )
    }

    return (
        <Box width="100%">
            {items[0]?.industryCode === undefined ? (
                items.map((item) => (
                    <Box key={item.id} padding="12px" display="inline-block">
                        <Box
                            onClick={() => onItemClick(item)}
                            style={{ cursor: 'pointer' }}
                        >
                            {renderItem({ ...item })}
                        </Box>
                    </Box>
                ))
            ) : (
                <Box>
                    {isMobile ? (
                        <Box width="400xp">
                            {items.map((item, idx) => (
                                <Card key={idx} className={classes.card}>
                                    <Box
                                        key={item.id}
                                        padding="12px 12px 0"
                                        display="inline-block"
                                    >
                                        <Box
                                            onClick={() => onItemClick(item)}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {renderItem({
                                                ...item,
                                            })}
                                        </Box>

                                        <PuScTemplate item={item} idx={idx} />
                                    </Box>
                                </Card>
                            ))}
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="space-between"
                            width="800px"
                        >
                            <Box flex={items.length === 1 ? '1' : 'auto'}>
                                {items.map(
                                    (item, idx) =>
                                        idx % 2 === 0 && (
                                            <Card
                                                key={idx}
                                                className={classes.card}
                                            >
                                                <Box
                                                    key={item.id}
                                                    padding="12px 12px 0"
                                                    display="inline-block"
                                                >
                                                    <Box
                                                        onClick={() =>
                                                            onItemClick(item)
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {renderItem({
                                                            ...item,
                                                        })}
                                                    </Box>

                                                    <PuScTemplate
                                                        item={item}
                                                        idx={idx}
                                                    />
                                                </Box>
                                            </Card>
                                        )
                                )}
                            </Box>
                            <Box>
                                {items.map(
                                    (item, idx) =>
                                        idx % 2 !== 0 && (
                                            <Card
                                                key={idx}
                                                className={classes.card}
                                            >
                                                <Box
                                                    key={item.id}
                                                    padding="12px 12px 0"
                                                    display="inline-block"
                                                >
                                                    <Box
                                                        onClick={() =>
                                                            onItemClick(item)
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {renderItem({
                                                            ...item,
                                                        })}
                                                    </Box>

                                                    <PuScTemplate
                                                        item={item}
                                                        idx={idx}
                                                    />
                                                </Box>
                                            </Card>
                                        )
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}

            {items.length < 1 && (
                <Grid item xs={12}>
                    <Typography
                        variant={'subtitle2'}
                        style={{ textAlign: 'center' }}
                    >
                        {t('no_el_warning')}
                    </Typography>
                </Grid>
            )}
        </Box>
    )
}
