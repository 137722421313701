import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'

export default function DashboardValue({
    title = '',
    subtitle,
    tooltipText,
    value,
    valueColor,
    trendIndicatorComponent,
    textVariant,
}) {
    const Icon = trendIndicatorComponent
    const TooltipOrNothing = tooltipText ? Tooltip : Fragment
    return (
        // <TooltipOrNothing title={tooltipText}>
        <TooltipOrNothing>
            <Box
                container
                spacing={3}
                alignContent={'center'}
                justify={'center'}
            >
                <Typography variant={'h2'}>{title}</Typography>
                {subtitle && (
                    <Typography variant={'caption'}>{subtitle}</Typography>
                )}

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {value && (
                        <Typography
                            variant={textVariant || 'h1'}
                            style={{ color: valueColor }}
                        >
                            {value}
                        </Typography>
                    )}
                    {trendIndicatorComponent && <Icon />}
                </div>
            </Box>
        </TooltipOrNothing>
    )
}
