import React, { Fragment } from 'react'
import './App.css'
import { createMuiTheme, CssBaseline } from '@material-ui/core'
import { ApolloProvider } from '@apollo/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'
import ProductionUnitsScreen from './Screens/ProductionUnitsScreen'
import ProductionUnitDetailScreen from './Screens/ProductionUnitDetailScreen'
import HomogeneousGroupDetailScreen from './Screens/HomogeneousGroupDetailScreen'
import LoginScreen from './Screens/LoginScreen'
import QuestionnaireScreen from './Screens/QuestionnaireScreen'
import { client } from './index'
import { ThemeProvider } from '@material-ui/styles'
import { responsiveFontSizes } from '@material-ui/core/styles'
import { useLoginStore } from './globalState'
import Confirm from './Components/Customized/Confirm'
import Sidebar from './Components/Customized/Sidebar'
import Topbar from './Components/Customized/Topbar'
import ShiftScreen from './Screens/ShiftsScreen'
import AllQuestionnaireScreen from './Screens/AllQuestionnaireScreen'
import OverviewDashboardScreen from './Screens/OverviewDashboardScreen'
import ArchiveOnlineScreen from './Screens/ArchiveOnlineScreen'
import AuditLogScreen from './Screens/AuditLogScreen'
import WorkerScreenTest from './Screens/WorkerScreen'
import EquipmentScreen from './Screens/EquipmentScreen'
import ScenarioScreen from './Screens/ScenarioScreen'
import JobHistoryScreen from './Screens/JobHistoryScreen'

const _theme = createMuiTheme({
    typography: {
        fontSize: 14,
        button: {
            fontSize: '1rem',
        },
        h1: {
            fontSize: 30,
        },
        h2: {
            fontSize: 26,
        },
        h3: {
            fontSize: 24,
        },
        h4: {
            fontSize: 22,
        },
        h5: {
            fontSize: 20,
        },
        h6: {
            fontSize: 18,
        },
    },
    palette: {
        secondary: {
            main: '#FF0405',
        },
        background: {
            default: '#f6f6f6',
        },
        status: {
            danger: 'rgb(224,63,87)',
            ok: 'rgb(63,224,117)',
            warning: 'rgb(255,205,86)',
            neutral: 'rgb(0,110,255)',
        },
    },
})

export const theme = responsiveFontSizes(_theme)

function PrivateRoute({ children, ...rest }) {
    const isLoggedIn = useLoginStore((state) => state.isLoggedIn)
    return (
        <Fragment>
            {isLoggedIn ? (
                <Route {...rest}>{children}</Route>
            ) : (
                <Redirect to={'/login'} />
            )}
        </Fragment>
    )
}

function App() {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App" style={{ display: 'flex' }}>
                    <ToastContainer />
                    <Router>
                        <Topbar />
                        <Sidebar
                            open={isSidebarOpen}
                            setOpen={setIsSidebarOpen}
                        />
                        <main style={{ flexGrow: 1 }}>
                            <Topbar setIsSidebarOpen={setIsSidebarOpen} />
                            <Switch>
                                <Route path={'/login'} exact>
                                    <LoginScreen />
                                </Route>
                                {/*<PrivateRoute path={'/'} exact>*/}
                                {/*    <OverviewDashboardScreen />*/}
                                {/*</PrivateRoute>*/}
                                <PrivateRoute
                                    exact
                                    path={'/workers/:unitID?/:hgID?'}
                                >
                                    {/*<WorkerScreen />*/}
                                    <WorkerScreenTest />
                                </PrivateRoute>
                                <PrivateRoute
                                    path={'/homogeneousGroups/:groupID'}
                                    exact
                                >
                                    <HomogeneousGroupDetailScreen />
                                </PrivateRoute>
                                <PrivateRoute path={'/productionUnits'} exact>
                                    <ProductionUnitsScreen />
                                </PrivateRoute>
                                <PrivateRoute path={'/equipments'} exact>
                                    <EquipmentScreen />
                                </PrivateRoute>
                                <PrivateRoute path={'/allQuestionnaire'} exact>
                                    <AllQuestionnaireScreen />
                                </PrivateRoute>
                                <PrivateRoute
                                    path={'/homogeneousGroups/:groupID/shifts'}
                                    exact
                                >
                                    <ShiftScreen />
                                </PrivateRoute>
                                <PrivateRoute
                                    path={'/productionUnits/:unitID'}
                                    exact
                                >
                                    <ProductionUnitDetailScreen />
                                </PrivateRoute>
                                <PrivateRoute path={'/questionnaire/:id'} exact>
                                    <QuestionnaireScreen />
                                </PrivateRoute>
                                <PrivateRoute path={'/'} exact>
                                    <OverviewDashboardScreen />
                                </PrivateRoute>
                                <PrivateRoute exact path={'/scenario/:PUid'}>
                                    <ScenarioScreen />
                                </PrivateRoute>
                                <PrivateRoute
                                    path={'/companyQuestionnaire'}
                                    exact
                                >
                                    <QuestionnaireScreen />
                                </PrivateRoute>
                                <PrivateRoute path={'/archive_online'} exact>
                                    <ArchiveOnlineScreen />
                                </PrivateRoute>
                                <PrivateRoute
                                    path={'/archive_online_window'}
                                    exact
                                >
                                    <ArchiveOnlineScreen />
                                </PrivateRoute>
                                <PrivateRoute path={'/auditLog'}>
                                    <AuditLogScreen />
                                </PrivateRoute>
                                <PrivateRoute exact path={'/jobHistory'}>
                                    <JobHistoryScreen />
                                </PrivateRoute>
                                <Route exact path={'/userQuestionnaire'}>
                                    <QuestionnaireScreen />
                                </Route>
                            </Switch>
                            <Confirm />
                        </main>
                    </Router>
                </div>
            </ThemeProvider>
        </ApolloProvider>
    )
}

export default App
