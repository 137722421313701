import { gql } from '@apollo/client'

export const HOMOGENEOUS_GROUPS_QUERY = gql`
    query upsertHomogeneousGroup($idList: [ID], $ownerProductionUnit: ID) {
        homogeneousGroups(
            idList: $idList
            ownerProductionUnit: $ownerProductionUnit
        ) {
            id
            name
            workers {
                id
            }
            risk {
                mitigatedRisk
                totalRisk
                proximityCategories {
                    name
                    activities {
                        aggregation
                        description
                        timePercentage
                        inducedRisk
                        totalRisk
                    }
                }
            }
            ownerProductionUnit {
                name
                id
            }
            employeesCount
            criticalities
        }
    }
`

export const HOMOGENEOUS_GROUP_DETAILS_QUERY = gql`
    query detailHomogeneousGroup($idList: [ID], $ownerProductionUnit: ID) {
        homogeneousGroups(
            idList: $idList
            ownerProductionUnit: $ownerProductionUnit
        ) {
            id
            name
            risk {
                proximityCategories {
                    name
                    weight
                    activities {
                        aggregation
                        description
                        timePercentage
                        inducedRisk
                        totalRisk
                    }
                }
                totalRisk
                mitigatedRisk
            }
            employeesCount
            criticalities
        }
    }
`

export const INSERT_HOMOGENEOUS_GROUP = gql`
    mutation insertHomogeneousGroup(
        $id: ID
        $name: String!
        $employeesCount: Int!
        $criticalities: String!
        $productionUnitId: ID
        $shifts: ID
    ) {
        insertHomogeneousGroup(
            homogeneousGroupData: {
                id: $id
                name: $name
                employeesCount: $employeesCount
                criticalities: $criticalities
                shifts: $shifts
            }
            productionUnitId: $productionUnitId
        ) {
            homogeneousGroup {
                id
                name
                employeesCount
                criticalities
            }
        }
    }
`

export const UPDATE_HOMOGENEOUS_GROUP = gql`
    mutation updateHomogeneousGroup(
        $id: ID
        $name: String
        $employeesCount: Int
        $criticalities: String
        $productionUnitId: ID
    ) {
        updateHomogeneousGroup(
            homogeneousGroupData: {
                id: $id
                name: $name
                employeesCount: $employeesCount
                criticalities: $criticalities
            }
            productionUnitId: $productionUnitId
        ) {
            homogeneousGroup {
                id
                name
                employeesCount
                criticalities
            }
        }
    }
`

export const UPDATE_HOMOGENEOUS_GROUP_ACTIVITIES = gql`
    mutation updateHGActivities(
        $groupId: ID!
        $activities: [ProximityFieldInput]!
    ) {
        editGroupRiskActivities(
            activitiesData: $activities
            groupId: $groupId
        ) {
            group {
                id
                name
                risk {
                    proximityCategories {
                        name
                        activities {
                            aggregation
                            description
                            timePercentage
                        }
                    }
                    mitigatedRisk
                    totalRisk
                }
            }
        }
    }
`

export const COPY_HOMOGENEOUS_GROUP_ACTIVITIES = gql`
    mutation copyHGActivities($groupId: ID!, $riskObject: RiskFieldInput) {
        copyGroupRiskActivities(groupId: $groupId, riskObject: $riskObject) {
            group {
                id
                name
                risk {
                    proximityCategories {
                        name
                        activities {
                            aggregation
                            description
                            timePercentage
                        }
                    }
                    mitigatedRisk
                    totalRisk
                }
            }
        }
    }
`
export const COPY_SHIFTS = gql`
    mutation copyShifts($fromGroupId: ID, $toGroupId: ID) {
        copyGroupShifts(fromGroupId: $fromGroupId, toGroupId: $toGroupId) {
            group {
                id
            }
        }
    }
`
export const DELETE_HOMOGENEOUS_GROUP = gql`
    mutation deleteHomogeneousGroup($id: ID) {
        deleteHomogeneousGroup(homogeneousGroupData: { id: $id }) {
            hg {
                ownerProductionUnit {
                    id
                }
            }
        }
    }
`

export const INSERT_SHIFT = gql`
    mutation insertShift(
        $nome: String!
        $lunedi: [ShiftRangeFieldInput]
        $martedi: [ShiftRangeFieldInput]
        $mercoledi: [ShiftRangeFieldInput]
        $giovedi: [ShiftRangeFieldInput]
        $venerdi: [ShiftRangeFieldInput]
        $sabato: [ShiftRangeFieldInput]
        $domenica: [ShiftRangeFieldInput]
        $homogeneousGroupId: ID
    ) {
        insertShift(
            shiftData: {
                nome: $nome
                lunedi: $lunedi
                martedi: $martedi
                mercoledi: $mercoledi
                giovedi: $giovedi
                venerdi: $venerdi
                sabato: $sabato
                domenica: $domenica
                homogeneousGroupId: $homogeneousGroupId
            }
        ) {
            shift {
                id
            }
        }
    }
`
export const DELETE_SHIFT = gql`
    mutation deleteShift($shiftId: ID) {
        deleteShift(shiftId: $shiftId) {
            deletedId
        }
    }
`

export const GET_QUESTIONNAIRE = gql`
    query getQuestionnaire($questionnaireId: ID) {
        questionnaire(questionnaireId: $questionnaireId) {
            timestamp
            notes
            questionnaireType
            questions {
                notes
                status
                question
            }
        }
    }
`

export const DELETE_QUESTIONNAIRE = gql`
    mutation deleteQuestionnaire($questionnaireId: ID) {
        deleteQuestionnaire(questionnaireId: $questionnaireId) {
            deletedId
        }
    }
`

export const GET_WORKER_BY_SHIFT = gql`
    query getWorkersByShift($shiftId: ID) {
        workersByShift(shiftId: $shiftId) {
            nome
            cognome
        }
    }
`

export const GET_SHIFTS = gql`
    query GetShifts($homogeneousGroupId: ID, $page: Int, $perPage: Int) {
        shifts(
            homogeneousGroupId: $homogeneousGroupId
            page: $page
            perPage: $perPage
        ) {
            shifts {
                id
                nome
                lunedi {
                    startTime
                    endTime
                }
                martedi {
                    startTime
                    endTime
                }
                mercoledi {
                    startTime
                    endTime
                }
                giovedi {
                    startTime
                    endTime
                }
                venerdi {
                    startTime
                    endTime
                }
                sabato {
                    startTime
                    endTime
                }
                domenica {
                    startTime
                    endTime
                }
            }
            totalCount
        }
    }
`

export const UPDATE_SHIFT = gql`
    mutation updateShift($shiftData: ShiftFieldInput) {
        updateShift(shiftData: $shiftData) {
            shift {
                id
            }
        }
    }
`

export const GET_LAST_QUESTIONNAIRE = gql`
    {
        lastQuestionnaire {
            questions {
                question
                status
                notes
            }
        }
    }
`
export const GET_SHIFT_LENGTH = gql`
    query shiftLength($homogeneousGroupId: ID) {
        shiftLength(homogeneousGroupId: $homogeneousGroupId)
    }
`

export const GET_ALL_QUESTIONNAIRE = gql`
    query allQuestionnaire(
        $page: Int
        $perPage: Int
        $questionnaireType: String
    ) {
        allQuestionnaire(
            page: $page
            perPage: $perPage
            questionnaireType: $questionnaireType
        ) {
            questionnaires {
                id
                timestamp
                notes
                questions {
                    question
                    status
                    notes
                }
            }
            totalCount
        }
    }
`
export const GET_QUESTIONNAIRE_LENGTH = gql`
    {
        questionnaireLength
    }
`
export const INSERT_QUESTIONNAIRE = gql`
    mutation insertQuestionnaire($questionnaireData: QuestionnaireFieldInput) {
        insertQuestionnaire(questionnaireData: $questionnaireData) {
            questionnaire {
                id
            }
        }
    }
`

export const GET_SHARED_FOLDER = gql`
    {
        sharedFolder
    }
`

export const DELETE_EQUIPMENT = gql`
    mutation deleteEquipment($equipmentId: ID) {
        deleteEquipment(equipmentId: $equipmentId) {
            deletedId
        }
    }
`

export const GET_ALL_EQUIPMENT = gql`
    query getAllEquipments(
        $perPage: Int
        $page: Int
        $equipmentId: ID
        $filters: FilterType
    ) {
        equipments(
            perPage: $perPage
            page: $page
            equipmentId: $equipmentId
            filters: $filters
        ) {
            equipments {
                id
                lastAssigned
                note
                currentOwner {
                    id
                    nome
                    note
                    cognome
                    nMatricola
                    gruppoOmogeneo {
                        id
                        ownerProductionUnit {
                            id
                        }
                    }
                }
                asset {
                    category
                    asset
                }
                history {
                    firstName
                    lastName
                    nMatricola
                    startTime
                    endTime
                }
            }
            totalCount
        }
    }
`

export const INSERT_EQUIPMENT = gql`
    mutation insertEquipment($equipmentData: EquipmentFieldInput) {
        insertEquipment(equipmentData: $equipmentData) {
            equipment {
                id
                note
                asset {
                    category
                    asset
                }
                lastAssigned
                currentOwner {
                    id
                    nome
                    cognome
                    nMatricola
                }
            }
        }
    }
`

export const GET_ASSETS_BY_CATEGORY = gql`
    query getAssetsByCategory($category: AssetCategory) {
        assets(category: $category) {
            asset
        }
    }
`

export const UPDATE_EQUIPMENT = gql`
    mutation updateEquipment(
        $equipmentId: ID
        $equipmentData: EquipmentFieldInput
    ) {
        editEquipment(
            equipmentId: $equipmentId
            equipmentData: $equipmentData
        ) {
            equipment {
                currentOwner {
                    id
                }
                lastAssigned
                asset {
                    asset
                    category
                }
            }
        }
    }
`
