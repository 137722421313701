import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Box, Button, TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import axios from 'axios'
import { useLoginStore } from '../globalState'
import {
    DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL,
    client,
} from '../index'
import { notifyFailure } from '../utils'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 500,
    },
    formField: {
        margin: theme.spacing(0.5),
        width: 360,
        height: 56,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

export default function LoginScreen() {
    const { t } = useTranslation()
    const classes = useStyle()
    const appHistory = useHistory()
    const [setLoggedIn, isLoggedIn, setLoggedOut] = useLoginStore((state) => [
        state.setLoggedIn,
        state.isLoggedIn,
        state.setLoggedOut,
    ])

    function login({ email, password }) {
        // const formData = new FormData()
        // formData.set('email', email)
        // formData.set('password', password)

        return axios
            .post(
                DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL +
                    '/login',
                { email, password },
                { withCredentials: true }
            )
            .then((resp) => {
                client.resetStore()
                setLoggedIn()
                appHistory.push('/productionUnits')
            })
            .catch((err) => {
                notifyFailure(t('login_failure_warning'))
                logout()
            })
    }

    function logout() {
        setLoggedOut()
        return axios.get(
            DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL +
                '/logout'
        )
    }

    return (
        <Box className={classes.container}>
            {isLoggedIn ? (
                appHistory.push('/productionUnits')
            ) : (
                <Formik
                    enableReinitialize
                    initialValues={{ email: '', password: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                        login({
                            email: values.email,
                            password: values.password,
                        }).then(() => setSubmitting(false))
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form className={classes.formContainer}>
                            <Field
                                label="Email"
                                name={'email'}
                                as={TextField}
                                variant={'outlined'}
                                className={classes.formField}
                            />
                            <Field
                                label="Password"
                                type={'password'}
                                variant={'outlined'}
                                name={'password'}
                                as={TextField}
                                className={classes.formField}
                            />
                            <Button
                                className={classes.formField}
                                fullWidth
                                color={'primary'}
                                variant={'contained'}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Login
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}
        </Box>
    )
}
