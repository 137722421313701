import React from 'react'
import { FormControl, InputLabel, Select, TextField } from '@material-ui/core'

const OutlinedTextField = (props) => (
    <TextField variant={'outlined'} {...props} />
)

const OutlinedSelect = ({ label, passClassName, dense, ...props }) => (
    <FormControl variant={'outlined'} className={passClassName}>
        <InputLabel id={`${props.name}-label`} margin={dense && 'dense'}>
            {label}
        </InputLabel>
        <Select
            label={label}
            labelId={`${props.name}-label`}
            {...props}
            margin={dense && 'dense'}
        />
    </FormControl>
)

export { OutlinedTextField, OutlinedSelect }
