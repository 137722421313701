import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation, useQuery } from '@apollo/client'
import {
    DELETE_QUESTIONNAIRE,
    GET_ALL_QUESTIONNAIRE,
} from '../Components/HomogeneousGroup/GraphQL_queries'
import {
    CircularProgress,
    Divider,
    Fab,
    IconButton,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import PageContainer from '../Components/Customized/PageContainer'
import PageTitle from '../Components/Customized/PageTitle'
import { useTranslation } from 'react-i18next'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useConfirmDialog, useQuestionnaireTypeStore } from '../globalState'
import { useHistory } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import { notifyFailure, notifySuccess } from '../utils'
import { Pagination } from '@material-ui/lab'
import AddIcon from '@material-ui/icons/Add'
import Moment from 'react-moment'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { ImLink } from 'react-icons/im'
import { useLocation } from 'react-router'
import Snackbar from '@material-ui/core/Snackbar'
import { Slide } from '@material-ui/core'
import { DoneAll } from '@material-ui/icons'
import { isMobile } from 'react-device-detect'
import CustomTooltip from '../Components/Customized/CustomTooltip'
import { Grow } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
    },
    snackbar: {
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid black',
        borderRadius: '7px',
        boxShadow: '10px',
        color: 'black',
    },
    boxWidthDesktop: {
        width: '50%',
    },
    boxWidthMobile: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordionDetail: {
        display: 'inline',
        alignItems: 'flex-start',
        justifyItems: 'left',
    },
    fab: {
        position: 'fixed',
        right: theme.spacing(4),
        bottom: theme.spacing(4),
    },

    list: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
    },
}))

const Transition = (props) => {
    const classes = useStyles()

    return (
        <Box width='100%'>
            <Slide
                {...props}
                direction={isMobile ? 'right' : 'up'}
                className={classes.snackbar}
            />
        </Box>
    )
}

const Message = ({ message }) => (
    <Box
        width='100%'
        display='flex'
        flexDirection={'row'}
        justifyContent='flex-start'
        alignItems='center'
    >
        <Box>
            <DoneAll color='primary' />
        </Box>
        <Box marginLeft={'16px'}>
            <Typography variant='body1'>{message}</Typography>
        </Box>
    </Box>
)

const LinkSnackbar = ({ handleLinkSnackClose, isLinkSnackOpen }) => {
    const { t } = useTranslation()

    return (
        <Box p='0px'>
            {isMobile ? (
                <Snackbar
                    style={{ width: '100%' }}
                    open={isLinkSnackOpen}
                    onClose={handleLinkSnackClose}
                    autoHideDuration={1500}
                    message={<Message message={t('copiato_successo')} />}
                    TransitionComponent={Transition}
                    className={'MuiSnackbarContent-message-mobile'}
                />
            ) : (
                <Snackbar
                    open={isLinkSnackOpen}
                    onClose={handleLinkSnackClose}
                    autoHideDuration={1500}
                    message={<Message message={t('copiato_successo')} />}
                    TransitionComponent={Transition}
                    className={'MuiSnackbarContent-message-desktop'}
                />
            )}
        </Box>
    )
}

export default function AllQuestionnaireScreen() {
    const classes = useStyles()
    //Query to retrieve questionnaire data
    const appHistory = useHistory()
    const location = useLocation()
    const { t } = useTranslation()
    const [isLinkSnackOpen, setIsLinkSnackOpen] = useState(false)
    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])
    const [delQuestionnaire] = useMutation(DELETE_QUESTIONNAIRE)
    const [itemsPerPage, setItemsPerPage] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalLength, setTotalLength] = useState(0)
    const { questionnaireType, setIsCompany, setIsUser } =
        useQuestionnaireTypeStore()
    const { loading, data, refetch, fetchMore } = useQuery(
        GET_ALL_QUESTIONNAIRE,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            variables: {
                page: currentPage,
                perPage: itemsPerPage,
                questionnaireType: questionnaireType,
            },
        },
    )

    useEffect(() => {
        if (data?.allQuestionnaire) {
            setTotalLength(data?.allQuestionnaire?.totalCount)
        }
    }, [data])

    async function handlePageChange(e, page) {
        await fetchMore({
            variables: {
                page: page,
                itemsPerPage: itemsPerPage,
                questionnaireType: questionnaireType,
            },
        })
        setCurrentPage(page)
    }

    const handleLinkSnackClose = () => setIsLinkSnackOpen(false)

    function deleteQuestionnaire(id) {
        openConfirm({
            title: t('elimina_questionario_titolo'),
            description: t('elimina_questionario_descrizione'),
            onConfirm: () =>
                delQuestionnaire({ variables: { questionnaireId: id } })
                    .then(async () => {
                        notifySuccess(t('elimina_questionario_successo'))
                        if (totalLength > 0)
                            if ((totalLength - 1) % 5 === 0)
                                if (currentPage !== 1)
                                    setCurrentPage((prevState) => prevState - 1)
                        setTotalLength((prevState) => prevState - 1)

                        await refetch()
                    })
                    .catch(notifyFailure),
        })
    }

    useEffect(() => {
        fetchMore({
            variables: {
                page: currentPage,
                perPage: itemsPerPage,
                questionnaireType: questionnaireType,
            },
        }).then(() => Promise.resolve())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaireType])

    return (
        <PageContainer>
            <PageTitle>{t('questionari')}</PageTitle>

            <Box
                boxShadow={2}
                classes={{ root: classes.root }}
                className={
                    isMobile ? classes.boxWidthMobile : classes.boxWidthDesktop
                }
                zIndex={-1}
            >
                <Box paddingTop='14px'>
                    <FormControl component='fieldset' style={{ width: '100%' }}>
                        <Box w={"100%"}>
                            <FormLabel
                                component='legend'
                                style={{ color: 'rgba(0,0,0,0.8) !important' }}
                                focused={false}
                            >
                                {t('tipo_questionario')}
                            </FormLabel>
                        </Box>
                        <RadioGroup
                            row
                            aria-label='position'
                            name='position'
                            defaultValue='company'
                            onChange={(e) => {
                                if (e.target.value === 'company') setIsCompany()
                                else if (e.target.value === 'user') setIsUser()
                            }}
                            value={questionnaireType}
                        >
                            <Box
                                margin='10px 0'
                                width='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='space-evenly'
                            >
                                <FormControlLabel
                                    value='company'
                                    control={<Radio color='primary' />}
                                    label={t('compagnia')}
                                    labelPlacement='start'
                                />
                                <FormControlLabel
                                    value='user'
                                    control={<Radio color='primary' />}
                                    label={t('lavoratori_terzi')}
                                    labelPlacement='start'
                                />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </Box>
                <List className={classes.list}>
                    {data?.allQuestionnaire?.questionnaires?.length > 0 ? (
                        data?.allQuestionnaire?.questionnaires.map(
                            (questionnaire, index) => {
                                return (
                                    <ListItem divider>
                                        <ListItemIcon>
                                            <IconButton
                                                onClick={() =>
                                                    appHistory.push(
                                                        `/questionnaire/${questionnaire.id}`,
                                                    )
                                                }
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText>
                                            {`${t('questionario_compilato')} `}
                                            <Moment format='DD-MM-YYYY HH:mm'>
                                                {`${questionnaire.timestamp}`}
                                            </Moment>
                                        </ListItemText>
                                        <IconButton
                                            aria-label='delete'
                                            color='secondary'
                                            onClick={() =>
                                                deleteQuestionnaire(
                                                    questionnaire.id,
                                                )
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <Divider className={classes.divider} />
                                    </ListItem>
                                )
                            },
                        )
                    ) : (
                        <h3>{t('no_questionari')}</h3>
                    )}
                    {loading && <CircularProgress />}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            position: 'relative',
                        }}
                    >
                        {data?.allQuestionnaire?.totalCount === 0 ? null : (
                            <Box
                                style={{
                                    flex: '0 1 auto',
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    maxWidth: '75%',
                                }}
                            >
                                <Pagination
                                    style={{ margin: '8px' }}
                                    onChange={(event, page) =>
                                        handlePageChange(event, page)
                                    }
                                    count={
                                        totalLength > itemsPerPage
                                            ? totalLength % itemsPerPage > 0
                                                ? Math.floor(
                                                totalLength / itemsPerPage,
                                            ) + 1
                                                : totalLength / itemsPerPage
                                            : 1
                                    }
                                    color='primary'
                                />
                            </Box>
                        )}
                        <Box
                            style={{
                                flex: '0 1 auto',
                                marginLeft: 'auto',
                                alignItems: 'center',
                            }}
                            mr='15px'
                        >
                            <Box display='flex' alignItems='center'>
                                <Typography
                                    variant={'body1'}
                                    color={'textSecondary'}
                                >
                                    {t('per_pagina')}
                                </Typography>
                                <Select
                                    value={itemsPerPage}
                                    style={{ margin: '8px' }}
                                    onChange={(e) => {
                                        setItemsPerPage(e.target.value)
                                        setCurrentPage(1)
                                        fetchMore({
                                            variables: {
                                                page: 1,
                                                itemsPerPage: itemsPerPage,
                                                questionnaireType:
                                                questionnaireType,
                                            },
                                        }).then(() => Promise.resolve())
                                    }}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                    </Box>
                    {/*</Grid>*/}
                    {/*</Grid>*/}
                </List>
            </Box>

            {questionnaireType === 'company' ? (
                <Box
                    className={classes.fab}
                    height={'fit-content'}
                    width='130px'
                    position='relative'
                >
                    <Box zIndex='11'>
                        <Grow in={true}>
                            <Fab
                                zIndex='10'
                                color='primary'
                                aria-label='add'
                                onClick={() =>
                                    appHistory.push('/companyQuestionnaire')
                                }
                                userQuestionnaire
                            >
                                <AddIcon />
                            </Fab>
                        </Grow>
                    </Box>
                </Box>
            ) : (
                <Box
                    className={classes.fab}
                    height={'fit-content'}
                    width='130px'
                    position='relative'
                >
                    <Box zIndex='11'>
                        <CustomTooltip
                            title={t('copia_link_quest_utente')}
                            placement='left'
                        >
                            <Grow in={true}>
                                <Fab
                                    zIndex='1'
                                    style={{ backgroundColor: '#FFF' }}
                                    aria-label='add'
                                    onClick={() => {
                                        setIsLinkSnackOpen(true)
                                        navigator.clipboard.writeText(
                                            window.location.href.replace(
                                                location.pathname,
                                                '',
                                            ) + '/userQuestionnaire',
                                        )
                                    }}
                                >
                                    <ImLink />
                                </Fab>
                            </Grow>
                        </CustomTooltip>
                    </Box>
                </Box>
            )}

            <LinkSnackbar
                handleLinkSnackClose={handleLinkSnackClose}
                isLinkSnackOpen={isLinkSnackOpen}
            />
        </PageContainer>
    )
}
