import React, { useEffect, useState } from 'react'
import { useFieldArray, Controller } from 'react-hook-form'
import { Grid, List, Button, TextField, Fab, Paper } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line no-unused-vars
const DeleteButton = withStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
        '&:hover': {
            background: theme.palette.background.default,
        },
    },
}))(Button)

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    formField: {
        margin: theme.spacing(1),
    },
    formSelectField: {
        minWidth: 140,
    },
    menuItem: {
        '&:hover': {
            backgroundColor: '#dedede',
        },
    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        width: 500,
        margin: theme.spacing(1),
    },
    fieldBox: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // width: 500,
    },
    formLongField: {
        margin: theme.spacing(1),
        flexGrow: 1,
        // minWidth: '20vw'
    },
    externalGrid: {
        margin: theme.spacing(1),
    },
}))

export default function FieldsArrayInsert({
    control,
    register,
    day,
    isError,
    setIsError,
    isEdit,
    ...props
}) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [errors, setErrors] = useState([])
    const [notInsertedAll, setNotInsertedAll] = useState(false)
    const [dateRanges, setDateRanges] = useState([])
    const { fields, append, remove } = useFieldArray({
        control,
        shouldUnregister: false,
        name: day,
    })

    const buildDate = (timeString) => {
        let newDate = new Date()
        newDate.setUTCHours(timeString?.split(':')[0])
        newDate.setUTCMinutes(timeString?.split(':')[1])
        return newDate
    }

    useEffect(() => {
        if (isEdit?.[day]) {
            let dateRangesCopy = _.cloneDeep(isEdit[day])
            isEdit[day].forEach((item, index) => {
                Object.assign(dateRangesCopy[index], {
                    startTime: buildDate(dateRangesCopy[index].startTime),
                    endTime: buildDate(dateRangesCopy[index].endTime),
                })
            })
            setDateRanges(dateRangesCopy)
            setErrors([])
            setIsError(false)
            setNotInsertedAll(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validateStart = (start, end, item) => {
        if (start > end) {
            setIsError(true)
            setNotInsertedAll(true)
            if (!errors.includes(item.id)) setErrors([...errors, item.id])
        } else {
            setErrors(errors.filter((err) => err !== item.id))
            let check = errors.filter((err) => err !== item.id)
            if (check.length === 0) {
                setIsError(false)
            }
            setNotInsertedAll(false)
        }
    }

    const validatePrevious = (
        start,
        end,
        item,
        index,
        toValidate,
        currentEnd = null
    ) => {
        let copyDateRanges = _.cloneDeep(dateRanges)
        if (start < end) {
            setIsError(true)
            setNotInsertedAll(true)
            if (!errors.includes(item.id)) setErrors([...errors, item.id])
        } else {
            if (toValidate === 'start') {
                if (copyDateRanges[index]?.endTime) {
                    let endDate = copyDateRanges[index].endTime
                    validateStart(start, endDate, item)
                } else {
                    setErrors(errors.filter((err) => err !== item.id))
                    let check = errors.filter((err) => err !== item.id)
                    if (check?.length === 0) {
                        setIsError(false)
                    }
                    setNotInsertedAll(false)
                }
            } else if (toValidate === 'end') {
                if (copyDateRanges[index]?.startTime) {
                    let startTime = copyDateRanges[index]?.startTime
                    if (currentEnd) {
                        validateStart(startTime, currentEnd, item)
                    } else {
                        validateStart(startTime, end, item)
                    }
                } else {
                    setErrors(errors.filter((err) => err !== item.id))
                    let check = errors.filter((err) => err !== item.id)
                    if (check.length === 0) {
                        setIsError(false)
                    }
                    setNotInsertedAll(false)
                }
            }
        }
    }

    //toValidate parameter is used to catch if is validating start or end time
    const validate = (date, item, index, toValidate) => {
        let buildedDate = buildDate(date)
        let copyDateRanges = _.cloneDeep(dateRanges)
        if (toValidate === 'start') {
            //set data in a state in order to be able to validate also the values update
            if (copyDateRanges[index]) {
                Object.assign(copyDateRanges[index], { startTime: buildedDate })
                setDateRanges(copyDateRanges)
                //control if a previous end time exists, if true validates with the previous range
                //Otherwise, validates only with the current end
                if (copyDateRanges[index - 1]?.endTime) {
                    let end = copyDateRanges[index - 1].endTime
                    validatePrevious(buildedDate, end, item, index, toValidate)
                } else if (copyDateRanges[index]?.endTime)
                    validateStart(
                        buildedDate,
                        copyDateRanges[index]?.endTime,
                        item
                    )
            } else {
                //if the range[index] !exists create the instance and validate with the previous
                copyDateRanges.push({ startTime: buildedDate })
                setDateRanges(copyDateRanges)
                if (copyDateRanges[index - 1]?.endTime) {
                    let endDate = copyDateRanges[index - 1].endTime
                    validatePrevious(
                        buildedDate,
                        endDate,
                        item,
                        index,
                        toValidate
                    )
                }
            }
        } else if (toValidate === 'end') {
            //set data in a state in order to be able to validate also the values update
            if (copyDateRanges[index]) {
                Object.assign(copyDateRanges[index], { endTime: buildedDate })
                setDateRanges(copyDateRanges)
                //control if a next end time exists, if true validates with the next range
                //Otherwise, validates only with the current start
                if (copyDateRanges[index + 1]?.startTime) {
                    let start = copyDateRanges[index + 1]?.startTime
                    validatePrevious(
                        start,
                        buildedDate,
                        item,
                        index,
                        toValidate
                    )
                } else if (copyDateRanges[index]?.startTime) {
                    if (copyDateRanges[index - 1]?.endTime) {
                        let start = copyDateRanges[index]?.startTime
                        let end = copyDateRanges[index - 1]?.endTime
                        validatePrevious(
                            start,
                            end,
                            item,
                            index,
                            toValidate,
                            buildedDate
                        )
                    } else
                        validateStart(
                            copyDateRanges[index]?.startTime,
                            buildedDate,
                            item
                        )
                }
            } else {
                //if the range[index] !exists create the instance and validate with the next if exists
                copyDateRanges.push({ endTime: buildedDate })
                setDateRanges(copyDateRanges)
                if (copyDateRanges[index + 1]?.startTime) {
                    let start = copyDateRanges[index + 1]?.startTime
                    validatePrevious(
                        start,
                        buildedDate,
                        item,
                        index,
                        toValidate
                    )
                }
            }
        }
    }

    return (
        <Grid item xs={12} className={classes.externalGrid}>
            <List>
                {fields.map((item, index) => {
                    return (
                        <Paper
                            key={item.id}
                            className={classes.button}
                            elevation={0}
                            variant={'outlined'}
                            style={{
                                border:
                                    errors?.includes(item.id) &&
                                    '1px solid red',
                            }}
                        >
                            <Box className={classes.fieldBox}>
                                <Controller
                                    control={control}
                                    name={`${day}.${index}.startTime`}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <TextField
                                            className={classes.formLongField}
                                            label={t('inizio_turno')}
                                            type={'time'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={
                                                errors.length
                                                    ? errors?.includes(item?.id)
                                                        ? false
                                                        : true
                                                    : false
                                            }
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                validate(
                                                    e.target.value,
                                                    item,
                                                    index,
                                                    'start'
                                                )
                                            }}
                                        />
                                    )}
                                ></Controller>
                                <Controller
                                    control={control}
                                    name={`${day}.${index}.endTime`}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <TextField
                                            className={classes.formLongField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={t('fine_turno')}
                                            type={'time'}
                                            defaultValue={''}
                                            disabled={
                                                errors?.length > 0
                                                    ? errors?.includes(item?.id)
                                                        ? false
                                                        : true
                                                    : false
                                            }
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                                validate(
                                                    e.target.value,
                                                    item,
                                                    index,
                                                    'end'
                                                )
                                            }}
                                        />
                                    )}
                                />
                                <IconButton
                                    onClick={() => {
                                        if (errors.includes(item.id)) {
                                            setErrors(
                                                errors.filter(
                                                    (err) => err !== item.id
                                                )
                                            )
                                            if (errors.length === 0)
                                                setIsError(false)
                                        }
                                        if (dateRanges[index]){
                                            let copyDateRanges = _.cloneDeep(dateRanges)
                                            copyDateRanges.splice(index)
                                            setDateRanges(copyDateRanges)
                                        }
                                        setNotInsertedAll(false)
                                        remove(index)
                                    }}
                                    aria-label="delete"
                                    color={'secondary'}
                                    className={classes.margin}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Paper>
                    )
                })}
            </List>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Fab
                    onClick={() => {
                        setNotInsertedAll(true)
                        append({})
                    }}
                    className={classes.addButton}
                    size={'small'}
                    color="primary"
                    disabled={notInsertedAll}
                    aria-label="add"
                >
                    <AddIcon />
                </Fab>
            </Box>
        </Grid>
    )
}

function FieldsArrayEdit({ control, register, day, currentIndex, ...props }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const fieldArrayNamePrefix = `shifts.${currentIndex}`
    const { fields, append, remove } = useFieldArray({
        control,
        shouldUnregister: false,
        // name: fieldArrayNamePrefix,
        name: day,
    })

    useEffect(() => {
        if (props.data !== undefined) {
            append(props.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid item xs={12} className={classes.externalGrid}>
            <List>
                {fields.map((item, index) => {
                    return (
                        <div>
                            {item?.[day]?.[index]?.startTime &&
                                item?.[day]?.[index]?.endTime && (
                                    <Paper
                                        key={item.id}
                                        className={classes.button}
                                        elevation={0}
                                        variant={'outlined'}
                                    >
                                        <Box className={classes.fieldBox}>
                                            <input
                                                hidden={true}
                                                value={item.id}
                                                {...register(
                                                    `shifts.${currentIndex}.id`
                                                )}
                                            />
                                            <input
                                                hidden={true}
                                                value={item.nome}
                                                {...register(
                                                    `shifts.${currentIndex}.nome`
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`${fieldArrayNamePrefix}.${day}.${index}.startTime`}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <TextField
                                                        className={
                                                            classes.formLongField
                                                        }
                                                        label={t(
                                                            'inizio_turno'
                                                        )}
                                                        type={'time'}
                                                        defaultValue={
                                                            item[day][index]
                                                                ?.startTime
                                                        }
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={value}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`${fieldArrayNamePrefix}.${day}.${index}.endTime`}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <TextField
                                                        className={
                                                            classes.formLongField
                                                        }
                                                        label={t('fine_turno')}
                                                        type={'time'}
                                                        defaultValue={
                                                            item[day][index]
                                                                ?.endTime
                                                        }
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={value}
                                                    />
                                                )}
                                            />
                                            <IconButton
                                                onClick={() => remove(index)}
                                                aria-label="delete"
                                                color={'secondary'}
                                                className={classes.margin}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Paper>
                                )}
                        </div>
                    )
                })}
            </List>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Fab
                    onClick={() => append({})}
                    className={classes.addButton}
                    size={'small'}
                    color="primary"
                    aria-label="add"
                >
                    <AddIcon />
                </Fab>
            </Box>
        </Grid>
    )
}

export { FieldsArrayInsert, FieldsArrayEdit }
