import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FiAlertTriangle } from 'react-icons/fi'

import CustomAccordion from './CustomAccordion'

const useStyles = makeStyles(() => ({
    containerStyle: {
        margin: '20px 0 0',
        padding: '18px',
        width: '60%',
        minWidth: '380px',
        minHeight: '100px',
        border: '2px solid red',
        borderRadius: 12,
        backgroundColor: 'rgba(255,0,0,0.2)',
    },
}))

export default function MissingRequirementsWarning({ missingList, PUid }) {
    const { t } = useTranslation()
    const styles = useStyles()

    return (
        <Box className={styles.containerStyle} maxWidth="580px">
            <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box mb="6px">
                    <Box
                        display="flex"
                        width="270px"
                        justifyContent="space-between"
                        alignItems="center"
                        mb="6px"
                    >
                        <FiAlertTriangle
                            fontSize="20px"
                            color="rgba(255,0,0,0.91)"
                        />
                        <Typography variant="h3" style={{ fontWeight: '500' }}>
                            {t('missing_req_warning')}
                        </Typography>
                    </Box>

                    <Typography variant="h5">
                        {missingList[1]
                            ? t('missing_req_sub_2')
                            : t('missing_req_sub_1')}
                    </Typography>
                </Box>

                <CustomAccordion missingList={missingList} PUid={PUid} />
            </Box>
        </Box>
    )
}
