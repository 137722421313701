import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FETCH_AUDIT_LOG } from '../Components/ProductionUnit/GraphQL_queries'
import {
    CircularProgress,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core'
import ImportContactsTwoToneIcon from '@material-ui/icons/ImportContactsTwoTone'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { CustomDialog } from '../Components/Customized'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2),
    },
}))

const HeadRow = () => {
    return (
        <TableHead>
            <TableRow>
                {[
                    'Timestamp',
                    'Status Code',
                    'Requested Url',
                    'HTTP Method',
                    'Headers',
                    'Parameters',
                    'Payload',
                    'User Requested',
                ].map((item) => (
                    <TableCell key={item}>{item}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const FieldDetails = ({ item }) => {
    const { t } = useTranslation()
    return (
        <TableContainer style={{ padding: '8px' }}>
            <Table>
                <TableBody>
                    {Object.keys(item).length > 0 ? (
                        Object.keys(item).map((key) => (
                            <TableRow>
                                <TableCell width={'150px'}>
                                    <b>{key}</b>
                                </TableCell>
                                <TableCell>
                                    {typeof item[key] === 'object' &&
                                    Object.keys(item[key]).length === 0
                                        ? '{}'
                                        : JSON.stringify(item[key])}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <h3 style={{ padding: '8px' }}>
                            {t('empty_obj_warning')}
                        </h3>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const AuditLogScreen = () => {
    const styles = useStyles()

    const { t } = useTranslation()
    const [tableLoading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState('{}')
    const [totalLength, setTotalLength] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [currentPage, setCurrentPage] = useState(0)
    const [auditLogData, setAuditLogData] = useState([])
    const [showDetailsDialog, setShowDetailsDialog] = useState(false)

    const { fetchMore } = useQuery(FETCH_AUDIT_LOG, {
        variables: { perPage: 25, page: 0 },
        onCompleted: (data) => {
            setAuditLogData(data?.auditLog.logs)
            if (totalLength === 0) setTotalLength(data?.auditLog?.totalCount)
        },
    })

    const ShowDetailsButton = ({ handleShowDialog }) => {
        return (
            <IconButton
                onClick={() => handleShowDialog()}
                style={{ borderRadius: '0%' }}
            >
                <ImportContactsTwoToneIcon />
                <Typography
                    style={{ margin: '5px' }}
                    color={'textSecondary'}
                    variant={'caption'}
                >
                    {t('mostra_dettagli')}
                </Typography>
            </IconButton>
        )
    }

    const handleChangePage = async (event, newPage) => {
        setLoading(true)
        setCurrentPage(newPage)
        fetchMore({
            variables: {
                perPage: rowsPerPage,
                page: newPage,
            },
        }).then((data) => {
            setAuditLogData(data?.data?.auditLog?.logs)
            setLoading(false)
        })
    }

    const DateObject = (props) => {
        // eslint-disable-next-line no-unused-vars
        let [date, setDate] = useState(new Date(props.dateStr))

        return (
            <div>
                {`${date.toLocaleTimeString()} - ${date.toLocaleDateString()}`}
            </div>
        )
    }

    const handleChangeRowsPerPage = (event) => {
        setLoading(true)
        fetchMore({
            variables: {
                perPage: parseInt(event.target.value, 10),
                page: 0,
            },
        }).then((data) => {
            setAuditLogData(data?.data?.auditLog?.logs)
            setLoading(false)
        })
        setRowsPerPage(parseInt(event.target.value, 10))
        setCurrentPage(0)
    }

    useEffect(() => {}, [showDetailsDialog])

    return (
        <div>
            <h1>Audit Log</h1>
            <Typography color={'textSecondary'}>{t('http_log')}</Typography>
            <Paper className={styles.paper}>
                <TableContainer style={{ maxHeight: '600px' }}>
                    <Table stickyHeader>
                        <HeadRow />
                        <TableBody>
                            {!tableLoading && auditLogData.length > 0 ? (
                                auditLogData?.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <DateObject
                                                dateStr={item.timestamp}
                                            />
                                        </TableCell>
                                        <TableCell>{item.statusCode}</TableCell>
                                        <TableCell>
                                            {item.requestedUrl}
                                        </TableCell>
                                        <TableCell>{item.httpMethod}</TableCell>
                                        <TableCell>
                                            <ShowDetailsButton
                                                handleShowDialog={() => {
                                                    setSelectedItem(
                                                        item.headers
                                                    )
                                                    setShowDetailsDialog(true)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ShowDetailsButton
                                                handleShowDialog={() => {
                                                    setSelectedItem(
                                                        item.parameters
                                                    )
                                                    setShowDetailsDialog(true)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ShowDetailsButton
                                                handleShowDialog={() => {
                                                    setSelectedItem(
                                                        item.payload
                                                    )
                                                    setShowDetailsDialog(true)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {item.userRequested?.id}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={'8'}>
                                        <CircularProgress
                                            style={{
                                                display: 'flex',
                                                margin: 'auto',
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component={'div'}
                    count={totalLength}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <CustomDialog
                    fullWidth={true}
                    open={showDetailsDialog}
                    onClose={() => setShowDetailsDialog(false)}
                >
                    <DialogTitle>{t('dettagli_campo')}</DialogTitle>
                    <FieldDetails item={JSON.parse(selectedItem)} />
                </CustomDialog>
            </Paper>
        </div>
    )
}

export default AuditLogScreen
