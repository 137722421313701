import React from 'react'
import { Radar } from 'react-chartjs-2'
import { formatDecimal } from '../../utils'

export function countermeasuresToRadarDatatype(homogeneousGroups) {
    return {
        labels: homogeneousGroups.map((c) => c.name),
        datasets: [
            {
                data: homogeneousGroups.map((g) =>
                    formatDecimal(g.risk?.mitigatedRisk)
                ),
                borderColor: 'rgb(0,110,255)',
                label: 'Residuo',
                backgroundColor: 'rgb(0,110,255)',
            },
            {
                data: homogeneousGroups.map((g) =>
                    formatDecimal(g.risk?.totalRisk)
                ),
                borderColor: 'rgb(255,205,86)',
                label: 'Inerente',
                backgroundColor: 'rgb(255,205,86)',
            },
        ],
    }
}

export default function ({ data }) {
    return (
        <Radar
            data={data ? countermeasuresToRadarDatatype(data) : {}}
            // legend={null}
            options={{
                maintainAspectRatio: false,
                responsive: true,
                scale: {
                    ticks: {
                        beginAtZero: true,
                        max: 24,
                        min: 0,
                        stepSize: 6,
                    },

                    pointLabels: {
                        callback: function (label, index, labels) {
                            if (/\s/.test(label)) {
                                return label.split(' ')
                            } else {
                                return label
                            }
                        },
                    },
                },
            }}
        />
    )
}
