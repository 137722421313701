import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Paper } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: '18px',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    customShadow: {
        boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 7%), 0px 3px 4px 0px rgb(0 0 0 / 7%), 0px 1px 8px 0px rgb(0 0 0 / 7%) !important',
    },
}))

export default function CustomAccordion({ PUid, missingList }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(missingList?.[0]?.value)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Box className={classes.root}>
            {missingList.map((warning, idx) => (
                <Accordion
                    style={{ borderRadius: 8 }}
                    key={idx}
                    expanded={expanded === warning.value}
                    onChange={handleChange(warning.value)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Box
                            ml="34px"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography className={classes.heading}>
                                {t(warning.value)}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: '0px' }}>
                        <Box width="100%" display="flex" flexDirection="column">
                            {warning.hgs?.length > 0 ? (
                                <Typography>
                                    {t(warning.value + '_desc1')}
                                </Typography>
                            ) : (
                                <Typography>
                                    {t(warning.value + '_desc2')}
                                </Typography>
                            )}

                            {warning.hgs?.length > 0 ? (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <Paper
                                        elevation={3}
                                        style={{
                                            marginTop: '12px',
                                            borderRadius: 6,
                                        }}
                                        classes={{
                                            elevation3: classes.customShadow,
                                        }}
                                    >
                                        <Box
                                            padding="0px 12px 12px 0px"
                                            width="fit-content"
                                            maxWidth="100%"
                                        >
                                            {warning.hgs.map((hg, idx) => (
                                                <Box
                                                    key={idx}
                                                    display="inline-block"
                                                    mt="8px"
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        ml={'12px'}
                                                    >
                                                        <Typography>
                                                            {hg.name}
                                                        </Typography>
                                                        <Button
                                                            href={
                                                                '/homogeneousGroups/' +
                                                                hg.hgid
                                                            }
                                                            variant="outlined"
                                                            color="primary"
                                                            style={{
                                                                marginTop:
                                                                    '4px',
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                        >
                                                            {t('vai_gruppo')}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Paper>
                                </Box>
                            ) : (
                                <Box>
                                    <Button
                                        href={'/productionUnits/' + PUid.id}
                                        variant="outlined"
                                        color="primary"
                                        style={{
                                            marginTop: '10px',
                                            textTransform: 'none',
                                        }}
                                    >
                                        {t('vai_a_pu')}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}
