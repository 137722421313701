import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { HorizontalBar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import { percentageToGreenRedRange } from '../../utils'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    chartBox: {
        height: 120,
        padding: theme.spacing(1),
        maxWidth: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
    },
}))

export default function RiskClass({
    style,
    baseRisk = 0,
    k3= 0,
    criticalitiesKappas = null,
    aggravatedUnmitigatedRiskDelta = 0,
    aggravatedRiskDelta = 0,
    initialRisk = 0,
    ...rest
}) {
    const { t } = useTranslation()
    const graphRef = React.createRef()
    // eslint-disable-next-line no-unused-vars
    const [riskAddConst, setRiskAddConst] = useState(19.2)
    function setRef(ref) {
        graphRef.current = ref
    }

    const calcRiskClass = () => {
        if (0 < baseRisk && baseRisk < 4.8)
            return 1
        else if( 4.8 <= baseRisk && baseRisk < 9.6)
            return 2
        else if (9.6 <= baseRisk && baseRisk < 14.4)
            return 3
        else if (14.4 <= baseRisk && baseRisk < 19.2)
            return 4
        else if (baseRisk >= 19.2)
            return 5
    }

    const setColors = () => {
        let mappingRiskClass = {
            1: "rgba(35,238,35,0.85)",
            2: "rgba(125, 197, 59, 0.7)",
            3: "rgba(239,223,57,0.91)",
            4: "rgba(255,165,0,0.76)",
            5: "rgba(255,0,0,0.66)"
        }
        let riskClass = calcRiskClass()
        return mappingRiskClass[riskClass]
    }
    const hoverColors = () => {
        let mappingRiskClass = {
            1: "rgba(16,180,16,0.35)",
            2: "rgba(125,197,59,0.3)",
            3: "rgba(239,223,57,0.43)",
            4: "rgba(245,134,14,0.31)",
            5: "rgba(166,8,8,0.24)"
        }
        let riskClass = calcRiskClass()
        return mappingRiskClass[riskClass]
    }
    const setBorderColors = () => {
        let mappingRiskClass = {
            1: "rgba(16,180,16,0.89)",
            2: "rgba(125,197,59,0.96)",
            3: "rgba(239,223,57,0.83)",
            4: "rgba(245,134,14,0.95)",
            5: "rgba(166,8,8,0.88)"
        }
        let riskClass = calcRiskClass()
        return mappingRiskClass[riskClass]
    }
    const maxInitial = 24 * k3
    const classes = useStyle(rest)
    const percentBaseRisk = (baseRisk / maxInitial) * 100
    const percentReducedRisk = (aggravatedRiskDelta / maxInitial) * 100
    const percentInitialRisk = (initialRisk / maxInitial) * 100
    const colors = {
        border1: setBorderColors(),
        border2: setBorderColors(),
        base1: setColors(),
        base2: setColors(),
        hover1: hoverColors(),
        hover2: hoverColors(),
        borderInitial: setBorderColors(),
        baseInitial: setColors(),
        hoverInitial: hoverColors(),
    }

    React.useEffect(() => {
        console.log(baseRisk)
    }, [])

    const datasets = [
        {
            label: t('rischio'),
            data: [initialRisk, baseRisk],
            borderWidth: 1,
            borderColor: [colors.borderInitial, colors.border1],
            hoverBorderColor: [colors.borderInitial, colors.border1],
            backgroundColor: [colors.baseInitial, colors.base1],
            hoverBackgroundColor: [colors.hoverInitial, colors.hover1],
            stack: 'main',
            barPercentage: 0.6,
        },
    ]
    if (aggravatedRiskDelta) {
        datasets.push({
            label: t('criticita'),
            data: [aggravatedUnmitigatedRiskDelta, aggravatedRiskDelta],
            borderWidth: 1,
            borderColor: [colors.border2, colors.border2],
            hoverBorderColor: [colors.border2, colors.border2],
            backgroundColor: [colors.border2, colors.base2],
            hoverBackgroundColor: [colors.border2, colors.hover2],
            stack: 'main',
            barPercentage: 0.6,
        })
    }
    return (
        <Box className={classes.chartBox}>
            <HorizontalBar
                ref={setRef}
                data={{
                    labels: [t('Inerente'), t('Residuo')],
                    datasets,
                }}
                options={{
                    legend: null,
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        position: 'nearest',
                        xAlign: initialRisk > 10 ? 'right' : 'left',
                        yAlign: 'center',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let label =
                                    data.datasets[tooltipItem.datasetIndex]
                                        .label || ''
                                if (label) {
                                    label += ': '
                                }
                                if (tooltipItem.xLabel === 0) {
                                    label = null
                                } else {
                                    label +=
                                        Math.round(tooltipItem.xLabel * 100) /
                                        100
                                }
                                return label
                            },
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                                display: true,
                                ticks: {
                                    display: false,
                                    max: maxInitial + riskAddConst,
                                    min: 0,
                                    stepSize: maxInitial / 5,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                beginAtZero: true,
                            },
                        ],
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 5,
                        },
                    },
                }}
            />
        </Box>
    )
}
