import React, { useEffect, useState } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    marginBottom: {
        marginBottom: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    badge: {
        width: 30,
        height: 30,
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    toggle_selected: {
        color: 'white !important',
        backgroundColor: '#3f51b5 !important',
    },
}))

export default function QuestionComponent({
    register,
    control,
    q,
    index,
    questionnaire,
    ...props
}) {
    const { t } = useTranslation()
    const classes = useStyles(props)
    const [selectedItem, setSelectedItem] = useState('N/A')
    const onSelectChange = (event, value) => {
        setSelectedItem(value)
    }
    const [questionnaireCompiled, setQuestionnaireCompiled] = useState(false)

    useEffect(() => {
        if (questionnaire !== null) {
            setQuestionnaireCompiled(true)
            setSelectedItem(questionnaire.questions?.[index]?.status)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionnaire])

    return (
        <Grid
            container
            alignItems="center"
            spacing={3}
            className={classes.marginBottom}
            key={q}
        >
            <Grid
                item
                md={7}
                container
                spacing={2}
                wrap="nowrap"
                alignItems="center"
            >
                <Grid item>
                    <div className={classes.badge}>{index + 1}</div>
                </Grid>
                <Grid item>
                    <Typography align="left">{t(q)}</Typography>
                </Grid>
            </Grid>
            <Grid item md={2}>
                <ToggleButtonGroup
                    exclusive={true}
                    {...register(`questions.${index}.status`, {
                        value: selectedItem,
                    })}
                    onChange={onSelectChange}
                    value={selectedItem ? selectedItem : null}
                >
                    <ToggleButton
                        value={'true'}
                        color={'info'}
                        classes={{ selected: classes.toggle_selected }}
                        disabled={questionnaireCompiled}
                    >
                        {t('sì')}
                    </ToggleButton>
                    <ToggleButton
                        value={'false'}
                        classes={{ selected: classes.toggle_selected }}
                        disabled={questionnaireCompiled}
                    >
                        {t('no')}
                    </ToggleButton>
                    <ToggleButton
                        value={'N/A'}
                        classes={{ selected: classes.toggle_selected }}
                        disabled={questionnaireCompiled}
                    >
                        {t('N/A')}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item md={3}>
                <TextField
                    {...register(`questions.${index}.notes`)}
                    label={t('note')}
                    multiline
                    rows={2}
                    variant="outlined"
                    className={classes.fullWidth}
                    value={questionnaire?.questions?.[index]?.notes}
                    disabled={questionnaireCompiled}
                    InputLabelProps={{
                        shrink: questionnaire?.questions?.[index]?.notes,
                    }}
                />
                <input
                    hidden={true}
                    {...register(`questions.${index}.question`)}
                    value={q}
                />
            </Grid>
        </Grid>
    )
}
