import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useLoginStore } from '../globalState'

export default function OverviewDashboardScreen() {
    const history = useHistory()
    const isLoggedIn = useLoginStore((state) => state.isLoggedIn)

    useEffect(() => {
        if (isLoggedIn) history.push('/productionUnits')
        else history.push('/login')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <h1>Redirecting ...</h1>
}
