import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { Box } from '@material-ui/core'
import { useLoginStore } from '../../globalState'
import { ExitToAppRounded } from '@material-ui/icons'
import axios from 'axios'
import {
    DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL,
    client,
} from '../../index'

export default function UserAccountButton({ isHome }) {
    const [isLoggedIn, setLoggedOut] = useLoginStore((state) => [
        state.isLoggedIn,
        state.setLoggedOut,
    ])

    function logout() {
        client.resetStore()
        setLoggedOut()
        return axios.get(
            DO_NOT_EDIT_THIS_URL_IS_REPLACED_IN_BUILD_PHASE_SERVER_BASE_URL +
                '/logout'
        )
    }

    return isLoggedIn ? (
        <Box pl={isHome ? 0 : '48px'}>
            <IconButton onClick={logout} color={'inherit'}>
                <ExitToAppRounded />
            </IconButton>
        </Box>
    ) : null
}
