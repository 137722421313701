import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import { RT_QUERY } from '../Components/ProductionUnit/GraphQL_queries'
import { CircularProgress } from '@material-ui/core'
import DashboardValue from '../Components/DashboardValue'

export function TrackedIndexSummary({ regions }) {
    const { t } = useTranslation()
    const theme = useTheme()
    const [rts, setRts] = useState([])
    const { loading, data } = useQuery(RT_QUERY)

    useEffect(() => {
        if (data) {
            const rtsList = []
            const provinceJSON = JSON.parse(data?.getLastRt.data)
            regions.forEach((region) => {
                rtsList.push({
                    region,
                    rt: provinceJSON[region],
                })
            })
            setRts(rtsList)
        }
    }, [data, regions])

    return loading ? (
        <CircularProgress />
    ) : rts.length === 0 ? (
        <DashboardValue
            title={`${t('rt_non_disponibile')}`}
            subtitle={t('aggiungi_unita_produttiva')}
        />
    ) : (
        <div>
            <DashboardValue
                title={`${t('RT_giornaliero')}`}
                tooltipText={`${t(
                    'fonte_dati'
                )}: Ministero della Salute, INFN, Istituto Superiore di Sanità Cabina di Regia ai sensi del DM Salute 30 aprile 2020`}
            />
            <Box>
                {rts.map((rtObj, index) => (
                    // <pre>{JSON.stringify(rtObj)}</pre>
                    <Box key={index}>
                        <DashboardValue
                            subtitle={t(rtObj.region)}
                            value={rtObj.rt ? rtObj.rt.toFixed(2) : t('N/D')}
                            valueColor={theme.palette.status.neutral}
                        />
                    </Box>
                ))}
            </Box>
        </div>
    )
}
