import React from 'react'
import PageContainer from '../Components/Customized/PageContainer'
import PageTitle from '../Components/Customized/PageTitle'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import {
    GET_CONFIG,
    UPDATE_CONFIG,
} from '../Components/ProductionUnit/GraphQL_queries'
import { useMutation, useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state'
import Grow from '@material-ui/core/Grow'
import {
    Box,
    ClickAwayListener,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core'
import Popper from '@material-ui/core/Popper'
import { FiSettings } from 'react-icons/fi'
import { MdClear } from 'react-icons/md'

import InstructionsAccordion from '../Components/Archive/InstructionsAccordion'

export default function ArchiveOnlineScreen() {
    const { t } = useTranslation()
    const location = useLocation()
    const [sharedFolderId, setSharedFolderId] = React.useState('')

    useQuery(GET_CONFIG, {
        variables: { configKey: 'shared_folder_id' },
        onCompleted: (res) => {
            setSharedFolderId(res.config)
        },
    })

    const [updateID] = useMutation(UPDATE_CONFIG)

    const getFolderId = (url) => {
        if (url.includes('=')) {
            return url.split('=').at(-1)
        } else {
            return url.split('/').at(-1)
        }
    }

    const handleIDClear = (id) => {
        document.getElementById(id).value = ''
    }

    const handleIDSubmit = () => {
        const url = document.getElementById('idField').value

        const id = getFolderId(url)

        updateID({
            variables: {
                configData: { value: id },
                configKey: 'shared_folder_id',
            },
        }).then((res) => {
            setSharedFolderId(res.data.editConfig.config.value)
        })

        document.getElementById('settings-button').click()
    }

    const handleIDCreate = () => {
        const url = document.getElementById('firstIdField').value

        const id = getFolderId(url)

        updateID({
            variables: {
                configData: { value: id },
                configKey: 'shared_folder_id',
            },
        }).then((res) => {
            setSharedFolderId(res.data.editConfig.config.value)
        })
    }

    const SettingsPopover = () => {
        return (
            <Box position="absolute" right="4px">
                <PopupState variant="popper" popupId="settings-popper">
                    {(popupState) => (
                        <ClickAwayListener
                            onClickAway={() => {
                                if (popupState.isOpen) {
                                    popupState.toggle()
                                }
                            }}
                        >
                            <div>
                                <IconButton
                                    id="settings-button"
                                    {...bindToggle(popupState)}
                                >
                                    <FiSettings />
                                </IconButton>

                                <Popper
                                    popperOptions={{
                                        modifiers: {
                                            offset: {
                                                offset: '0, 20',
                                            },
                                        },
                                    }}
                                    placement="left"
                                    {...bindPopper(popupState)}
                                    transition
                                >
                                    {({ TransitionProps }) => (
                                        <Grow
                                            {...TransitionProps}
                                            timeout={350}
                                        >
                                            <Paper style={{ borderRadius: 10 }}>
                                                <Box
                                                    p={2}
                                                    width="fit-content"
                                                    height="120px"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                    >
                                                        <Box>
                                                            <Typography>
                                                                {t(
                                                                    'cartella_condivisa'
                                                                )}
                                                            </Typography>
                                                            <TextField
                                                                autoComplete="off"
                                                                defaultValue={
                                                                    sharedFolderId
                                                                }
                                                                id="idField"
                                                                style={{
                                                                    width: '380px',
                                                                }}
                                                                InputProps={{
                                                                    endAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        handleIDClear(
                                                                                            'idField'
                                                                                        )
                                                                                    }
                                                                                    style={{
                                                                                        height: '32px',
                                                                                        width: '32px',
                                                                                        padding:
                                                                                            '0',
                                                                                    }}
                                                                                    color="primary"
                                                                                >
                                                                                    <MdClear fontSize="20px" />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                        >
                                                            <Button
                                                                onClick={
                                                                    handleIDSubmit
                                                                }
                                                                style={{
                                                                    width: '30%',
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                                variant="contained"
                                                                color="primary"
                                                                size={'small'}
                                                                type={'submit'}
                                                            >
                                                                {t('salva')}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        </ClickAwayListener>
                    )}
                </PopupState>
            </Box>
        )
    }

    return (
        <PageContainer>
            {sharedFolderId !== null && sharedFolderId !== '' ? (
                <Box>
                    {location.pathname === '/archive_online' && (
                        <Box>
                            <SettingsPopover />
                            <PageTitle>{t('archive_online_screen')}</PageTitle>
                        </Box>
                    )}

                    <iframe
                        title="DropBox"
                        src={`https://app.box.com/embed/s/${sharedFolderId}?sortColumn=date&view=list`}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        allowFullScreen
                        webkitallowfullscreen
                        msallowfullscreen
                    ></iframe>
                </Box>
            ) : (
                <Box
                    w="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Paper
                        style={{
                            marginTop: '20px',
                            padding: '18px 20px 20px',
                            width: 'fit-content',
                            borderRadius: 10,
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Box>
                                <Box mb="20px">
                                    <Typography variant="h3">
                                        {t('no_config_warning')}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                >
                                    <Typography>
                                        {t('cartella_condivisa')}
                                    </Typography>
                                    <TextField
                                        autoComplete="off"
                                        defaultValue={sharedFolderId}
                                        id="firstIdField"
                                        style={{
                                            width: '480px',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment>
                                                    <IconButton
                                                        onClick={() =>
                                                            handleIDClear(
                                                                'firstIdField'
                                                            )
                                                        }
                                                        style={{
                                                            height: '32px',
                                                            width: '32px',
                                                            padding: '0',
                                                        }}
                                                        color="primary"
                                                    >
                                                        <MdClear fontSize="20px" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Button
                                onClick={handleIDCreate}
                                style={{
                                    marginTop: '10px',
                                }}
                                variant="contained"
                                color="primary"
                                size={'small'}
                                type={'submit'}
                            >
                                {t('salva')}
                            </Button>
                        </Box>
                    </Paper>
                    <Paper
                        style={{
                            marginTop: '20px',
                            padding: '18px 20px 20px',
                            width: '100%',
                            maxWidth: '520px',
                            borderRadius: 10,
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <InstructionsAccordion />
                        </Box>
                    </Paper>
                </Box>
            )}
        </PageContainer>
    )
}
