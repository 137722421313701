import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

import Divider from '@material-ui/core/Divider'
import { useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
    DELETE_SHIFT,
    GET_SHIFTS,
    GET_WORKER_BY_SHIFT,
} from '../Components/HomogeneousGroup/GraphQL_queries'
import { Fab, List, MenuItem, Select, Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { UPDATE_SHIFTS } from '../Components/ProductionUnit/GraphQL_queries'
import PageTitle from '../Components/Customized/PageTitle'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'
import PageContainer from '../Components/Customized/PageContainer'
import ShiftCreateForm from '../Components/HomogeneousGroup/ShiftCreateForm'
import { CustomDialog } from '../Components/Customized'
import { notifyFailure, notifySuccess } from '../utils'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import VisibilityIcon from '@material-ui/icons/Visibility'
import GroupIcon from '@material-ui/icons/Group'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import { Pagination } from '@material-ui/lab'
import { useConfirmDialog } from '../globalState'
import CloseButton from '../Components/CloseButton'
import { isMobile } from 'react-device-detect'
const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        marginTop: theme.spacing(3),
    },
    list: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '5px',
    },
    boxWidthDesktop: {
        width: '50%',
    },
    boxWidthMobile: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    accordionDetail: {
        '& .MuiAccordion-root.Mui-expanded': {
            marginBottom: '30px',
        },
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        // alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    button: {
        margin: theme.spacing(1),
        textAlign: 'left',
    },
    fab: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        margin: '20px',
        '&.MuiDivider-root': {
            background: '#6495ED',
        },
    },
}))

export default function ShiftsScreen() {
    const classes = useStyles()
    const { groupID } = useParams()
    // eslint-disable-next-line no-unused-vars
    const [updateShiftsMutation] = useMutation(UPDATE_SHIFTS)
    const { t } = useTranslation()
    const [isShiftFormOpen, setShiftFormOpen] = React.useState(false)
    const [isEditFormOpen, setEditFormOpen] = React.useState(false)
    const [shiftToVisualize, setShiftToVisualize] = useState({})
    const [openRelatedWorkers, setOpenRelatedWorkers] = useState(false)
    const [totalLength, setTotalLength] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [workers, setWorkers] = useState([])
    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])
    const [itemsPerPage, setItemsPerPage] = useState(25)
    const { data, refetch, fetchMore } = useQuery(GET_SHIFTS, {
        fetchPolicy: 'network-only',
        variables: {
            homogeneousGroupId: groupID,
            page: currentPage,
            perPage: itemsPerPage,
        },
    })
    const [getWorkerByShift] = useLazyQuery(GET_WORKER_BY_SHIFT, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setWorkers(data?.workersByShift)
        },
    })
    const [deleteShift] = useMutation(DELETE_SHIFT)

    useEffect(() => {
        if (data?.shifts) {
            setTotalLength(data?.shifts?.totalCount)
        }
    }, [data])

    const createNewShift = () => {
        setShiftFormOpen(true)
    }

    const closeInsertForm = () => {
        setShiftFormOpen(false)
    }
    const closeEditForm = () => {
        setEditFormOpen(false)
    }

    async function handlePageChange(e, page) {
        await fetchMore({
            variables: {
                homogeneousGroupId: groupID,
                page: page,
                itemsPerPage: itemsPerPage,
            },
        })
        setCurrentPage(page)
    }
    function checkShiftName(nome) {
        if (nome) {
            let lowerCaseName = nome.toLowerCase()
            if (lowerCaseName.includes('turno')) return true
            else if (lowerCaseName.includes('shift')) return true
            else return false
        }
    }
    async function removeShift(id) {
        openConfirm({
            title: t('elimina_turno_titolo'),
            description: t('elimina_turno_descrizione'),
            onConfirm: () =>
                deleteShift({ variables: { shiftId: id } })
                    .then(async () => {
                        notifySuccess(t('elimina_turno_successo'))
                        if (totalLength > 0)
                            if ((totalLength - 1) % 5 === 0)
                                if (currentPage !== 1) {
                                    await handlePageChange(
                                        null,
                                        currentPage - 1
                                    )
                                }
                        setTotalLength((prevState) => prevState - 1)
                        await refetch()
                    })
                    .catch(notifyFailure),
        })
    }

    const afterSaveShift = async () => {
        await refetch()

        if (data?.shifts?.shifts?.totalCount > 0) {
            if (data?.shifts?.shifts?.totalCount % itemsPerPage > 0) {
                handlePageChange(
                    null,
                    parseInt(data?.shifts?.shifts?.totalCount / itemsPerPage) +
                        1
                ).then(() => Promise.resolve())
            } else {
                handlePageChange(
                    null,
                    parseInt(data?.shifts?.shifts?.totalCount / itemsPerPage)
                ).then(() => Promise.resolve())
            }
        }
        closeInsertForm()
    }

    const afterEditShift = async () => {
        await refetch()
    }

    return (
        <PageContainer>
            <PageTitle>{t('gestione_turni')}</PageTitle>
            <Fab
                className={classes.fab}
                color="primary"
                aria-label="add"
                onClick={() => createNewShift()}
            >
                <AddIcon />
            </Fab>
            {data && data?.shifts?.shifts.length > 0 ? (
                <Box
                    boxShadow={2}
                    classes={{ root: classes.root }}
                    className={
                        isMobile
                            ? classes.boxWidthMobile
                            : classes.boxWidthDesktop
                    }
                >
                    <List className={classes.list}>
                        {data?.shifts?.shifts?.map((shift, index) => (
                            <div>
                                <ListItem divider>
                                    <ListItemIcon>
                                        <Tooltip title={t('visualizza_turno')}>
                                            <IconButton
                                                onClick={() => {
                                                    setShiftToVisualize(shift)
                                                    setEditFormOpen(true)
                                                }}
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemIcon>
                                        <Tooltip
                                            title={t('lavoratori_correlati')}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    getWorkerByShift({
                                                        variables: {
                                                            shiftId: shift?.id,
                                                        },
                                                    })
                                                    setShiftToVisualize(shift)
                                                    setOpenRelatedWorkers(true)
                                                }}
                                            >
                                                <GroupIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant={'body2'}>
                                            {shift?.nome}
                                        </Typography>
                                    </ListItemText>
                                    <IconButton
                                        aria-label="delete"
                                        color="secondary"
                                        onClick={() => removeShift(shift.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItem>
                                <Divider />
                            </div>
                        ))}
                        {/*<Grid container spacing={1}>*/}
                        {/*    <Grid item sm={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>*/}
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            {data?.shifts?.totalCount === 0 ? null : (
                                <Box
                                    style={{
                                        maxWidth: '75%',
                                        flex: '0 1 auto',
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                >
                                    <Pagination
                                        count={
                                            totalLength > itemsPerPage
                                                ? totalLength % itemsPerPage > 0
                                                    ? Math.floor(
                                                          totalLength /
                                                              itemsPerPage
                                                      ) + 1
                                                    : totalLength / itemsPerPage
                                                : 1
                                        }
                                        color={'primary'}
                                        onChange={handlePageChange}
                                        style={{ margin: '8px' }}
                                        page={currentPage}
                                    />
                                </Box>
                            )}

                            {/*</Grid>*/}
                            {/*<Grid item sm={4} style={{ margin: 'auto' }}>*/}
                            <Box
                                style={{
                                    flex: '0 1 auto',
                                    marginLeft: 'auto',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant={'body1'}
                                    color={'textSecondary'}
                                >
                                    {t('oggetti') + ': '}
                                </Typography>
                                <Select
                                    value={itemsPerPage}
                                    style={{ margin: '8px' }}
                                    onChange={(e) => {
                                        setItemsPerPage(e.target.value)
                                        setCurrentPage(1)
                                        fetchMore({
                                            variables: {
                                                homogeneousGroupId: groupID,
                                                page: currentPage,
                                                itemsPerPage: itemsPerPage,
                                            },
                                        }).then(() => Promise.resolve())
                                    }}
                                >
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                        {/*</Grid>*/}
                        {/*</Grid>*/}
                    </List>
                </Box>
            ) : (
                <Typography variant={'body1'} color={'textSecondary'}>
                    {t('no_shift_warning')}
                </Typography>
            )}
            <CustomDialog
                open={isEditFormOpen}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <CloseButton
                    title={t('modifica_turno')}
                    onClose={() => setEditFormOpen(false)}
                />
                <ShiftCreateForm
                    afterSubmit={() => afterEditShift()}
                    onClose={closeEditForm}
                    groupId={groupID}
                    shiftData={shiftToVisualize}
                />
            </CustomDialog>
            <CustomDialog
                open={isShiftFormOpen}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <CloseButton
                    title={t('inserisci_turno')}
                    onClose={() => closeInsertForm()}
                />
                <ShiftCreateForm
                    afterSubmit={() => afterSaveShift()}
                    onClose={() => closeInsertForm()}
                    groupId={groupID}
                />
            </CustomDialog>
            <CustomDialog
                open={openRelatedWorkers}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <Box display={'flex'} flexDirection={'column'} fullWidth={true}>
                    <Box fullWidth={true}>
                        <CloseButton
                            title={
                                checkShiftName(shiftToVisualize?.nome)
                                    ? t('lavoratori_turno') +
                                      shiftToVisualize?.nome
                                    : t('lavoratori_turno1') +
                                      shiftToVisualize?.nome
                            }
                            onClose={() => setOpenRelatedWorkers(false)}
                        />
                    </Box>
                    <Box width={'300px'}>
                        <List>
                            {workers.length > 0 ? (
                                workers.map((worker, index) => (
                                    <Box>
                                        <ListItem>
                                            <ListItemText>
                                                {`${index + 1}.` +
                                                    worker?.nome +
                                                    ' ' +
                                                    worker?.cognome}
                                            </ListItemText>
                                        </ListItem>
                                        <Divider
                                            variant={'middle'}
                                            light={false}
                                        />
                                    </Box>
                                ))
                            ) : (
                                <Box padding={'16px'}>
                                    <Typography variant={'body1'}>
                                        {t('no_lavoratori')}
                                    </Typography>
                                </Box>
                            )}
                        </List>
                    </Box>
                </Box>
            </CustomDialog>
        </PageContainer>
    )
}
