import Box from '@material-ui/core/Box'
import React from 'react'
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router'
import {
    PU_SCENARIO,
    UPDATE_SCENARIO,
} from '../Components/ProductionUnit/GraphQL_queries'

import { colorsList, headerColorPalette, inactiveColors } from '../colors'
import CustomizedTableCell from '../Components/Scenarios/CustomizedTableCell'
import MissingRequirementsWarning from '../Components/Scenarios/MissingRequirementsWarning'

const useStyle = makeStyles(() => ({
    bottomBorder: {
        borderBottom: '1px solid black',
    },
    rightBottomBorder: {
        borderBottom: '1px solid black',
        borderRight: '1px solid black',
    },
    labelTable: {
        width: '150px',
        paddingRight: 0,
    },
    tableStyle: {
        border: '1px solid black',
        width: '210px',
        height: '130px',
        position: 'relative',
    },
    defaultNoEdit: {
        padding: 0,
    },
}))

export default function ScenarioScreen() {
    const { t } = useTranslation()
    const styles = useStyle({})

    const defaultTableData = {
        currentScenario: {
            row: null,
            col: null,
            valuesIndex: null,
            missingFields: [],
        },
        tables: [],
        productionUnit: null,
    }

    const [tableContent, setTableContent] = React.useState(defaultTableData)

    const { PUid } = useParams()

    useQuery(PU_SCENARIO, {
        fetchPolicy: 'network-only',
        variables: {
            productionUnitId: PUid,
        },
        onCompleted: (res) => {
            setTableContent(res.scenarios[0])
            window.scrollTo(0, 0)
        },
    })

    const [updateScenario] = useMutation(UPDATE_SCENARIO)

    const [areRequirementsMissing, setAreRequirementsMissing] =
        React.useState(false)

    React.useEffect(() => {
        if (tableContent.currentScenario.missingFields.length > 0) {
            setAreRequirementsMissing(true)
        } else setAreRequirementsMissing(false)
    }, [tableContent])

    const submitPackage = (pckg) => {
        updateScenario({
            variables: {
                scenarioData: pckg,
            },
        })
    }

    // -------------- Table Std Content --------------

    const alertList = ['Pre Alert', 'Alert', 'Pre-alarm', 'Alarm', 'Emergency']

    const classesList = [
        t('classe_1'),
        t('classe_2'),
        t('classe_3'),
        t('classe_4'),
        t('classe_5'),
    ]

    const tableLayout = [
        { value: [0, 1, 2, 3, 4] },
        { value: [0, 1, 2, 3, 4] },
        { value: [0, 1, 2, 3, 4] },
        { value: [0, 1, 2, 3, 4] },
        { value: [0, 1, 2, 3, 4] },
    ]

    return (
        <Box
            position="relative"
            width={'100%'}
            display="flex"
            justifyContent="center"
            mb="40px"
        >
            <Box mt="40px">
                <Box mb="20px" w="100%" display="flex" justifyContent="center">
                    {areRequirementsMissing && (
                        <MissingRequirementsWarning
                            missingList={
                                tableContent.currentScenario.missingFields
                            }
                            PUid={tableContent.productionUnit}
                        />
                    )}
                </Box>

                <Card
                    style={{
                        padding: '20px',
                        borderRadius: '20px',
                        backgroundColor: '#FCFCFC',
                    }}
                >
                    <form>
                        <Table>
                            <TableHead>
                                <TableCell
                                    style={{ fontWeight: 'bold' }}
                                    className={styles.rightBottomBorder}
                                >
                                    {t('allerta_classe')}
                                </TableCell>
                                {classesList.map((classLabel, idx) => (
                                    <TableCell
                                        key={idx}
                                        className={styles.rightBottomBorder}
                                        style={{
                                            backgroundImage:
                                                'linear-gradient(#F6F6F6, ' +
                                                headerColorPalette[idx] +
                                                ')',
                                        }}
                                    >
                                        <Box
                                            width="100%"
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Box fontWeight="bold">
                                                {classLabel}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableHead>

                            <TableBody>
                                {alertList.map((alert, alertIndex) => (
                                    <TableRow key={alertIndex}>
                                        <TableCell
                                            classes={{
                                                root: styles.labelTable,
                                            }}
                                            style={{
                                                backgroundImage:
                                                    'linear-gradient(90deg, #F6F6F6, ' +
                                                    headerColorPalette[
                                                        alertIndex
                                                    ] +
                                                    ')',
                                            }}
                                            className={styles.bottomBorder}
                                        >
                                            <Box
                                                fontWeight="bold"
                                                display="flex"
                                                flexDirection="row-reverse"
                                                mr="8px"
                                            >
                                                {alert}
                                            </Box>
                                        </TableCell>

                                        {tableLayout[alertIndex].value.map(
                                            (cell, defIndex) => {
                                                let content
                                                if (
                                                    tableContent.productionUnit ===
                                                        'none' ||
                                                    areRequirementsMissing
                                                ) {
                                                    content = (
                                                        <TableCell
                                                            style={{
                                                                background: `repeating-linear-gradient(56deg,#F6F6f6,#F6F6F6 20px,${inactiveColors[alertIndex][defIndex]} 20px,${inactiveColors[alertIndex][defIndex]} 40px)`,
                                                                height: '180px',
                                                                width: '240px',
                                                            }}
                                                            classes={{
                                                                body: styles.tableStyle,
                                                            }}
                                                            className={
                                                                styles.defaultNoEdit
                                                            }
                                                        ></TableCell>
                                                    )
                                                } else {
                                                    content = (
                                                        <CustomizedTableCell
                                                            key={defIndex}
                                                            yIndex={alertIndex}
                                                            xIndex={cell}
                                                            tables={
                                                                tableContent.tables
                                                            }
                                                            scenario={
                                                                tableContent.currentScenario
                                                            }
                                                            color={
                                                                colorsList[
                                                                    alertIndex
                                                                ][cell]
                                                            }
                                                            tableContent={
                                                                tableContent
                                                            }
                                                            setTableContent={
                                                                setTableContent
                                                            }
                                                            PUid={
                                                                tableContent.productionUnit
                                                            }
                                                            submitPackage={
                                                                submitPackage
                                                            }
                                                        />
                                                    )
                                                }
                                                return content
                                            }
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </form>
                </Card>
            </Box>
        </Box>
    )
}
