import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button, TextField } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import PageContainer from '../Components/Customized/PageContainer'
import PageTitle from '../Components/Customized/PageTitle'
import {
    notifyFailure,
    notifySuccess,
    questionnaireQuestions,
    userQuestionnaireQuestions,
} from '../utils'
import QuestionComponent from '../Components/QuestionComponent'
import { useMutation, useQuery } from '@apollo/client'
import {
    GET_QUESTIONNAIRE,
    INSERT_QUESTIONNAIRE,
} from '../Components/HomogeneousGroup/GraphQL_queries'
import { useHistory, useParams } from 'react-router-dom'
import Moment from 'react-moment'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    marginBottom: {
        marginBottom: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    badge: {
        width: 30,
        height: 30,
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}))

export default function QuestionnaireScreen() {
    const location = useLocation()
    const classes = useStyles()
    const [questions, setQuestions] = useState(
        !location.pathname.includes('/userQuestionnaire')
            ? questionnaireQuestions
            : userQuestionnaireQuestions
    )
    const [saved, setSaved] = useState(false)
    const type = !location.pathname.includes('/userQuestionnaire')
        ? 'company'
        : 'user'
    const { t } = useTranslation()
    const [insertQuestionnaire] = useMutation(INSERT_QUESTIONNAIRE)
    const { register, handleSubmit, control } = useForm()
    const appHistory = useHistory()
    const id = useParams()
    const { data } = useQuery(GET_QUESTIONNAIRE, {
        onCompleted: (data) => {
            if (data?.questionnaire?.questionnaireType === 'company')
                setQuestions(questionnaireQuestions)
            else if (data?.questionnaire?.questionnaireType === 'user')
                setQuestions(userQuestionnaireQuestions)
        },
        variables: {
            questionnaireId: id?.id,
        },
    })

    function sendFormData(formData) {
        formData = { ...formData, questionnaireType: type }
        insertQuestionnaire({
            variables: {
                questionnaireData: formData,
            },
        })
            .then(() => {
                setSaved(true)
                notifySuccess(t('questionario_salvato'))
                if (!location.pathname.includes('/userQuestionnaire'))
                    appHistory.push('/allQuestionnaire')
            })
            .catch(() => notifyFailure(t('questionario_errore_salvataggio')))
    }

    return (
        <PageContainer>
            {id?.id === undefined ? (
                <PageTitle>
                    {t('questionario_qualificazione_azienda')}
                </PageTitle>
            ) : (
                <PageTitle>
                    {`${t('questionario_compilato')} `}
                    <Moment format="DD-MM-YYYY HH:mm">
                        {`${data?.questionnaire?.timestamp}`}
                    </Moment>
                </PageTitle>
            )}
            {!saved ? (
                <form onSubmit={handleSubmit(sendFormData)}>
                    <Grid container direction="column" alignItems="flex-start">
                        {questions.map((q, index) => (
                            <QuestionComponent
                                register={register}
                                q={q}
                                index={index}
                                control={control}
                                questionnaire={
                                    data ? data?.questionnaire : null
                                }
                            />
                        ))}
                        <TextField
                            {...register('notes')}
                            label={
                                data?.questionnaire ? null : t('annotazioni')
                            }
                            multiline
                            rows={3}
                            variant="outlined"
                            value={
                                data?.questionnaire &&
                                data?.questionnaire?.notes
                            }
                            className={`${classes.marginBottom} ${classes.fullWidth}`}
                            style={{ width: '100%' }}
                            disabled={data?.questionnaire ? true : false}
                        />

                        {id?.id === undefined ? (
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                startIcon={<SaveIcon />}
                            >
                                {t('salva')}
                            </Button>
                        ) : null}
                    </Grid>
                </form>
            ) : (
                location.pathname.includes('/userQuestionnaire') &&
                saved && (
                    <Typography variant={'h6'}>
                        Grazie per aver compilato il questionario!
                    </Typography>
                )
            )}
        </PageContainer>
    )
}
