import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GET_JOB_HISTORY } from '../Components/ProductionUnit/GraphQL_queries'
import {
    Button,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core'
import { CustomDialog } from '../Components/Customized'
import { Avatar } from '@material-ui/core'
import { FaRegCheckCircle } from 'react-icons/fa'
import { RiCloseCircleLine } from 'react-icons/ri'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(() => ({
    avatarSuccessStyle: {
        backgroundColor: '#4AAF50',
        height: '26px',
        width: '26px',
    },
    avatarFailureStyle: {
        backgroundColor: '#F44336',
        height: '26px',
        width: '26px',
    },
    buttonStyle: {
        marginLeft: '10px',
        borderRadius: 8,
        fontSize: '14px',
        textTransform: 'none',
        backgroundColor: '#F8F8F8',
        boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 4%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%) !important',
    },
}))

const StatusDetail = ({ jobStatus }) => {
    const { t } = useTranslation()
    return (
        <TableContainer style={{ padding: '8px' }}>
            <Table>
                <TableBody>
                    {Object.keys(jobStatus).length > 0 ? (
                        Object.keys(jobStatus).map((key) => (
                            <TableRow>
                                <TableCell width={'150px'}>
                                    <b>{key}</b>
                                </TableCell>
                                <TableCell>
                                    {key === 'success'
                                        ? jobStatus[key].toString()
                                        : jobStatus[key]}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <h3 style={{ padding: '8px' }}>
                            {t('empty_obj_warning')}
                        </h3>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default function JobHistoryScreen() {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    const [perPage, setPerPage] = useState(25)
    const [jobHistory, setJobHistory] = useState([])
    const [selectedHistoryStatus, setSelectedHistoryStatus] = useState({})
    const [openDetails, setOpenDetails] = useState(false)
    const { loading, fetchMore } = useQuery(GET_JOB_HISTORY, {
        variables: {
            page: page,
            perPage: perPage,
        },
        onCompleted: (res) => {
            setJobHistory(res?.jobHistory)
        },
    })

    const styles = useStyle()

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
        fetchMore({
            variables: {
                perPage: perPage,
                page: newPage,
            },
        }).then((data) => {
            setJobHistory(data?.data?.jobHistory)
        })
    }

    const handleChangeRowsPerPage = async (event) => {
        fetchMore({
            variables: {
                perPage: parseInt(event.target.value, 10),
                page: 1,
            },
        }).then((data) => {
            setJobHistory(data?.data?.jobHistory)
        })
        setPerPage(parseInt(event.target.value, 10))
        setPage(1)
    }

    const DateObject = (props) => {
        // eslint-disable-next-line no-unused-vars
        let [date, setDate] = useState(new Date(props.dateStr))

        return (
            <div>
                {`${date.toLocaleTimeString()} - ${date.toLocaleDateString()}`}
            </div>
        )
    }

    return (
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
            >
                <h1>Job History</h1>
                <TableContainer>
                    <Table stickyHeader={true}>
                        <TableHead>
                            <TableRow>
                                {['Job name', 'Job staus', 'timestamp'].map(
                                    (key) => (
                                        <TableCell key={key} width={'242px'}>
                                            {key}
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading &&
                                jobHistory.length > 0 &&
                                jobHistory.map((history_instance, idx) => (
                                    <TableRow key={history_instance.id}>
                                        <TableCell>
                                            {history_instance.jobName}
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                {history_instance.jobStatus
                                                    .success ? (
                                                    <Avatar
                                                        classes={{
                                                            root: styles.avatarSuccessStyle,
                                                        }}
                                                    >
                                                        <FaRegCheckCircle />
                                                    </Avatar>
                                                ) : (
                                                    <Avatar
                                                        classes={{
                                                            root: styles.avatarFailureStyle,
                                                        }}
                                                    >
                                                        <RiCloseCircleLine fontSize="24px" />
                                                    </Avatar>
                                                )}

                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        setSelectedHistoryStatus(
                                                            history_instance.jobStatus
                                                        )
                                                        setOpenDetails(true)
                                                    }}
                                                    classes={{
                                                        root: styles.buttonStyle,
                                                    }}
                                                >
                                                    {t('mostra_dettagli')}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <DateObject
                                                dateStr={
                                                    history_instance.timestamp
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component={'div'}
                    count={jobHistory?.length}
                    rowsPerPage={perPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <CustomDialog
                    open={openDetails}
                    onClose={() => setOpenDetails(false)}
                >
                    <DialogTitle>{t('dettagli_campo')}</DialogTitle>
                    <StatusDetail jobStatus={selectedHistoryStatus} />
                </CustomDialog>
            </Box>
        </Box>
    )
}
