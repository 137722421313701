/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pie, Line } from 'react-chartjs-2'
import _ from 'lodash'
import { Typography, Box, Paper } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import hash from 'object-hash'
import { TrackedIndexSummary } from '../Components/TrackedIndexSummary'
import GrowthIndicator from '../Components/Customized/GrowthIndicator'
import DashboardValue from '../Components/DashboardValue'
import {
    employeesStatsToAreaChartData,
    employeesStatsToPieChartData,
    calcInfectionsTrend,
    getRegionsByProvinces,
} from '../utils'
import { REFRESH_STATS } from '../Components/ProductionUnit/GraphQL_queries'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    paperStyle: {
        borderRadius: 8,
    },
    verticalPaperStyle: {},
    refreshButton: {
        margin: theme.spacing(1),
        borderRadius: '0%',
    },
    verticalRule: {
        borderLeft: '1px solid rgba(0,0,0,0.15)',
    },
    horizontalRule: {
        paddingTop: '10px',
        width: '50%',
        borderTop: '1px solid rgba(0,0,0,0.15)',
    },
    bigHorizontalRule: {
        borderTop: '1px solid rgba(0,0,0,0.15)',
    },
}))

export default function DashboardActions({
    provinces,
    children,
    detailsData,
    secondBreakpoint,
}) {
    const theme = useTheme()
    const { t } = useTranslation()
    const { unitID } = useParams()
    const styles = useStyles()
    const [data, setData] = useState(detailsData)
    const [totalEmployees, setTotalEmployees] = useState(0)
    const [partialEmployees, setPartialEmployees] = useState(0)

    useLazyQuery(REFRESH_STATS, {
        variables: { productionUnitId: unitID },
        fetchPolicy: 'network-only',
        onCompleted: (result) => {
            setData({ ...data, ...{ workerStats: result?.updateWorkerStats } })
        },
    })

    useEffect(() => {
        let employees = 0
        data.homogeneousGroups.map(
            (group) => (employees += group.workers.length)
        )
        setPartialEmployees(employees)

        let totalEmployees = data.employeesCount
        setTotalEmployees(totalEmployees)
    }, [])

    const statusPieData = React.useMemo(() => {
        return !_.isNull(data?.workerStats) && data && data?.length !== 0
            ? employeesStatsToPieChartData(data?.workerStats)
            : {}
    }, [hash({ data })])

    const trendAreaData = React.useMemo(() => {
        return !_.isNull(data?.workerStats) && data?.length !== 0
            ? employeesStatsToAreaChartData(data?.workerStats)
            : {}
    }, [hash({ data })])

    const regions = React.useMemo(() => {
        return provinces?.length !== 0 ? getRegionsByProvinces(provinces) : []
    }, [hash({ provinces })])

    // eslint-disable-next-line no-unused-vars
    const vaccinatedPercentage = React.useMemo(() => {
        return data?.length !== 0 ? `${data?.vaccinati}%` : t('N/D')
    }, [hash({ data })])

    const infectionsTrend = React.useMemo(() => {
        return !_.isNull(data?.workerStats) && data?.length !== 0
            ? calcInfectionsTrend(data?.workerStats)
            : t('N/D')
    }, [hash({ data })])

    return (
        <Box width="100%" maxWidth="1280px" pt="40px">
            <Paper
                className={secondBreakpoint && styles.verticalPaperStyle}
                classes={{ root: styles.paperStyle }}
            >
                <Box p="30px 0">
                    <Box
                        display="flex"
                        flexDirection={secondBreakpoint ? 'column' : 'row'}
                        alignItems="center"
                        justifyContent="space-around"
                        marginBottom="20px"
                    >
                        <Box
                            flex="1"
                            style={
                                secondBreakpoint
                                    ? { paddingBottom: '10px' }
                                    : { visibility: 'visible' }
                            }
                        >
                            <TrackedIndexSummary regions={regions} />
                        </Box>
                        <Box
                            flex="1"
                            className={
                                secondBreakpoint
                                    ? styles.horizontalRule
                                    : styles.verticalRule
                            }
                        >
                            {/* TODO: use real data */}
                            <GrowthIndicator
                                label="trend_contagi"
                                trend={infectionsTrend}
                            />
                        </Box>
                        <Box
                            flex="1"
                            className={
                                secondBreakpoint
                                    ? styles.horizontalRule
                                    : styles.verticalRule
                            }
                        >
                            <DashboardValue
                                title={t('personale_vaccinato')}
                                subtitle={t('ad_oggi')}
                                value={
                                    data?.workerStats[
                                        data?.workerStats.length - 1
                                    ] &&
                                    data?.workerStats[
                                        data?.workerStats.length - 1
                                    ]?.vaccinati
                                        ? data?.workerStats[
                                              data?.workerStats.length - 1
                                          ]?.vaccinati?.toFixed(0) + '%'
                                        : t('N/D')
                                }
                                valueColor={theme.palette.status.neutral}
                            />
                        </Box>
                    </Box>

                    {data?.workerStats &&
                    _.some(
                        _.omit(
                            data.workerStats[data?.workerStats.length - 1],
                            'timestamp'
                        ),
                        (val) => val !== null && val !== 0
                    ) ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={secondBreakpoint ? 'column' : 'row'}
                            width="100%"
                        >
                            {secondBreakpoint && (
                                <Box
                                    width="70%"
                                    className={styles.bigHorizontalRule}
                                ></Box>
                            )}
                            <Box
                                flex="1"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                pt="20px"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {t('composizione_lavoratori')}
                                </Typography>
                                <Box width="300px" pb="20px">
                                    <Box height="300px">
                                        <Pie
                                            data={statusPieData}
                                            options={{
                                                maintainAspectRatio: false,
                                                responsive: true,
                                            }}
                                        />
                                    </Box>
                                    <Box mt="10px">
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {t('pie_context_p1')}
                                            <b>{partialEmployees}</b>
                                            {t('pie_context_p2')}
                                            <b>{totalEmployees}</b>
                                            {t('pie_context_p3')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {secondBreakpoint && (
                                <Box
                                    width="70%"
                                    className={styles.bigHorizontalRule}
                                ></Box>
                            )}
                            <Box
                                flex="1"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                pt="20px"
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {t('composizione_personale')}
                                </Typography>
                                <Box width={400} height={270}>
                                    <Line
                                        data={trendAreaData}
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            scales: {
                                                yAxes: [
                                                    {
                                                        stacked: true,
                                                    },
                                                ],
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Typography
                            style={{ margin: 'auto' }}
                            variant={'body1'}
                            color={'textSecondary'}
                        >
                            {t('insuff_data_warning')}
                        </Typography>
                    )}
                    {children}
                </Box>
            </Paper>
        </Box>
    )
}
