import React from 'react'
import { Tooltip } from '@material-ui/core'
import { Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    tooltip: {
        fontSize: '14px',
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.8)',
    },
    tooltipArrow: {
        color: 'rgba(0,0,0,0.8)',
    },
}))

export default function CustomTooltip({ title, placement, ...props }) {
    const classes = useStyles()

    return (
        <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
            title={title}
            arrow={true}
            placement={placement}
            classes={{
                tooltipArrow: classes.tooltip,
                arrow: classes.tooltipArrow,
            }}
        >
            {props.children}
        </Tooltip>
    )
}
