import React from 'react'
import { AppBar, Toolbar, Typography, IconButton, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import BackButton from './BackButton'
import UserAccountButton from './UserAccountButton'
import { useLoginStore } from '../../globalState'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

export default function Topbar({ setIsSidebarOpen }) {
    // eslint-disable-next-line no-unused-vars
    const [isLoggedIn, setLoggedOut] = useLoginStore((state) => [
        state.isLoggedIn,
        state.setLoggedOut,
    ])

    const location = useLocation()

    const getExceptions = () => {
        return (
            location.pathname.includes('/userQuestionnaire') ||
            location.pathname.includes('/archive_online_window')
        )
    }

    const includes = getExceptions()
    const isHome = location.pathname === '/productionUnits'

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    {isLoggedIn && !includes && (
                        <IconButton
                            color="inherit"
                            onClick={() => setIsSidebarOpen(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {!includes && !isHome && (
                        <Box>
                            <BackButton />
                        </Box>
                    )}
                    <Box width="100%">
                        <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
                            {!includes ? (
                                <Link
                                    to="/productionUnits"
                                    style={{
                                        color: 'unset',
                                        textDecoration: 'none',
                                    }}
                                >
                                    P-VIEW
                                </Link>
                            ) : (
                                'P-VIEW'
                            )}
                        </Typography>
                    </Box>
                    {!includes && <UserAccountButton isHome={isHome} />}
                </Toolbar>
            </AppBar>
            {/* second toolbar needed because the dimension of the appbar doesn't impact the rest of the page
            https://material-ui.com/components/app-bar/#fixed-placement */}
            <Toolbar style={{ padding: 0 }} />
        </>
    )
}
