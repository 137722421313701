import React, { Fragment } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import CrudGrid from '../CrudGrid'
import CardHG from './CardHG'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    card: {
        width: 250,
    },
}))

export default function ListHG({
    list = [],
    onItemClick,
    onDelete,
    onEdit,
    insertedEmployees,
    ...rest
}) {
    const { t } = useTranslation()
    const classes = useStyle(rest)
    const appHistory = useHistory()
    const [anchorEl, setAnchorEl] = React.useState({
        domElement: null,
        data: null,
    })

    const handleMoreClick = (event, data) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl({ domElement: event.currentTarget, data })
    }
    const HGCardWIthFunctions = (item) => {
        return (
            <CardHG
                className={classes.card}
                handleShiftRedirect={(e) => handleShiftRedirect(e, item)}
                handleMoreClick={(e) => handleMoreClick(e, item)}
                {...item}
            />
        )
    }
    const handleShiftRedirect = (e, data) => {
        e.stopPropagation()
        appHistory.push('/homogeneousGroups/' + anchorEl?.data?.id + '/shifts')
    }

    const handleWorkerRedirect = (e) => {
        e.stopPropagation()
        appHistory.push(
            '/workers/' +
                anchorEl?.data?.ownerProductionUnit?.id +
                '/' +
                anchorEl?.data?.id
        )
    }
    const handleMenuClose = () => {
        setAnchorEl({ domElement: null, data: null })
    }

    const handleDelete = () => {
        handleMenuClose()
        anchorEl?.data?.id &&
            onDelete(
                anchorEl.data,
                insertedEmployees - anchorEl.data.employeesCount
            )
    }

    const handleEdit = () => {
        handleMenuClose()
        anchorEl?.data?.id && onEdit(anchorEl.data)
    }

    return (
        <Fragment>
            <Menu
                id="homogeneous-group-menu"
                anchorEl={anchorEl?.domElement}
                keepMounted
                open={Boolean(anchorEl?.domElement)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>{t('modifica')}</MenuItem>
                <MenuItem onClick={handleShiftRedirect}>{t('turni')}</MenuItem>
                <MenuItem onClick={handleWorkerRedirect}>
                    {t('inserisci_lavoratore')}
                </MenuItem>
                <MenuItem onClick={handleDelete}>{t('elimina')}</MenuItem>
            </Menu>
            <CrudGrid
                items={list}
                onItemClick={onItemClick}
                renderItem={HGCardWIthFunctions}
            />
        </Fragment>
    )
}
