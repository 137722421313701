import { CardHeader, Grid, IconButton, Typography } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import styled from 'styled-components'
import RiskDisplayPU from './RiskDisplayPU'
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded'
import PhotoSizeSelectSmallRoundedIcon from '@material-ui/icons/PhotoSizeSelectSmallRounded'
import GroupWorkRoundedIcon from '@material-ui/icons/GroupWorkRounded'
import Box from '@material-ui/core/Box'
import { formatDecimal, getRegionsByProvinces } from '../../utils'
import Paper from '@material-ui/core/Paper'
import RiskClass from '../Customized/RiskClass'
import InitialClassVSFinalClass from './InitialClassVSFinalClass'
import Tooltip from '@material-ui/core/Tooltip'
import { GET_LAST_WOKRER_STATS, RT_QUERY } from './GraphQL_queries'
import { useQuery } from '@apollo/client'
import Chip from '@material-ui/core/Chip'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    card: {
        width: 380,
        border: (props) =>
            props.restrainingBlock ? '1px solid #f44336' : 'none',
    },
    inline: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
}))

const StyledCardHeader = styled(({ ...other }) => <CardHeader {...other} />)`
    & .MuiCardHeader-content {
        flex: 1 1 auto;
        width: 100%;
        height: 3.6em;
    }
    & .MuiCardHeader-title {
        font-size: 20px;
    }
`

export default function CardPU({
    id,
    name,
    address,
    image,
    riskClass,
    handleMoreClick,
    province,
    k1,
    k3,
    restrainingBlock,
    homogeneousGroups,
    risk,
    employeesCount,
    ...rest
}) {
    const { t } = useTranslation()
    const classes = useStyle({ ...rest, restrainingBlock })
    const theme = useTheme()
    const [rt, setRt] = useState(0)
    const [vaccinated, setVaccinated] = useState(0)

    //TODO move to backend side
    const inputedEmployees = homogeneousGroups.reduce(
        (sum, current) => (sum += current?.employeesCount),
        0
    )

    useEffect(() => {
        getRegionsByProvinces([province])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line no-unused-vars
    const rtIndex = useQuery(RT_QUERY, {
        onCompleted: (result) => {
            let currentRegion = getRegionsByProvinces([province])
            setRt(JSON.parse(result?.getLastRt?.data)[currentRegion])
            // setRt(JSON.parse(result?.getLastRt?.data))
        },
    })

    // eslint-disable-next-line no-unused-vars
    const vaccinatedPercentage = useQuery(GET_LAST_WOKRER_STATS, {
        variables: { productionUnitId: id },
        fetchPolicy: 'network-only',
        onCompleted: (result) => {
            if (result?.workerStats?.length > 0) {
                if (
                    result?.workerStats[result?.workerStats.length - 1]
                        .vaccinati
                )
                    setVaccinated(
                        result?.workerStats[result?.workerStats.length - 1]
                            .vaccinati
                    )
            }
        },
    })

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box width="100%">
                <StyledCardHeader
                    avatar={
                        restrainingBlock ? (
                            <Tooltip title={t('blocco-res-current')}>
                                <ErrorOutlineIcon
                                    style={{ color: '#f44336' }}
                                />
                            </Tooltip>
                        ) : (
                            <Box style={{ visibility: 'hidden' }}>
                                <Tooltip>
                                    <ErrorOutlineIcon
                                        style={{ color: '#f44336' }}
                                    />
                                </Tooltip>
                            </Box>
                        )
                    }
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleMoreClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={name}
                    subheader={address}
                />
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Tooltip title={t('rt_ttip_description')} placement="top">
                        <Chip
                            style={{ fontSize: '14px' }}
                            label={
                                t('i_Rt') +
                                ' ' +
                                province +
                                ': ' +
                                parseFloat(rt).toFixed(2)
                            }
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <Tooltip title={t('vacc_ttip_description')} placement="top">
                        <Chip
                            style={{ fontSize: '14px' }}
                            label={
                                t('vaccinati') +
                                ': ' +
                                (vaccinated !== 0
                                    ? `${parseInt(vaccinated)}%`
                                    : t('N/D'))
                            }
                        />
                    </Tooltip>
                </Grid>
            </Grid>
            <Box
                width="100%"
                marginTop={2}
                display="flex"
                flexDirection="column"
            >
                <Grid item xs={12}>
                    <Typography
                        style={{ fontSize: '15px' }}
                        color={'textSecondary'}
                    >
                        {t('COEFFICIENTI')}
                    </Typography>
                </Grid>
                <Box display="flex" justifyContent="space-around" mt="2px">
                    <Box>
                        <Tooltip title={t('coeff_ttip_description')}>
                            <Box className={classes.inline}>
                                <GroupWorkRoundedIcon
                                    className={classes.marginRight}
                                />
                                <Typography
                                    variant={'caption'}
                                    style={{ fontSize: '14px' }}
                                >
                                    RISK = {formatDecimal(risk?.finalPercentage) || 'N/A'}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Tooltip title={t('coeff_ttip_ateco')}>
                            <Box className={classes.inline}>
                                <BusinessRoundedIcon
                                    className={classes.marginRight}
                                />
                                <Typography
                                    variant={'caption'}
                                    style={{ fontSize: '14px' }}
                                >
                                    ATECO = {formatDecimal(k1) || 'N/A'}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box>
                        <Tooltip title={t('coeff_ttip_num')}>
                            <Box className={classes.inline}>
                                <PhotoSizeSelectSmallRoundedIcon
                                    className={classes.marginRight}
                                />
                                <Typography
                                    variant={'caption'}
                                    style={{ fontSize: '14px' }}
                                    o
                                >
                                    SIZE = {formatDecimal(k3) || 'N/A'}
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>

            <Grid
                container
                spacing={1}
                // xs={12}
                style={{
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                }}
            >
                <Grid item xs={8} style={{ paddingLeft: theme.spacing(3) }}>
                    <Paper
                        elevation={0}
                        style={{ backgroundColor: '#f6f6f6' }}
                        className={'triangle-isosceles'}
                    >
                        <RiskClass
                            k3={k3}
                            baseRisk={risk?.finalPercentage}
                            criticalitiesKappas={
                                risk?.finalRiskPercentageComposition
                            }
                            initialRisk={risk?.initialPercentage}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={4}>
                    <RiskDisplayPU
                        unitId={rest?.id}
                        employeesCount={employeesCount}
                        inputedEmployees={inputedEmployees}
                        groups={homogeneousGroups}
                    />
                </Grid>
            </Grid>

            <Box width="100%" display="flex" justifyContent="center">
                <Box width="90%">
                    <InitialClassVSFinalClass
                        initialClass={risk?.initialClass}
                        initialPercentage={risk?.initialPercentage}
                        finalClass={risk?.finalClass}
                        finalPercentage={risk?.finalPercentage}
                    />
                </Box>
            </Box>
        </Box>
    )
}
