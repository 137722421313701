import React, { useState } from 'react'
import { Fab, CircularProgress, Box, Divider } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ProductionUnits from '../Components/ProductionUnit/ListPU'
import { CustomDialog } from '../Components/Customized'
import { useMutation, useQuery } from '@apollo/client'
import AddIcon from '@material-ui/icons/Add'
import ProductionUnitEditForm from '../Components/ProductionUnit/ProductionUnitEditForm'
import {
    DELETE_PRODUCTION_UNIT,
    PRODUCTION_UNITS_QUERY,
    INSERT_PRODUCTION_UNIT,
    UPDATE_PRODUCTION_UNIT,
    GLOBAL_STATS,
} from '../Components/ProductionUnit/GraphQL_queries'
import { employeesStatsToPieChartData, notifyFailure } from '../utils'
import { useConfirmDialog } from '../globalState'
import PageTitle from '../Components/Customized/PageTitle'
import PageContainer from '../Components/Customized/PageContainer'
import ProductionUnitWorkerForm from '../Components/ProductionUnit/ProductionUnitWorkerForm'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import DashboardValue from '../Components/DashboardValue'
import { Pie } from 'react-chartjs-2'
import _ from 'lodash'
import hash from 'object-hash'
import CloseButton from '../Components/CloseButton'
import { useLoginStore } from '../globalState'

const useStyle = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        right: theme.spacing(4),
        bottom: theme.spacing(4),
    },
    paperStyle: {
        marginTop: '5px',
        borderRadius: 8,
        paddingTop: 20,
    },
    topGrid: {
        paddingBottom: 20,
    },
    gridVerticalRule: {
        borderLeft: '1px solid grey',
    },
    gridVerticalSpacing: {
        marginTop: '15px',
    },
}))

function GlobalStats({ stats, Employees }) {
    const theme = useTheme()
    const style = useStyle()
    const { t } = useTranslation()
    const gridContent = [
        { title: t('sani'), subtitle: t('pu_desc_1') },
        { title: t('quarantena'), subtitle: t('pu_desc_2') },
        { title: t('infetti'), subtitle: t('pu_desc_3') },
        { title: t('vaccinati'), subtitle: t('pu_desc_4') },
    ]

    const [windowWidth, setWindowWidth] = useState()

    React.useEffect(() => {
        setWindowWidth(window.innerWidth)
    }, [])

    window.addEventListener('resize', (event) => {
        const latestWidth = window.innerWidth
        setWindowWidth(latestWidth)
    })

    const statusPieData = React.useMemo(() => {
        return !_.isNull(stats) && stats && [stats]?.length !== 0
            ? employeesStatsToPieChartData([stats])
            : {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash({ stats })])

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <Box width="100%" maxWidth="690px">
                <Typography variant="h2" color="textSecondary">
                    {t('composizione_personale')}
                </Typography>
                <Paper className={style.paperStyle}>
                    <Grid
                        container
                        justify={'space-between'}
                        className={style.topGrid}
                    >
                        {stats &&
                            Object.keys(stats).map((item, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    className={
                                        index !== 0 && windowWidth >= 600
                                            ? style.gridVerticalRule
                                            : index !== 0 &&
                                              style.gridVerticalSpacing
                                    }
                                >
                                    <DashboardValue
                                        title={t(gridContent[index].title)}
                                        subtitle={t(
                                            gridContent[index].subtitle
                                        )}
                                        value={
                                            stats
                                                ? stats[item]
                                                    ? parseFloat(
                                                          stats[item]
                                                      ).toFixed(2) + '%'
                                                    : t('Nessuno')
                                                : t('N/D')
                                        }
                                        valueColor={
                                            theme.palette.status.neutral
                                        }
                                        textVariant={'h5'}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    container*/}
                    {/*    justify={'space-around'}*/}
                    {/*    */}
                    {/*    className={style.topGrid}*/}
                    {/*>*/}
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent="center"
                        alignItems={'center'}
                    >
                        {stats && _.some(stats, (val) => val !== 0) ? (
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {t('stato_attuale_lavoratori')}
                                </Typography>
                                <Box width={300} height={270}>
                                    <Pie
                                        data={statusPieData}
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                        }}
                                    />
                                </Box>
                                <Box marginTop={'8px'} marginBottom={'8px'}>
                                    <Typography
                                        variant={'body2'}
                                        color={'textSecondary'}
                                    >
                                        {t('pie2_context_p1')}
                                        <b>{Employees?.currentInsertedSum}</b>
                                        {t('pie2_context_p2')}
                                        <b>{Employees?.totalSum}</b>
                                        {t('pie2_context_p3')}
                                    </Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                height="100%"
                                width="100%"
                                position="relative"
                                bottom="20px"
                            >
                                <Typography
                                    variant={'body1'}
                                    color={'textSecondary'}
                                >
                                    {t('dati_insufficienti')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default function ProductionUnitsScreen(props) {
    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyle(props)
    const [Employees, setEmployees] = useState({})

    const setLoggedOut = useLoginStore((state) => state.setLoggedOut)

    const { loading, data } = useQuery(PRODUCTION_UNITS_QUERY, {
        fetchPolicy: 'network-only',
        onError: (error) => {
            if (error.message.slice(0, 3) === '401') {
                setLoggedOut()
                history.replace('/login')
            }
        },
        onCompleted: (data) => {
            let totalSum = 0
            let currentInsertedSum = 0
            data.productionUnits.forEach((unit) => {
                totalSum += unit.employeesCount
                unit.homogeneousGroups.forEach((group) => {
                    currentInsertedSum += group?.workers?.length
                })
            })
            setEmployees({
                totalSum: totalSum,
                currentInsertedSum: currentInsertedSum,
            })
        },
    })
    // const lastWorkersStats = useQuery(GET_LAST_WOKRER_STATS, {
    //     variables: {
    //
    //     }
    // })
    const [_deleteProductionUnit] = useMutation(DELETE_PRODUCTION_UNIT, {
        refetchQueries: [{ query: PRODUCTION_UNITS_QUERY }],
    })
    const [updateProductionUnit] = useMutation(UPDATE_PRODUCTION_UNIT)
    const [insertProductionUnit] = useMutation(INSERT_PRODUCTION_UNIT, {
        refetchQueries: [{ query: PRODUCTION_UNITS_QUERY }],
    })
    const [isEditFormOpen, setIsEditFormOpen] = React.useState(false)
    const [isWorkerFormOpen, setIsWorkerFormOpen] = React.useState(false)
    const [selectedUnit, setSelectedUnit] = React.useState(null)
    const appHistory = useHistory()
    const [openConfirm] = useConfirmDialog((state) => [state.openConfirm])
    const [stats, setStats] = useState(null)

    // eslint-disable-next-line no-unused-vars
    const globalStats = useQuery(GLOBAL_STATS, {
        fetchPolicy: 'network-only',
        onCompleted: (result) => {
            setStats(result?.globalStats)
        },
    })

    function handleUnitClick(unit) {
        appHistory.push(`/productionUnits/${unit.id}`)
    }

    function handleEditClick(unit) {
        setSelectedUnit(unit)
        setIsEditFormOpen(true)
    }

    function handleFormSubmit(productionUnitData) {
        const mutationToUse = productionUnitData?.id
            ? updateProductionUnit
            : insertProductionUnit
        mutationToUse({
            variables: productionUnitData,
        })
            .then(() => {
                setIsEditFormOpen(false)
                return Promise.resolve()
            })
            .catch(notifyFailure)
    }

    function handleWorkerClick(unit) {
        setSelectedUnit(unit)
        setIsWorkerFormOpen(true)
    }

    function deleteProductionUnit({ id }) {
        openConfirm({
            title: t('elimina_PU'),
            description: t('elimina_PU_descrizione'),
            onConfirm: () =>
                _deleteProductionUnit({ variables: { id } }).catch(
                    notifyFailure
                ),
        })
    }

    function handleCreateIntent() {
        setSelectedUnit(null)
        setIsEditFormOpen(true)
    }

    return (
        <PageContainer>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
            >
                <PageTitle>{t('unita_produttive')}</PageTitle>
                <GlobalStats stats={stats && stats} Employees={Employees} />
                <Divider
                    light
                    style={{
                        margin: '30px 0',
                        background: 'rgba(0,0,0,0.23)',
                        width: '100%',
                        maxWidth: '690px',
                    }}
                />
                {!!loading && <CircularProgress />}
                {!!data?.productionUnits && (
                    <ProductionUnits
                        unitsList={data.productionUnits}
                        onUnitClick={handleUnitClick}
                        onDelete={deleteProductionUnit}
                        onEdit={handleEditClick}
                        onWorker={handleWorkerClick}
                    />
                )}

                <CustomDialog
                    open={isEditFormOpen}
                    aria-labelledby="simple-dialog-title"
                >
                    <Box borderRadius={20}>
                        <CloseButton
                            title={t('crea_mod_PU')}
                            onClose={() => setIsEditFormOpen(false)}
                        />
                        <ProductionUnitEditForm
                            key={selectedUnit?.id}
                            onSubmit={handleFormSubmit}
                            unitData={selectedUnit}
                            units={data?.productionUnits}
                            afterSubmit={() => {
                                setIsEditFormOpen(false)
                            }}
                        />
                    </Box>
                </CustomDialog>
                <CustomDialog
                    onClose={() => setIsWorkerFormOpen(false)}
                    open={isWorkerFormOpen}
                    aria-labelledby="simple-dialog-title"
                >
                    <CloseButton
                        title={'Informazioni lavoratore'}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                    <ProductionUnitWorkerForm
                        unitData={selectedUnit}
                        afterSubmit={async () => {
                            setIsWorkerFormOpen(false)
                        }}
                        onClose={() => setIsWorkerFormOpen(false)}
                    />
                </CustomDialog>

                <Fab
                    className={classes.fab}
                    color="primary"
                    aria-label="add"
                    onClick={handleCreateIntent}
                >
                    <AddIcon />
                </Fab>
            </Box>
        </PageContainer>
    )
}
