import React, { useState } from 'react'
import { Box, Typography, Grid, Avatar } from '@material-ui/core'
import ActivitiesForm from './ActivitiesForm'
import { Doughnut } from 'react-chartjs-2'
import {
    doughnutColors,
    formatAreasForDoughnut,
    notifyFailure,
    notifySuccess,
} from '../../utils'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ColoredRibbon from '../ColoredRibbon'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { UPDATE_HOMOGENEOUS_GROUP_ACTIVITIES } from './GraphQL_queries'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import CustomTooltip from '../Customized/CustomTooltip'

// function styledCurrentSlice(doughnutData, currentIndex, activeColor, baseColor) {
//     const cloned = _.cloneDeep(doughnutData)
//     cloned.datasets[0].backgroundColor = Array(cloned.datasets[0].data.length).fill(baseColor)
//     cloned.datasets[0].backgroundColor[currentIndex] = activeColor
//     return cloned
// }

const useStyles = makeStyles((theme) => ({
    radar: {
        minHeight: 300,
        marginTop: theme.spacing(8),
    },
    activityBox: {
        margin: theme.spacing(1),
    },
    hideOverflow: {
        position: 'relative',
        overflow: 'hidden',
    },
    customPadding: {
        marginTop: theme.spacing(0.5),
        padding: theme.spacing(1),
    },
    sticky: {
        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: 60,
        },
        height: 400,
        marginBottom: theme.spacing(8),
    },
    avatarStyle: {
        height: '22px',
        width: '22px',
        backgroundColor: '#5191F2',
        '&:hover': {
            backgroundColor: 'rgba(81,145,242,0.85)',
        },
    },
}))

export default function DetailHG({ data = {}, refetch, ...rest }) {
    const { t } = useTranslation()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.addEventListener('resize', () => {
        const newWidth = window.innerWidth
        setWindowWidth(newWidth)
    })

    //TODO replace blendColors with hue rotate green --> red
    const [risk, setRisk] = useState(data.risk || {})
    const classes = useStyles()
    const { groupID } = useParams()
    // eslint-disable-next-line no-unused-vars
    const mitigation = data.mitigation || {}
    const { formState, register, control, handleSubmit } = useForm({
        defaultValues: {
            0: { activities: risk?.proximityCategories?.[0].activities },
            1: { activities: risk?.proximityCategories?.[1].activities },
            2: { activities: risk?.proximityCategories?.[2].activities },
            3: { activities: risk?.proximityCategories?.[3].activities },
        },
    })
    const { data: genericGraphData, total } = formatAreasForDoughnut(
        risk.proximityCategories
    )
    const [updateRiskActivities] = useMutation(
        UPDATE_HOMOGENEOUS_GROUP_ACTIVITIES
    )
    let { isSubmitting, isDirty } = formState

    const submitForm = (data) => {
        let result = _.values(data)
        let updatedData = []
        result.forEach((activities, index) => {
            if (activities.activities?.id) {
                delete result[index].activities.id
            }

            let updatedActivityField = {
                weight: index + 1,
                activities: activities.activities,
            }
            updatedData.push(updatedActivityField)
        })
        updateRiskActivities({
            variables: {
                activities: updatedData,
                groupId: groupID && groupID,
            },
        })
            .then(async (res) => {
                notifySuccess()
                let newRisk = await refetch()
                setRisk(newRisk)
                // reset(values)
                // reset(getValues(["0.activities", "1.activities", "2.activities", "3.activities"]))
            })
            .catch(notifyFailure)
    }

    const [isPercOvershot, setIsPercOvershot] = useState(false)
    const [sum, setSum] = useState(0)
    const [sumStore, setSumStore] = useState([])
    const [percentageHistory, setPercentageHistory] = useState([])
    const info = [t('p1_info'), t('p2_info'), t('p3_info'), t('p4_info')]

    // ---- Percentage overshoot check ----

    const initSum = (currentField) => {
        currentField.forEach((item) => {
            setSumStore((prevValue) => {
                let newStore = _.cloneDeep(prevValue)
                let removeId = -1

                newStore.forEach((prevItem, idx) => {
                    if (prevItem.id === item.id) removeId = idx
                })

                if (removeId > -1) newStore.splice(removeId, 1)

                return [
                    ...newStore,
                    { id: item.id, value: item.timePercentage },
                ]
            })
        })
    }

    const addSum = (value, id) => {
        setSumStore((prevValue) => {
            let newStore = _.cloneDeep(prevValue)
            let removeId = -1

            newStore.forEach((prevItem, idx) => {
                if (prevItem.id === id) removeId = idx
            })

            if (removeId > -1) newStore.splice(removeId, 1)

            return [...newStore, { id, value }]
        })
    }

    const removeSum = (id) => {
        setSumStore((prevValue) => {
            let newStore = _.cloneDeep(prevValue)
            let removeStoreId = -1

            newStore.forEach((prevItem, idx) => {
                if (prevItem.id === id) removeStoreId = idx
            })

            if (removeStoreId > -1) newStore.splice(removeStoreId, 1)

            return newStore
        })
    }

    React.useEffect(() => {
        let sumBuffering = 0

        sumStore.forEach((item) => {
            let typedValue = item.value

            if (typeof typedValue === 'string') {
                if (typedValue.length === 0) {
                    typedValue = 0
                } else {
                    typedValue = parseInt(typedValue, 10)
                }
            }

            sumBuffering += typedValue
        })

        setSum(sumBuffering)
    }, [sumStore])

    React.useEffect(() => {
        if (sum > 100) {
            setIsPercOvershot(true)
        } else {
            if (isPercOvershot) setIsPercOvershot(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sum])

    return (
        <Box>
            {!risk?.proximityCategories?.length ? null : (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} className={classes.sticky}>
                        <Paper className={classes.customPadding}>
                            <Grid item xs={12}>
                                <Typography gutterBottom variant={'h5'}>
                                    {t('tempo_assegnato')}
                                </Typography>
                                {/*<pre>{100-total}</pre>*/}
                                {total > 100 ? <p>Error</p> : null}
                                {total < 100 ? (
                                    <Typography
                                        variant={'overline'}
                                        color={'error'}
                                    >
                                        {t('controlla_totale')}
                                    </Typography>
                                ) : null}
                                <Doughnut
                                    legend={null}
                                    options={{ maintainAspectRatio: true }}
                                    data={genericGraphData}
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Box w={'100%'}>
                        <Paper
                            style={{
                                width:
                                    windowWidth <= 575 ? windowWidth : '575px',
                            }}
                        >
                            <form
                                onChange={(e) => {}}
                                onSubmit={handleSubmit(submitForm)}
                            >
                                {risk?.proximityCategories.map(
                                    (area, currentIndex) => (
                                        <Box w={'100%'} p={'10px'}>
                                            <Paper
                                                variant={'outlined'}
                                                className={classes.hideOverflow}
                                                elevation={0.5}
                                                style={{ position: 'relative' }}
                                            >
                                                <Box
                                                    position="absolute"
                                                    top="4px"
                                                    right="4px"
                                                >
                                                    <CustomTooltip
                                                        title={
                                                            info[currentIndex]
                                                        }
                                                        placement="left"
                                                    >
                                                        <Avatar
                                                            className={
                                                                classes.avatarStyle
                                                            }
                                                        >
                                                            <AiOutlineInfoCircle />
                                                        </Avatar>
                                                    </CustomTooltip>
                                                </Box>
                                                <ColoredRibbon
                                                    color={
                                                        doughnutColors[
                                                            currentIndex
                                                        ]
                                                    }
                                                />
                                                <Typography
                                                    variant="h6"
                                                    component="h3"
                                                >
                                                    {area.name ||
                                                        t('rischio_p') +
                                                            area.weight}
                                                </Typography>
                                                <ActivitiesForm
                                                    // onBlur={onInputChange}
                                                    setPercentageHistory={
                                                        setPercentageHistory
                                                    }
                                                    percentageHistory={
                                                        percentageHistory
                                                    }
                                                    control={control}
                                                    register={register}
                                                    riskGroupIndex={
                                                        currentIndex
                                                    }
                                                    activities={area.activities}
                                                    windowWidth={windowWidth}
                                                    initSum={initSum}
                                                    addSum={addSum}
                                                    removeSum={removeSum}
                                                />
                                            </Paper>
                                        </Box>
                                    )
                                )}
                                <Box
                                    w={'100%'}
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                    p={'10px'}
                                    alignItems={'center'}
                                >
                                    {isPercOvershot && (
                                        <Box justifySelf={'center'} width="70%">
                                            <Typography
                                                variant={'body1'}
                                                style={{
                                                    paddingRight: '20px',
                                                    color: 'red',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {t('validation_error')}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Button
                                        type={'submit'}
                                        variant={'contained'}
                                        color={'primary'}
                                        disabled={
                                            isSubmitting ||
                                            !isDirty ||
                                            isPercOvershot
                                        }
                                    >
                                        salva
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                    </Box>
                </Grid>
            )}
        </Box>
    )
}
