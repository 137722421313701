import * as React from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { CgPlayListRemove } from 'react-icons/cg'
import { MdPlaylistAdd, MdInsertLink } from 'react-icons/md'
import { makeStyles } from '@material-ui/core'
import Draggable from 'react-draggable'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        width: '100%',
        paddingBottom: '15px',
    },
    buttonBoxStyle: {
        border: '1px solid #C4C4C4',
        borderRadius: '4px',
        height: '56px',
        width: '56px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        margin: theme.spacing(1),
    },
    linkButtonActive: {
        backgroundColor: '#3F51B5 !important',
    },
    linkIconActive: {
        color: 'white !important',
    },
    dialogActionsStyle: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    },
}))

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}

export default function CustomScenarioDialog({
    isOpen,
    handleClose,
    cellContentList,
    setCellContentList,
    addCellToPackage,
}) {
    const { t } = useTranslation()
    const styles = useStyles()
    const [newFieldValue, setNewFieldValue] = React.useState({
        type: 'text',
        value: '',
    })
    const [newLinkValue, setNewLinkValue] = React.useState({
        type: 'link',
        title: '',
        url: '',
    })
    const [newLocalCellContentList, setNewLocalCellContentList] =
        React.useState(cellContentList)
    const [editMode, setEditMode] = React.useState('text')

    React.useEffect(() => {
        setNewLocalCellContentList(cellContentList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cellContentList])

    const openArchivePopUp = () => {
        window.open(
            '/archive_online_window',
            'targetWindow',
            'width=800,height=800'
        )
    }

    const handleDialogClose = () => {
        setNewLocalCellContentList(cellContentList)
        setNewFieldValue({ type: 'text', value: '' })
        setNewLinkValue({ type: 'link', title: '', url: '' })
        handleClose()
    }

    const resetFields = () => {
        setNewFieldValue({ type: 'text', value: '' })
        setNewLinkValue({ type: 'link', title: '', url: '' })
    }

    const addCurrentFieldToList = () => {
        if (editMode === 'text') {
            if (newFieldValue.value !== '') {
                setNewLocalCellContentList([
                    ...newLocalCellContentList,
                    newFieldValue,
                ])
                resetFields()
            }
        } else if (editMode === 'link') {
            if (newLinkValue.title !== '' && newLinkValue.url !== '') {
                setNewLocalCellContentList([
                    ...newLocalCellContentList,
                    newLinkValue,
                ])
                resetFields()
            }
        }
    }

    const saveLocalStateToMain = () => {
        if (editMode === 'text' && newFieldValue.value !== '') {
            setCellContentList([...newLocalCellContentList, newFieldValue])
            addCellToPackage([...newLocalCellContentList, newFieldValue])
        } else if (
            editMode === 'link' &&
            newLinkValue.title !== '' &&
            newLinkValue.url !== ''
        ) {
            setCellContentList([...newLocalCellContentList, newLinkValue])
            addCellToPackage([...newLocalCellContentList, newLinkValue])
        } else {
            setCellContentList(newLocalCellContentList)
            addCellToPackage(newLocalCellContentList)
        }

        handleDialogClose()
    }

    const switchEditMode = () => {
        if (editMode === 'text') {
            setEditMode('link')
        } else if (editMode === 'link') {
            setEditMode('text')
        }
    }

    // Counter for Input labels
    let measureCounter = 0

    // Set dialog body based on cell content
    const Content = React.useRef(<Box></Box>)

    const deleteLocalListItem = (listIndex) => {
        const newList = _.cloneDeep(newLocalCellContentList)
        newList.splice(listIndex, 1)
        setNewLocalCellContentList(newList)
    }

    const keyPressListener = (e) => {
        if (e.key === 'Enter') {
            addCurrentFieldToList()
        }
    }

    const handleFirstFieldChange = (e) => {
        const { name, value } = e.target

        if (name === 'value') {
            setNewFieldValue((prevValue) => {
                return {
                    ...prevValue,
                    [name]: value,
                }
            })
        } else if (name === 'title' || name === 'url') {
            setNewLinkValue((prevValue) => {
                return {
                    ...prevValue,
                    [name]: value,
                }
            })
        }
    }

    const handleNextFieldChange = (e) => {
        const { name, value } = e.target

        if (name === 'value') {
            setNewFieldValue((prevValue) => {
                return {
                    ...prevValue,
                    [name]: value,
                }
            })
        } else if (name === 'title' || name === 'url') {
            setNewLinkValue((prevValue) => {
                return {
                    ...prevValue,
                    [name]: value,
                }
            })
        }
    }

    const handleListChange = (e) => {
        const { name, value } = e.target
        const nameSplit = name.split('_')

        const index = nameSplit[0]
        const fieldName = nameSplit[1]

        let newCellContentList = _.cloneDeep(newLocalCellContentList)

        if (fieldName === 'value') {
            newCellContentList[index] = {
                ...newCellContentList[index],
                value: value,
            }
        } else if (fieldName === 'title') {
            newCellContentList[index] = {
                ...newCellContentList[index],
                title: value,
            }
        } else if (fieldName === 'url') {
            newCellContentList[index] = {
                ...newCellContentList[index],
                url: value,
            }
        }
        setNewLocalCellContentList(newCellContentList)
    }

    if (newLocalCellContentList.length === 0) {
        if (editMode === 'text') {
            measureCounter += 1
            Content.current = (
                <TextField
                    autofocus
                    autoComplete="off"
                    name="value"
                    value={newFieldValue.value}
                    variant="outlined"
                    label={t('misura_') + measureCounter}
                    className={styles.inputStyle}
                    onChange={(e) => handleFirstFieldChange(e)}
                    onKeyDown={(e) => keyPressListener(e)}
                />
            )
        } else if (editMode === 'link') {
            measureCounter += 1
            Content.current = (
                <Box display="flex" justifyContent="space-between">
                    <Box flex="1" mr="5px">
                        <TextField
                            autofocus
                            autoComplete="off"
                            name="title"
                            value={newLinkValue.title}
                            variant="outlined"
                            label={t('misura_') + measureCounter + ' - Titolo'}
                            className={styles.inputStyle}
                            onChange={(e) => handleFirstFieldChange(e)}
                            onKeyDown={(e) => keyPressListener(e)}
                        />
                    </Box>
                    <Box flex="1" ml="5px">
                        <TextField
                            autoComplete="off"
                            name="url"
                            value={newLinkValue.url}
                            variant="outlined"
                            label={t('misura_') + measureCounter + ' - Url'}
                            className={styles.inputStyle}
                            onChange={(e) => handleFirstFieldChange(e)}
                            onKeyDown={(e) => keyPressListener(e)}
                        />
                    </Box>
                </Box>
            )
        }
    } else {
        Content.current = newLocalCellContentList.map((item, idx) => {
            measureCounter += 1
            return (
                <Box key={idx}>
                    {item.type === 'text' ? (
                        <TextField
                            autoComplete="off"
                            name={idx + '_value'}
                            variant="outlined"
                            label={t('misura_') + measureCounter}
                            className={styles.inputStyle}
                            value={newLocalCellContentList[idx]?.value}
                            onChange={(e) => handleListChange(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Box
                                            width="56px"
                                            height="56px"
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    deleteLocalListItem(idx)
                                                }
                                            >
                                                <CgPlayListRemove
                                                    color="black"
                                                    fonstSize="28px"
                                                />
                                            </IconButton>
                                        </Box>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ) : (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box flex="0.9" mr="5px">
                                <TextField
                                    autoComplete="off"
                                    name={idx + '_title'}
                                    variant="outlined"
                                    label={
                                        t('misura_') +
                                        measureCounter +
                                        ' - Titolo'
                                    }
                                    className={styles.inputStyle}
                                    value={newLocalCellContentList[idx]?.title}
                                    onChange={(e) => handleListChange(e)}
                                />
                            </Box>
                            <Box flex="1.5" ml="5px">
                                <TextField
                                    autoComplete="off"
                                    name={idx + '_url'}
                                    variant="outlined"
                                    label={
                                        t('misura_') + measureCounter + ' - Url'
                                    }
                                    className={styles.inputStyle}
                                    value={newLocalCellContentList[idx]?.url}
                                    onChange={(e) => handleListChange(e)}
                                />
                            </Box>
                            <Box mb="15px" ml="10px">
                                <Box className={styles.buttonBoxStyle}>
                                    <IconButton
                                        onClick={() => deleteLocalListItem(idx)}
                                    >
                                        <CgPlayListRemove
                                            color="black"
                                            fonstSize="28px"
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            )
        })
    }

    return (
        <Box>
            <Dialog
                open={isOpen}
                onClose={() => {}}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle
                    style={{ cursor: 'move' }}
                    id="draggable-dialog-title"
                >
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>{t('misure_di_sicurezza')}</Box>
                        <Box>
                            <IconButton onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box width="400px">
                        <Box>{Content.current}</Box>
                        {newLocalCellContentList.length > 0 && (
                            <Box>
                                {editMode === 'text' ? (
                                    <Box>
                                        <TextField
                                            autoFocus
                                            autoComplete="off"
                                            name="value"
                                            value={newFieldValue.value}
                                            variant="outlined"
                                            label={
                                                t('misura_') +
                                                (measureCounter + 1)
                                            }
                                            className={styles.inputStyle}
                                            onChange={(e) =>
                                                handleNextFieldChange(e)
                                            }
                                            onKeyDown={(e) =>
                                                keyPressListener(e)
                                            }
                                        />
                                    </Box>
                                ) : (
                                    editMode === 'link' && (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box flex="1" mr="5px">
                                                <TextField
                                                    autoFocus
                                                    autoComplete="off"
                                                    name="title"
                                                    value={newLinkValue.title}
                                                    variant="outlined"
                                                    label={
                                                        t('misura_') +
                                                        (measureCounter + 1) +
                                                        ' - Titolo'
                                                    }
                                                    className={
                                                        styles.inputStyle
                                                    }
                                                    onChange={(e) =>
                                                        handleNextFieldChange(e)
                                                    }
                                                    onKeyDown={(e) =>
                                                        keyPressListener(e)
                                                    }
                                                />
                                            </Box>
                                            <Box flex="1" ml="5px">
                                                <TextField
                                                    autoComplete="off"
                                                    name="url"
                                                    value={newLinkValue.url}
                                                    variant="outlined"
                                                    label={
                                                        t('misura_') +
                                                        measureCounter +
                                                        ' - Url'
                                                    }
                                                    className={
                                                        styles.inputStyle
                                                    }
                                                    onChange={(e) =>
                                                        handleNextFieldChange(e)
                                                    }
                                                    onKeyDown={(e) =>
                                                        keyPressListener(e)
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    )
                                )}
                            </Box>
                        )}
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            <IconButton
                                onClick={addCurrentFieldToList}
                                style={{
                                    padding: '10px',
                                    border: '1px solid #C4C4C4',
                                    marginRight: '5px',
                                }}
                            >
                                <MdPlaylistAdd color="black" fontSize="28px" />
                            </IconButton>
                            <IconButton
                                onClick={switchEditMode}
                                style={{
                                    padding: '10px',
                                    border: '1px solid #C4C4C4',
                                    marginLeft: '5px',
                                    transition: '0.2s',
                                }}
                                className={
                                    editMode === 'link'
                                        ? styles.linkButtonActive
                                        : ''
                                }
                            >
                                <MdInsertLink
                                    color="black"
                                    fontSize="28px"
                                    style={{ transition: '0.2s' }}
                                    className={
                                        editMode === 'link'
                                            ? styles.linkIconActive
                                            : ''
                                    }
                                />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions className={styles.dialogActionsStyle}>
                    <Box>
                        <Button
                            style={{
                                alignSelf: 'flex-end',
                                backgroundColor: 'rgba(42,42,42,0.81)',
                                color: 'white',
                            }}
                            variant={'contained'}
                            color={'secondary'}
                            size={'small'}
                            className={styles.button}
                            onClick={handleDialogClose}
                        >
                            {t('chiudi')}
                        </Button>
                        <Button
                            style={{ alignSelf: 'flex-end' }}
                            variant={'contained'}
                            color={'primary'}
                            type="submit"
                            size={'small'}
                            onClick={saveLocalStateToMain}
                        >
                            {t('salva')}
                        </Button>
                    </Box>
                    <Box>
                        <Button onClick={openArchivePopUp}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="110px"
                            >
                                <CloudUploadIcon />
                                {t('archivio')}
                            </Box>
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
