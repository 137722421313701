import React from 'react'
import { Box, Typography, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles((theme) => ({
    form: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    formField: {
        margin: theme.spacing(1),
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    horizontalRule: {
        borderTop: '1px solid rgba(0,0,0,0.15)',
    },
    spacedHorizontalRule: {
        borderTop: '1px solid rgba(0,0,0,0.15)',
        padding: '15px 0 0',
    },
}))

const OutlinedNumberField = (props) => (
    <TextField
        {...props}
        margin="dense"
        type={'number'}
        style={{ width: '120px' }}
        inputProps={{
            min: '0',
            max: '100',
            step: '1',
        }}
        InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        variant={'outlined'}
    />
)

export default function MitigationForm({
    onSubmit,
    mitigationInputBP,
    mitigationLabelBP,
    mitigationActivities = [],
    ...rest
}) {
    const { t } = useTranslation()

    const name = 'mitigationActivities'
    const { control, handleSubmit, formState } = useForm({
        defaultValues: { [name]: mitigationActivities },
    })
    const {
        fields,
        // append, prepend, remove, swap, move, insert
    } = useFieldArray({
        control,
        name: name,
    })
    const { isSubmitting, isDirty } = formState

    const classes = useStyle(rest)

    function submit(values, event) {
        onSubmit({
            mitigationActivities: values.mitigationActivities.map((a) => ({
                ...a,
                practicalExecution: parseInt(a.practicalExecution) || null,
                practicalEfficacy: parseInt(a.practicalEfficacy) || null,
            })),
        })
    }

    return (
        <Box width="100%">
            <form onSubmit={handleSubmit(submit)} className={classes.form}>
                {fields.map((item, index) => (
                    <Box
                        width="100%"
                        key={item.id}
                        display="flex"
                        flexDirection={mitigationLabelBP ? 'column' : 'row'}
                        justifyContent={
                            mitigationLabelBP ? 'flex-start' : 'space-between'
                        }
                        alignItems="center"
                        className={
                            mitigationInputBP &&
                            index !== 0 &&
                            !mitigationLabelBP
                                ? classes.horizontalRule
                                : mitigationLabelBP && index === 0
                                ? classes.spacedHorizontalRule
                                : ''
                        }
                    >
                        {mitigationLabelBP && index !== 0 && (
                            <Box
                                width="200px"
                                borderTop="1px solid rgba(0,0,0,0.15)"
                            ></Box>
                        )}
                        <Box pt={mitigationLabelBP ? '6px' : 0}>
                            <Typography
                                title={t(`${item.name}_tips`)}
                                variant={'body2'}
                            >
                                {t(item.name)}
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection={mitigationInputBP ? 'column' : 'row'}
                        >
                            <Controller
                                name={`${name}.${index}.practicalExecution`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <OutlinedNumberField
                                        className={classes.marginRight}
                                        key={item.id}
                                        value={value}
                                        onChange={(e) =>
                                            onChange(e.target.value)
                                        }
                                        title={t('attuazione')}
                                        label={t('attuazione_abbr')}
                                        defaultValue={item.practicalExecution}
                                    />
                                )}
                            />
                            <Controller
                                name={`${name}.${index}.practicalEfficacy`}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Box mb="4px">
                                        <OutlinedNumberField
                                            className={classes.marginRight}
                                            key={item.id}
                                            value={value}
                                            onChange={(e) =>
                                                onChange(e.target.value)
                                            }
                                            title={t('efficacia')}
                                            label={t('efficacia') + '.'}
                                            defaultValue={
                                                item.practicalEfficacy
                                            }
                                        />
                                    </Box>
                                )}
                            />
                        </Box>
                        {/*<button type="button" onClick={() => remove(index)}>Delete</button>*/}
                    </Box>
                ))}
                <Box mt="16px">
                    <Button
                        style={{ alignSelf: 'flex-end' }}
                        variant={isDirty ? 'contained' : 'outlined'}
                        color={'primary'}
                        type="submit"
                        disabled={!isDirty || isSubmitting}
                    >
                        {t('salva')}
                    </Button>
                </Box>
            </form>
        </Box>
    )
}
