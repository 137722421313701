import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { OutlinedSelect } from './Customized/CustomComponents'
import {
    IconButton,
    ListSubheader,
    MenuItem,
    Typography,
} from '@material-ui/core'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
    ALL_PRODUCTION_UNITS,
    GET_HOMOGENEOUS_GROUP_QUERY,
} from './ProductionUnit/GraphQL_queries'
import ReplayIcon from '@material-ui/icons/Replay'
import CheckIcon from '@material-ui/icons/Check'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    outlinedSelect: {
        minWidth: 150,
        marginRight: '8px',
    },
    resize: {
        fontSize: 50,
    },
}))

const FiltersBar = ({
    fetchData,
    unitID,
    hgID,
    setSelectedPuId,
    setSelectedHgId,
    applyFilters,
    resetFilters,
}) => {
    const { t } = useTranslation()
    const styles = useStyles()

    const [productionUnits, setProductionUnits] = useState([])
    const [homogeneousGroups, setHomogeneousGroups] = useState([])

    // eslint-disable-next-line no-unused-vars
    const fetchProductionUnits = useQuery(ALL_PRODUCTION_UNITS, {
        fetchPolicy: 'network-only',
        onCompleted: (res) => {
            if (unitID) {
                setProductionUnits(
                    res.productionUnits.filter((pu) => pu.id === unitID)
                )
            } else {
                setProductionUnits(res.productionUnits)
            }
        },
    })

    const [fetchHomogeneousGroups] = useLazyQuery(GET_HOMOGENEOUS_GROUP_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: (result) => {
            setHomogeneousGroups(result.productionUnits[0].homogeneousGroups)
        },
    })

    React.useEffect(() => {
        if (unitID) {
            fetchHomogeneousGroups({ variables: { unitId: unitID } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box ml="8px">
                <Typography
                    variant={'body1'}
                    color={'textSecondary'}
                    style={{ marginRight: '8px' }}
                >
                    {t('filtra_per')}
                </Typography>
            </Box>

            <Box className={styles.outlinedSelect}>
                <OutlinedSelect
                    fullWidth={true}
                    style={{ width: 150 }}
                    dense={true}
                    label={t('unita_prod_abbr')}
                    value={unitID ? unitID : ''}
                    onChange={(e) => {
                        setSelectedPuId(e.target.value)
                        fetchHomogeneousGroups({
                            variables: { unitId: e.target.value },
                        })
                    }}
                >
                    {productionUnits &&
                        productionUnits.map((unit) => (
                            <MenuItem value={unit.id}>{unit.name}</MenuItem>
                        ))}
                </OutlinedSelect>
            </Box>
            <Box className={styles.outlinedSelect}>
                <OutlinedSelect
                    fullWidth={true}
                    style={{ width: 150 }}
                    margin={'dense'}
                    dense={true}
                    value={hgID ? hgID : ''}
                    label={t('gruppo_omog_abbr')}
                    onChange={(e) => {
                        setSelectedHgId(e.target.value)
                    }}
                >
                    {homogeneousGroups.length > 0 && (
                        <MenuItem value="Tutti">{t('tutti')}</MenuItem>
                    )}
                    {homogeneousGroups.length > 0 ? (
                        homogeneousGroups.map((group) => (
                            <MenuItem value={group.id}>{group.name}</MenuItem>
                        ))
                    ) : (
                        <ListSubheader>{t('seleziona_PU')}</ListSubheader>
                    )}
                </OutlinedSelect>
            </Box>
            <Box>
                <IconButton
                    onClick={() => {
                        applyFilters()
                    }}
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    <CheckIcon />
                </IconButton>
            </Box>
            <Box>
                <IconButton
                    onClick={() => {
                        setSelectedHgId(null)
                        setSelectedPuId(null)
                        resetFilters()
                    }}
                >
                    <ReplayIcon style={{ color: 'red' }} />
                </IconButton>
            </Box>
        </Box>
    )
}

export default FiltersBar
