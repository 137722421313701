import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, FormControl, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Box from '@material-ui/core/Box'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_ALL_WORKERS } from '../ProductionUnit/GraphQL_queries'
import { makeStyles } from '@material-ui/core/styles'
import {
    OutlinedSelect,
    OutlinedTextField,
} from '../Customized/CustomComponents'
import {
    GET_ASSETS_BY_CATEGORY,
    INSERT_EQUIPMENT,
    UPDATE_EQUIPMENT,
} from '../HomogeneousGroup/GraphQL_queries'
import { notifyFailure, notifySuccess } from '../../utils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2),
        // alignItems: 'center',
        width: '100%',
    },
    formField: {
        margin: theme.spacing(1),
        width: '100%',
    },
    inputBox: {
        margin: theme.spacing(1),
    },
    outlinedSelect: {
        width: '100%',
    },
    buttonsBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
}))

const EquipmentForm = ({ equipmentData, ...rest }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    // eslint-disable-next-line no-unused-vars
    const [assets, setAssets] = useState([])
    const [workers, setWorkers] = useState([])

    const { handleSubmit, formState, control } = useForm({
        defaultValues: equipmentData && {
            ...equipmentData,
            currentOwner: equipmentData?.currentOwner?.id,
        },
    })

    const { isSubmitting, isDirty } = formState

    // eslint-disable-next-line no-unused-vars
    const workersQuery = useQuery(GET_ALL_WORKERS, {
        onCompleted: (res) => {
            setWorkers(res.workers.workers)
        },
    })

    const [getAssetsByCategory] = useLazyQuery(GET_ASSETS_BY_CATEGORY, {
        onCompleted: (res) => {
            setAssets(res.assets)
        },
    })

    const [insertEquipment] = useMutation(INSERT_EQUIPMENT)
    const [updateEquipment] = useMutation(UPDATE_EQUIPMENT)

    const handleEdit = (data) => {
        let id = data.id
        delete data['id']
        delete data['lastAssigned']
        updateEquipment({
            variables: {
                equipmentId: id,
                equipmentData: { ...data, currentOwner: data.currentOwner },
            },
        })
            .then(() => {
                notifySuccess()
                rest.afterSubmit()
            })
            .catch(notifyFailure)
    }

    const handleCreate = (data) => {
        insertEquipment({
            variables: {
                equipmentData: data,
            },
        })
            .then(() => {
                notifySuccess()
                rest.afterSubmit()
            })
            .catch(notifyFailure)
    }

    return (
        <form
            className={classes.form}
            onSubmit={handleSubmit(equipmentData ? handleEdit : handleCreate)}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="space-between"
            >
                <Box style={{ margin: '8px' }}>
                    <Controller
                        control={control}
                        name={'asset.category'}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedSelect
                                passClassName={classes.outlinedSelect}
                                key={'category'}
                                onChange={(e) => {
                                    onChange(e.target.value)
                                    getAssetsByCategory({
                                        variables: {
                                            category: e.target.value,
                                        },
                                    })
                                }}
                                value={value}
                                label={t('categoria')}
                            >
                                {[
                                    'DPI',
                                    'STRUMENTI_IGIENIZZAZIONE',
                                    'TERMOMETRI',
                                ].map((item) => (
                                    <MenuItem value={item}>{t(item)}</MenuItem>
                                ))}
                            </OutlinedSelect>
                        )}
                    />
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Controller
                            control={control}
                            name={'asset.asset'}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                // <FormControl variant={'outlined'}>
                                //     <Autocomplete
                                //         className={classes.inputBox}
                                //         id={'autcomplete-asset'}
                                //         options={assets}
                                //         onChange={(e, value) => {
                                //             // onChange(value?.id)
                                //         }}
                                //         // value={typeof (value) === 'object' ? value : workerData && workerData.responsabile}
                                //         getOptionLabel={(option) => option.asset}
                                //         renderInput={(params) => <TextField {...params} label='Asset'
                                //                                             variant='outlined' />}
                                //     />
                                // </FormControl>
                                <OutlinedTextField
                                    className={classes.inputBox}
                                    label={t('nome_risorsa')}
                                    onChange={(e) => onChange(e.target.value)}
                                    value={value}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name={'currentOwner'}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <FormControl
                                    variant={'outlined'}
                                    style={{ width: '210px' }}
                                >
                                    <Autocomplete
                                        className={classes.inputBox}
                                        id={'autcomplete-owner'}
                                        options={workers}
                                        onChange={(e, value) => {
                                            onChange(value?.id)
                                        }}
                                        value={
                                            typeof value === 'object'
                                                ? value
                                                : equipmentData &&
                                                  equipmentData.currentOwner
                                        }
                                        getOptionLabel={(option) =>
                                            `${option.nome} - (mat. ${option.nMatricola})`
                                        }
                                        getOptionSelected={(option, value) =>
                                            `${option.nome} - (mat. ${option.nMatricola})`
                                        }
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label={t('assegna_a')}
                                                    variant="outlined"
                                                />
                                            )
                                        }}
                                    />
                                </FormControl>
                            )}
                        />
                    </Box>
                </Box>
                <Box marginRight="16px">
                    <Controller
                        control={control}
                        name={'note'}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                className={classes.formField}
                                multiline
                                variant="outlined"
                                label={t('note')}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box className={classes.buttonsBox}>
                <Button
                    variant="contained"
                    size={'small'}
                    style={{
                        backgroundColor: 'rgba(42,42,42,0.81)',
                        color: 'white',
                    }}
                    className={classes.button}
                    onClick={() => {
                        rest.onClose()
                    }}
                >
                    {t('chiudi')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size={'small'}
                    type={'submit'}
                    disabled={!(isSubmitting || isDirty)}
                    className={classes.button}
                >
                    {t('salva')}
                </Button>
            </Box>
        </form>
    )
}

export default EquipmentForm
